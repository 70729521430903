import styled from 'styled-components';

export const Title = styled.h3`
   font-size: 18px;
   font-weight: lighter;
   color: #9A9A9A;
`;

export const Message = styled.div`
   font-size: 56px;
   font-weight: bold;
   white-space: pre-line;
   margin-bottom: 257px;
   margin-top: 15px;
   color: #040404;
   line-height: 65px;
`;