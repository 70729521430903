import { createGlobalStyle } from 'styled-components';

import BoldFontWoff from '../fonts/Gilroy-Bold/Gilroy-Bold.woff';
import BoldFontWoff2 from '../fonts/Gilroy-Bold/Gilroy-Bold.woff2';
import MediumFontWoff from '../fonts/Gilroy-Medium/Gilroy-Medium.woff';
import MediumFontWoff2 from '../fonts/Gilroy-Medium/Gilroy-Medium.woff2';
import RegularFontWoff from '../fonts/Gilroy-Regular/Gilroy-Regular.woff';
import RegularFontWoff2 from '../fonts/Gilroy-Regular/Gilroy-Regular.woff2';

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 400;
    src: url(${RegularFontWoff}) format('woff'), url(${RegularFontWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: medium;
    font-weight: 500;
    src: url(${MediumFontWoff}) format('woff'), url(${MediumFontWoff2}) format('woff2');
  }

  @font-face {
    font-family: 'Gilroy';
    font-style: bold;
    font-weight: 700;
    src: url(${BoldFontWoff}) format('woff'), url(${BoldFontWoff2}) format('woff2');
  }

  html,
  body {
    font-family: 'Gilroy';
    font-weight: normal;
  }

  html {
    height: auto;
    min-height: 100%;
    margin: 0;
  }

  body {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    height: auto;
    min-height: 100%;
    width: 100%;
  }

  #root {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    align-items: stretch;
  }


  .ant-layout {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    margin: auto;
    width: 100%;
  }

  .ant-layout-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    max-width: 800px;
    width: 100%;
  }

  a,
  .ant-btn-link {
    color: ${({ theme }) => theme.colors.secondary};

    :hover {
      color: ${({ theme }) => theme.colors.tertiary};
    }
  }

  .ant-typography {
    color: ${({ theme }) => theme.colors.primary};
  }
  
  .ant-card-body {
    .ant-result {
      padding: 0;
    }
  }
  
  .ant-checkbox-input:focus + .ant-checkbox-inner,
  .ant-checkbox-input:focus {
     outline: black auto 1px;
  }
  

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-wrapper:hover .ant-checkbox-inner {
    border-color:  ${({ theme }) => theme.colors.tertiary};
  }
  
  .ant-checkbox-checked::after {
    border: 2px solid #000;
    border-top: 0;
    border-left: 0;
    border-radius: 2px;
    visibility: hidden !important;
  }

  .ant-radio-button-wrapper-checked svg {
    fill: white;
  }

  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: ${({ theme }) => theme.colors.primary};
    border-color:  ${({ theme }) => theme.colors.primary};
    ::after {
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      border-radius: 2px;
    }
  }

  .ant-result-title {
    line-height: 1.2;
    margin-bottom: 10px;
  }

  .ant-card-bordered {
    border-color: #D9D9D9 !important;
  }

  .bid-modal .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
  
  .ant-layout {
    background: #fff;
  }

  input.ant-input {
    font-size: 16px;
  }

  .ant-notification {
    z-index: 2000000;
  }

  .ant-card-bordered {
    border: 1px solid #f0f0f0;
    border-radius: 2px;
  }

  /* Titles */
  h1.ant-typography,
  .ant-typography h1 {
    font-size: 30px;
  }

  h3.ant-typography,
  .ant-typography h3 {
    font-size: 20px;
  }

  h4.ant-typography,
  .ant-typography h4 {
    font-size: 16px;
  }

  /* Spinner */

  .ant-spin {
    color: ${({ theme }) => theme.colors.primary};

    .ant-spin-dot-item {
      background-color: ${({ theme }) => theme.colors.primary};
    }
  }


  /* Inputs */
  .ant-input:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }

  .ant-input:focus,
  .ant-input:hover {
    border-color: ${({ theme }) => theme.colors.tertiary};
  }


  /* Modal */
  .ant-modal-mask {
    z-index: 1001;
  }

  .ant-modal-wrap {
    z-index: 1002;
  }
  
  .login__title {
    font-weight: bold;
    font-size: 14;
    margin-bottom: 10px; 
  }
  
  .login__title--margin {
    margin-top: 20px;
  }
  
  .login__description {
    font-size: 18px;
    color: #BBBBBB;
  }
  
  .background-black {
    background: black;
  }
  
  .link-bold {
    font-weight: bold;
  }
  
  .main-link {
    color: white;
    margin-left: 13px;
  }
  
  .main-link--black {
    color: black;
  }
  
  .main-link--styled {
    font-weight: bold;
    color: #4E4E4E;
  }
  
  .main-link--padding {
    padding-left: 10px
  }
  
  .hide {
    display: none;
  }
  
  .border {
    border: 2px solid black;
  }
  
   .single-input {
    display: inline-block;
    width: 38px;
    height: 46px;
    margin-right: 4px;
    border: 2px solid black;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
  .single-input:active {
    outline: 0;
    outline-offset: 0;
  }
  .single-input:focus {
    outline: 0;
    outline-offset: 0;
  }

  .input-short {
    width: 54px;
    border-right: none;
  }
  
  .flex-container {
    display: flex;  
  }
  
  .input-wrapper {
      border: 2px solid black;
      width: 100%;
      z-index: 2;
  }
  
  .input-wrapper {
    border: 2px solid black;
    width: 100%;
    z-index: 2;
  }
  
  .terms-item {
    font-weight: bold;
    color: #4E4E4E;
  }
  
  .terms-num {
    position: absolute;
    left: 0;
    font-weight: bold;
    color: #4E4E4E;
  }

  .PhoneInputInput, .PhoneInputCountry {
    font-size: 16px;
    height: 46px;
    padding: 4px 11px;
    border: none;
    outline: none;
  }
  :root {  
    --PhoneInputCountrySelectArrow-color: black;
    --PhoneInput-color--focus: black;
  }
  .get-started-button {
    background: white;
  }
`;
