import moment from 'moment';
import { useMemo } from 'react';

import { ProjectOverviewCarpProps } from '../../components/ProjectOverview/Card';
import {
  PROJECT_STATUS_LABELS,
  getProjectStatusFromEnum,
  STATUS_ENUM,
} from '../../constants/status';
import { AllUserProjectsQuery, Project, Bid } from '../../generated/graphql';
import { createAddressStrings } from '../../helpers/address';
import { getAcceptedBid } from '../../helpers/bids';
import { formatCurrency, formatDate } from '../../helpers/formatters';
import { ROUTES } from '../../router';

function getRightText(project?: Partial<Project>, acceptedBid?: Bid) {
  if (!project) {
    return '';
  }

  switch (project?.status) {
    case STATUS_ENUM.CONSULT:
      return '';
    case STATUS_ENUM.COMPLETED:
      return formatDate(project?.completedAt) || '';
    case STATUS_ENUM.WAITING:
    case STATUS_ENUM.CREATED:
      return String(project?.Bids?.length || 0) + ' Bids';
    default:
      return formatCurrency(acceptedBid?.price);
  }
}

function projectTransformer(item?: Partial<Project>): ProjectOverviewCarpProps {
  const acceptedBid = getAcceptedBid(item?.Bids);

  const rightText = getRightText(item, acceptedBid);
  const statusText = getProjectStatusFromEnum(item?.status as STATUS_ENUM);

  const { line1, line2 } = createAddressStrings(item?.address);

  let note;

  let estimatedDate;
  if (item?.status === STATUS_ENUM.IN_PROGRESS && acceptedBid) {
    estimatedDate = moment(acceptedBid?.startDate).add(
      acceptedBid?.duration,
      'days',
    );
    const formattedDate = formatDate(estimatedDate);

    note = {
      text: `Estimated Completion Date: ${formattedDate}`,
      bolded: formattedDate,
    };
  }

  return {
    note,
    extra: {
      id: item?.id,
      bidStart: acceptedBid?.startDate,
      rawStatus: item?.status as STATUS_ENUM,
      estimatedDate,
    },
    line1,
    line2,
    line3: item?.address2 || '',
    status: statusText,
    title: item?.name,
    right: rightText,
    to: `${ROUTES.PROJECTS}/${item?.id}`,
  };
}

export function useProjectsTransformer(data?: AllUserProjectsQuery) {
  return useMemo(() => {
    return data?.Project.map((project) => projectTransformer(project)) || [];
  }, [data]);
}

export function useProjectsOverviewTransformer(data?: AllUserProjectsQuery) {
  const transformedData = useProjectsTransformer(data);

  return useMemo(() => {
    return transformedData.reduce(
      (
        acc: {
          uncompleted: ProjectOverviewCarpProps[];
          completed: ProjectOverviewCarpProps[];
        },
        project,
      ) => {
        const bucket =
          project?.status === PROJECT_STATUS_LABELS.completed
            ? 'completed'
            : 'uncompleted';

        acc[bucket].push(project);
        return acc;
      },
      {
        uncompleted: [],
        completed: [],
      },
    );
  }, [transformedData]);
}
