import { Typography } from 'antd';
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';

import { STATUS_ENUM } from '../../../constants/status';
import { formatCurrency } from '../../../helpers/formatters';
import { LabeledText } from '../../LabeledText';
import { Stat } from '../../LabeledText/styled';

import { Container } from './styled';

interface IProps {
  price?: number;
  completion?: Date | string | Moment;
  status?: any;
  rightText?: string;
  discount?: number | string;
}

export function Overview({
  completion,
  discount,
  price,
  status,
  rightText,
}: IProps) {
  const momentDate = useMemo(() => moment(completion), [completion]);
  const formattedDate = useMemo(() => momentDate.format('M/D/YY'), [
    momentDate,
  ]);

  const dateText = useMemo(() => {
    if (rightText) {
      return rightText;
    }

    const isCompleted = status === STATUS_ENUM.COMPLETED;

    return !isCompleted && momentDate.isSameOrAfter(moment(), 'day')
      ? 'Estimated Completion Date'
      : 'Completion Date';
  }, [momentDate, rightText, status]);

  const formattedMoney = useMemo(() => {
    return formatCurrency(
      Math.max(Number(price || 0) - Number(discount || 0), 0),
    );
  }, [discount, price]);

  return (
    <div style={{ width: '100%' }}>
      <Container>
        <LabeledText label="Price">{formatCurrency(price)}</LabeledText>
        <LabeledText label={dateText} rightAligned>
          {formattedDate}
        </LabeledText>
      </Container>
      {Boolean(discount) && (
        <Typography>
          <Stat>Due: {formattedMoney}</Stat>
        </Typography>
      )}
    </div>
  );
}
