import { Layout as AntdLayout } from 'antd';
import styled from 'styled-components';

const { Content: AntdContent } = AntdLayout;

export const Content = styled(AntdContent)`
  background: white;
  padding: 32px;
`;

export const FullWidthPageWrapper = styled.div`
  width: 100%;
`;
