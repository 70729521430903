import { Typography } from 'antd';
import styled from 'styled-components';

import theme from '../../styles';

const backgrounds: any = {
  blue: '#D9EDFF',
  green: '#a1cab7',
  default: theme.colors.tertiary,
};

const overlays: any = {
  blue: '#1890FF',
  green: '#31825d',
  default: theme.colors.secondary,
};

// Alt
// #193b08
// #497632
// #31825d

interface WrapperProps {
  color?: string;
}

export const Wrapper = styled.div<WrapperProps>`
  align-content: center;
  background: ${({ color = 'green' }) => backgrounds[color]};
  display: flex;
  height: 32px;
  padding: 8px 16px;
  width: 100%;
  position: relative;
`;

export const Text = styled(Typography.Text)`
  color: #fff;
  font-size: 12px;
  line-height: 1.4;
  z-index: 2;
`;

interface BarProps {
  progress?: number;
  color?: string;
}

export const Bar = styled.div<BarProps>`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: ${({ color = 'green' }) => overlays[color]};
  z-index: 1;
  transform-origin: left;
  transform: scaleX(${({ progress = 0 }) => progress});
`;
