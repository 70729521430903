import styled from 'styled-components';

export const Title = styled.h3`
   font-size: 28px;
   font-weight: bold;
   color: #040404;
`;

export const Message = styled.div`
   font-size: 18px;
   white-space: pre-line;
   margin: 4px 0 37px;
   color: #BBBBBB;
`;

export const Spacer = styled.div`
  height: 144px;
  width: 100%;
`;