import { Button as AntdButton } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface ButtonProps {
  isCTA?: boolean;
}

export const Button = styled(AntdButton)<ButtonProps>`
  height: 54px;
  margin: 8px 0;
  padding: 8px 16px;
  width: 100%;

  color: #fff;

  &.ant-btn-background-ghost,
  &.ant-btn-background-ghost.ant-btn-primary {
    color: ${({ theme }) => theme.colors.primary};

    :hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  &,
  &.ant-btn-background-ghost.ant-btn-primary {
    background: ${({ theme, isCTA }) => {
      if (isCTA) {
        return theme.colors.tertiary;
      }

      return theme.colors.secondary;
    }};
    border-color: ${({ theme, isCTA }) => {
      if (isCTA) {
        return theme.colors.tertiary;
      }

      return theme.colors.secondary;
    }};
  }

  :hover,
  :focus {
    color: #fff;
    background: ${({ theme, isCTA }) => {
      if (isCTA) {
        return theme.colors.secondary;
      }
      return theme.colors.tertiary;
    }};
    border-color: ${({ theme, isCTA }) => {
      if (isCTA) {
        return theme.colors.secondary;
      }
      return theme.colors.tertiary;
    }};
  }
`;

export const BackButton = styled(Link)`
  display: block;
  color: #000;
  margin-bottom: 41px;
`;

export const SimpleButton = styled.button`
  display: block;
  color: black;
  height: 54px;
  font-size: 18px;
`;

export const BlackButton = styled.button`
  display: block;
  background-color: black;
  border: none;
  color: white;
  height: 54px;
  font-size: 18px;
  width: 100%;
`;
