import { UserOutlined } from '@ant-design/icons';
import { Avatar, Typography } from 'antd';
import React from 'react';

import { useContractorByIdQuery } from '../../generated/graphql';
import { ImageCloudinary } from '../ImagePreviews/ImagePreview/ImageCloudinary';

import { Row } from './styled';

interface IProps {
  children?: string;
  src?: string;
}

export function InfoRow({ children, src }: IProps) {
  return (
    <Row>
      <div style={{ alignItems: 'center', display: 'flex' }}>
        <Avatar
          shape="square"
          size={40}
          icon={src ? <ImageCloudinary src={src} /> : <UserOutlined />}
        />
        <div style={{ width: '16px' }} />
        <Typography>
          <Typography.Text strong>{children}</Typography.Text>
        </Typography>
      </div>
    </Row>
  );
}

export function InfoRowWithData({ id }: { id?: string | number }) {
  const { data, loading } = useContractorByIdQuery({
    variables: {
      id: Number(id) || 0,
    },
  });

  return (
    <InfoRow src={data?.Contractor_by_pk?.profilePic}>
      {loading ? 'Loading ...' : data?.Contractor_by_pk?.name}
    </InfoRow>
  );
}
