import { Typography, Input, Form, Spin, Space } from 'antd';
import React, { useState, useRef, useCallback } from 'react';

import parseGooglePlace from '../../helpers/googlePlaces';
import AddressAutocomplete from '../AddressAutocomplete';
import { Geocoding } from '../Geocoding';
import { Link } from '../Link';
import { Spacer } from '../Spacer';

import { AddressRow, Body, Card, RightColumn } from './styled';

interface IAddress {
  street?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
}

interface IProps {
  address?: IAddress;
  forceUpdate?: any;
  hideLabel?: boolean;
  onChange?: (value: IAddress) => void;
}

function getValue({ street, city, state, zip }: IAddress) {
  if (!street && !city && !state) {
    return '';
  }

  let value = `${street}, ${city}, ${state}`;

  if (zip) {
    value += `, ${zip}`;
  }

  return value;
}

function getEmptyText(loading: boolean) {
  if (loading) {
    return (
      <div>
        <Space>
          <Spin />
          <span>Trying To Locate Address</span>
        </Space>
      </div>
    );
  }

  return 'Enter Address';
}

let savedMyLocation: any = undefined;
let isLocationDisabled = false;

export function AddressInputCard({
  address = {},
  forceUpdate,
  hideLabel,
  onChange,
}: IProps) {
  const [disabled, setDisabled] = useState(false);

  const [searching, setSearching] = useState(false);
  const [myLocation, setMyLocation] = useState<any>(savedMyLocation);
  const { street, city, state, zip } = address;
  const inputRef = useRef<Input>();

  const hasAddressFilled =
    address.city && address.state && address.zip && address.street;

  const [loading, setLoading] = useState(
    !hasAddressFilled && !isLocationDisabled,
  );
  const [getLocation, setGetlocation] = useState(false);

  const value = getValue(address);

  const changeToMyAddress = useCallback(
    (newAddress?: any) => {
      if (hasAddressFilled && !getLocation) {
        setLoading(true);
        setGetlocation(true);
        return;
      }

      if ((newAddress || myLocation) && onChange) {
        onChange(newAddress || myLocation);
        setMyLocation(newAddress || myLocation);
        setTimeout(forceUpdate);
      }
    },
    [forceUpdate, getLocation, hasAddressFilled, myLocation, onChange],
  );

  const myLocationCallback = useCallback(
    (myAddress) => {
      if (myAddress === 'disabled') {
        isLocationDisabled = true;
        setLoading(false);
        setDisabled(true);
        return;
      }

      if (!myAddress) {
        setLoading(false);
        return;
      }

      const parseData = parseGooglePlace(myAddress);

      let street = `${parseData?.streetName}`;

      if (parseData?.streetNumber) {
        street = `${parseData?.streetNumber} ${parseData?.streetName}`;
      }

      const newAddress = {
        street,
        city: parseData?.city,
        zip: parseData?.zipCode,
        state: parseData?.stateShort,
      };

      if (myAddress) {
        setMyLocation(newAddress);
        savedMyLocation = newAddress;
      }

      // Set address if input still waiting
      // for location
      if (getLocation || (loading && !hasAddressFilled)) {
        changeToMyAddress(newAddress);
      }

      setLoading(false);
    },
    [changeToMyAddress, getLocation, hasAddressFilled, loading],
  );

  return (
    <div>
      {(!hasAddressFilled || getLocation) && !isLocationDisabled && (
        <Geocoding onComplete={myLocationCallback} />
      )}
      {!hideLabel && (
        <Typography>
          <Typography.Title level={3}>Address</Typography.Title>
        </Typography>
      )}
      <Spacer size="small" />
      <Card>
        <Body>
          <AddressRow style={{ flex: '1 1 auto' }}>
            {value && !loading && (
              <>
                <p>{street}</p>
                <p>
                  {city}, {state} {zip}
                </p>
              </>
            )}
            {(!value || loading) && getEmptyText(loading)}
          </AddressRow>
          <RightColumn>
            {(!loading || hasAddressFilled) && (
              <Link
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  setSearching(!searching);
                  setTimeout(() => {
                    if (!searching) {
                      inputRef?.current?.focus();
                    }
                  });
                }}
              >
                {searching ? 'Cancel' : 'Change'}
              </Link>
            )}
            {!disabled && !loading && !isLocationDisabled && (
              <Link
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  changeToMyAddress();
                }}
              >
                Use My Location
              </Link>
            )}
          </RightColumn>
        </Body>
        <Spacer size="small" />
        <Body>
          {value && (
            <>
              <Form.Item name="apartment" noStyle>
                <Input
                  placeholder="Apt. #"
                  style={{ maxWidth: '100px' }}
                  onChange={forceUpdate}
                />
              </Form.Item>
            </>
          )}
        </Body>
      </Card>
      {searching && (
        <div>
          <AddressAutocomplete
            inputRef={inputRef}
            value={value}
            onSelected={(rawString, parseData) => {
              let street = `${parseData?.streetName}`;

              if (parseData?.streetNumber) {
                street = `${parseData?.streetNumber} ${parseData?.streetName}`;
              }

              const newAddress = {
                street,
                city: parseData?.city,
                zip: parseData?.zipCode,
                state: parseData?.stateShort,
              };

              if (onChange) {
                onChange(newAddress);
                setTimeout(forceUpdate);
              }

              setSearching(false);
            }}
          />
        </div>
      )}
    </div>
  );
}
