import { Typography } from 'antd';
import React from 'react';

import theme from '../../styles';

import { TitleContainer } from './styled';

const placeholder = `Please upload photos of your current space as well as examples of how you would like the finished project to look.`;

export function Header() {
  return (
    <>
      <TitleContainer>
        <Typography.Title level={3}>Show us some pictures</Typography.Title>
        <Typography.Text style={{ color: theme.colors.tertiary }}>
          Optional
        </Typography.Text>
      </TitleContainer>
      <Typography>
        <Typography.Text>{placeholder}</Typography.Text>
      </Typography>
    </>
  );
}
