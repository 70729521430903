import styled from 'styled-components';

export const Message = styled.div`
   font-size: 56px;
   font-weight: bold;
   white-space: pre-line;
   margin-top: 60px;
   color: #040404;
   line-height: 65px;
`;

export const Description = styled.div`
   font-size: 18px;
   margin-top: 31px;
   margin-bottom: 443px;
   color: #BBBBBB;
`;