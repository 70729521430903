import { onError } from '@apollo/client/link/error';
import { notification } from 'antd';
import moment from 'moment';

import { userDispatch } from '../../context/User';
import { USER_ACTION_TYPES } from '../../context/User/types';

let lastCalled: Date;

export const onErrorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const error of graphQLErrors) {
        const { extensions, message, locations, path } = error;

        if (extensions?.code === 'invalid-jwt') {
          userDispatch({ type: USER_ACTION_TYPES.LOGOUT });

          if (!lastCalled || moment().diff(lastCalled, 'minutes') > 1) {
            notification.warn({
              message: 'Token Expired, please login again',
            });

            lastCalled = new Date();
          }
        }

        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      }
    }
  },
);
