import React, { useMemo } from 'react';

import { ImagePreview } from './ImagePreview';
import { Container, NoResultText } from './styled';

interface IPhoto {
  type?: string;
  url: string;
}
interface IProps {
  filter?: string;
  onDelete?: (index?: number, src?: string | undefined) => void;
  photos?: IPhoto[];
}

export function ImagePreviews({
  onDelete,
  filter = undefined,
  photos = [],
}: IProps) {
  const filteredPhotos = useMemo(() => {
    if (typeof filter === 'string') {
      return photos.filter((photo) => photo.type === filter);
    }

    return photos;
  }, [filter, photos]);

  return (
    <Container>
      {!filteredPhotos.length && (
        <NoResultText>No Images Uploaded</NoResultText>
      )}
      {filteredPhotos.map(({ url }, index) => {
        return (
          <ImagePreview
            index={index}
            key={`${encodeURIComponent(url)}-${index}`}
            onDelete={onDelete}
            src={url}
          />
        );
      })}
    </Container>
  );
}
