import { setContext } from '@apollo/client/link/context';

import { authStorage } from '../../context/User';

export const authLink = setContext((request, previousContext) => {
  const token = authStorage.token();

  if (!token) {
    return {};
  }

  return {
    headers: { authorization: 'Bearer ' + token },
  };
});
