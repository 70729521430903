/* eslint-disable @typescript-eslint/camelcase */
import { ArrowLeftOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { useStripe } from '@stripe/react-stripe-js';
import {
  PaymentIntent,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js';
import {
  Modal,
  Space,
  notification,
  Spin,
  Card,
  Typography,
  Result,
} from 'antd';
import React, { useContext, useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { BackButton, Button } from '../../components/Button';
import { PaymentForm } from '../../components/PaymentForm';
import { PaymentMethods } from '../../components/PaymentMethods';
import { ReviewBid } from '../../components/ReviewBid';
import { Spacer } from '../../components/Spacer';
import { UserContext } from '../../context/User';
import {
  useCreatePaymentIntentMutation,
  PaymentMethod,
  useUpdateBidsStatusMutation,
  useAcceptBidOnProjectMutation,
} from '../../generated/graphql';
import { formatCurrency } from '../../helpers/formatters';
import { ROUTES } from '../../router';
import theme from '../../styles';
import { FullWidthPageWrapper } from '../../styles/Layout';

type IProps = Partial<
  RouteComponentProps<
    {},
    {},
    {
      back?: string;
      bid?: any;
      rejectedBidsIds?: number[];
      projectId?: number;
    }
  >
>;

export default function PaymentInformationPage({ history, location }: IProps) {
  const { refetch, user } = useContext(UserContext);

  const [
    createIntent,
    { loading: intentLoading },
  ] = useCreatePaymentIntentMutation();
  const [
    updateBidsStatuses,
    { loading: updateBidsLoading },
  ] = useUpdateBidsStatusMutation();
  const [
    acceptBid,
    { loading: acceptingBid },
  ] = useAcceptBidOnProjectMutation();

  const [loading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [finalPrice, setFinalPrice] = useState(0);

  const stripe = useStripe();

  const [newCard, setNewCard] = useState<{
    card: StripeCardNumberElement;
    name: string;
    saveCard: boolean;
  }>();
  const [selectedPayment, setSelectedPayment] = useState<
    string | Partial<PaymentMethod>
  >();

  const back = location?.state?.back || ROUTES.DASHBOARD;
  const bid = location?.state?.bid;
  const rejectedBidsIds = location?.state?.rejectedBidsIds;
  const projectId = location?.state?.projectId;

  const totalPrice = Math.max(bid?.price - Number(user?.promoBalance || 0), 0);

  useEffect(() => {
    if (!bid || !rejectedBidsIds || !projectId) {
      history?.push(back);
    }
  }, [back, bid, history, projectId, rejectedBidsIds]);

  const handleSubmit = async () => {
    setLoading(true);
    if (!stripe) {
      return;
    }

    const variables: any = {
      bidId: bid?.id,
      promoBalance: user?.promoBalance || 0,
    };

    if (newCard?.saveCard) {
      variables.savePaymentMethod = true;
    }

    if (
      typeof selectedPayment !== 'string' &&
      selectedPayment &&
      (selectedPayment as Partial<PaymentMethod>)?.id
    ) {
      variables.paymentMethodId = (selectedPayment as Partial<
        PaymentMethod
      >)?.id;
    }

    let paymentIntent: any;

    try {
      paymentIntent = await createIntent({
        variables,
      });
    } catch (error) {
      notification.warn({
        message: (error as Error)?.message,
      });
      setLoading(false);
      return false;
    }

    const secret = paymentIntent?.data?.createPaymentIntent.secret;

    const paymentOptions: any = {};

    if (secret !== 'skipped' && newCard) {
      paymentOptions.payment_method = {
        card: newCard?.card,
        billing_details: {
          name: newCard?.name,
        },
      };
    }

    let results: {
      paymentIntent?: Partial<PaymentIntent> | undefined;
      error?: StripeError | undefined;
    };

    if (secret !== 'skipped') {
      try {
        results = await stripe.confirmCardPayment(secret!, paymentOptions);
      } catch (error) {
        notification.warn({
          message: (error as Error)?.message,
        });
        setLoading(false);
        return false;
      }
    } else {
      results = {
        paymentIntent: {
          status: 'succeeded',
        },
      };
    }

    if (results.paymentIntent?.status === 'succeeded') {
      try {
        await acceptBid({
          variables: {
            bidId: Number(bid?.id),
            id: Number(projectId),
          },
        });
      } catch (error) {
        notification.warn({
          message: (error as Error)?.message,
        });
        setLoading(false);
        return false;
      }

      try {
        const rejectedResults = await updateBidsStatuses({
          variables: {
            _in: rejectedBidsIds,
            status: 'rejected',
          },
        });
      } catch (error) {
        console.log(error);
        notification.warn({
          message: (error as Error)?.message,
        });
        setLoading(false);
        return false;
      }
    }

    setFinalPrice(totalPrice);
    refetch();
    setComplete(true);
  };

  const isProcessing = intentLoading && updateBidsLoading && acceptingBid;

  const isFree = totalPrice === 0;

  const showReview =
    isFree || (selectedPayment && selectedPayment !== 'new') || newCard;
  const showPaymentForm = !isFree && selectedPayment === 'new' && !newCard;
  const showPaymentMethods = !isFree && !selectedPayment;

  return (
    <FullWidthPageWrapper>
      <BackButton to={back} replace>
        <Space>
          <ArrowLeftOutlined />
          <span>
            Back to {back.includes(ROUTES.DASHBOARD) ? 'Dashboard' : 'Project'}
          </span>
        </Space>
      </BackButton>
      {showReview && (
        <ReviewBid
          discount={user?.promoBalance}
          bid={bid}
          onCancel={() => {
            setSelectedPayment(undefined);
            setNewCard(undefined);

            if (isFree) {
              history?.push(back);
            }
          }}
          onConfirm={handleSubmit}
        />
      )}
      <PaymentForm
        visible={showPaymentForm}
        onSubmit={({ card, name, save }) => {
          setNewCard({
            card,
            name: name || '',
            saveCard: save || false,
          });
        }}
        onCancel={() => {
          setSelectedPayment(undefined);
          setNewCard(undefined);
        }}
      />
      {showPaymentMethods && (
        <PaymentMethods
          onAddNew={() => setSelectedPayment('new')}
          onSelected={(paymentMethod) => {
            setSelectedPayment(paymentMethod || undefined);
          }}
        />
      )}
      <Modal
        centered
        maskStyle={{ background: '#262626' }}
        className="bid-modal"
        closable={false}
        title={null}
        footer={null}
        visible={isProcessing || loading || isComplete}
      >
        <Card style={{ textAlign: 'center' }}>
          {loading && !isComplete && (
            <>
              <Spin size="large" />
              <Spacer />
              {/* <Typography.Text>Processing Payment</Typography.Text> */}
              <Typography.Text>
                {isFree ? 'Processing ...' : 'Processing Payment...'}
              </Typography.Text>
            </>
          )}
          {isComplete && (
            <>
              <Result
                icon={
                  <CheckCircleOutlined
                    style={{ color: theme.colors.tertiary }}
                  />
                }
                status="success"
                title={`Bid accepted for ${formatCurrency(finalPrice)}`}
                subTitle={`Bid has been successfully added.`}
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      history?.push(ROUTES.DASHBOARD);
                    }}
                  >
                    Go To Projects
                  </Button>,
                ]}
              />
            </>
          )}
        </Card>
      </Modal>
    </FullWidthPageWrapper>
  );
}
