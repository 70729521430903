import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {  BlackButton } from '../../components/Button';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Description, Message } from './styled';
type IProps = Partial<RouteComponentProps>;


export default function ContactUsSuccessPage({ history }: IProps) {
  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    document?.querySelector('main + div + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
      document?.querySelector('main + div + div + div')?.classList.remove('hide');
    };
  });

  const goHomeScreen = ()=> {
    history?.push(ROUTES.DASHBOARD);
  };

  return (
    <FullWidthPageWrapper>
      <Message>
        Thank you
      </Message>
      <Description>
        We'll get in touch soon.
      </Description>
      <BlackButton
        onClick={ goHomeScreen }
      >
        Go to home screen
      </BlackButton>
    </FullWidthPageWrapper>
  );
}
