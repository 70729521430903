import { Space, Form, Input } from 'antd';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BackButton, Button } from '../../components/Button';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Arrow } from '../../components/Arrow';
import { Title, Message, Spacer } from './styled';
import MaskedInput from 'antd-mask-input';
import { replaceNonNumeric, stripeZeroOrOneStart } from '../../helpers';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

type IProps = Partial<
  RouteComponentProps<
    {},
    {},
    {
      back?: string;
    }
    >
  >;


export default function ContactUsPage({ history, location }: IProps) {
  const back = location?.state?.back || ROUTES.VERIFY;

//  const [countryCode, setCountryCode] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [userFullName, setUserFullName] = useState('');


  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    document?.querySelector('main + div + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
      document?.querySelector('main + div + div + div')?.classList.remove('hide');
    };
  });

const isValid = true;

  return (
    <FullWidthPageWrapper>
      <BackButton to={back} replace>
        <Space>
          <Arrow />
        </Space>
      </BackButton>
      <Title>
        Contact us
      </Title>
      <Message>
        Fill in this form and we'll get in touch
        with you in no time to hear about your
        project
      </Message>
      <Form
        onFinish={() => {
          if (!isValid) {
            return;
          }
          history?.push(ROUTES.CONTACT_US_SUCCESS)
        }}
      >
        <div className={'login__title'}>
          Full name
        </div>
        <Form.Item
          name="yourName"
        >
          <div className={'border'}>
            <Input
              style = {{height: "46px"}}
              placeholder="Your name"
              value={userFullName}
              onChange={({ target: { value } }) => {
                setUserFullName(value)
              }}
            />
          </div>
        </Form.Item>
        <div className={'login__title'}>
          Mobile phone number
        </div>
        <div className={'flex-container'}>
          <div className={'input-wrapper'}>
          <PhoneInput
            placeholder="Your mobile number"
            value={phoneNumber}
            defaultCountry="US"
            onChange={( value ) => {
              setPhoneNumber(value);
            }}
          />
          </div>
        </div>
        <div className={'login__title login__title--margin'}>
          E-mail
        </div>
        <div className={'border'}>
          <Input
            style = {{height: "46px"}}
            placeholder="Your e-mail"
            onChange={({ target: {  } }) => {
            }}
          />
        </div>
        <Spacer />
        <Button
          htmlType="submit"
          disabled={!isValid}
          type="primary"
        >
          Send contact request
        </Button>
      </Form>
    </FullWidthPageWrapper>
  );
}
