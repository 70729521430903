import styled from 'styled-components';

export const Row = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  line-height: 1;
  margin-bottom: 16px;

  .ant-typography {
    margin: 0;
  }

  span {
    color: ${({ theme }) => theme.colors.secondary};
    font-size: 12px;
  }
`;
