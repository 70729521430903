import { ArrowRightOutlined } from '@ant-design/icons';
import { Input, Typography, Space } from 'antd';
import React, { useContext } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Card } from '../../components/Card';
import { Header } from '../../components/Header';
import { LogoutLink } from '../../components/LogoutLink';
import { Spacer } from '../../components/Spacer';
import { UserContext } from '../../context/User';
import { useGetPaymentMethodsQuery } from '../../generated/graphql';
import { formatCurrency } from '../../helpers/formatters';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';

type IProps = Partial<RouteComponentProps>;

export default function ProfilePage({ history, location }: IProps) {
  const { user } = useContext(UserContext);
  const { data, loading } = useGetPaymentMethodsQuery({
    fetchPolicy: 'network-only',
  });

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { address = {} } = user!;

  const [firstName, lastName] = (user?.name || '').split(' ');

  return (
    <FullWidthPageWrapper>
      <Header right={<LogoutLink />}>Profile</Header>
      <Typography>
        <Typography.Text strong>Balance:</Typography.Text>{' '}
        {formatCurrency(user?.promoBalance || 0)}
      </Typography>
      <Typography.Title level={3}>Name</Typography.Title>
      <Input
        readOnly
        value={firstName}
        onClick={() => {
          history?.push(ROUTES.PROFILE_UPDATE);
        }}
      />
      <Spacer />
      <Input
        readOnly
        value={lastName}
        onClick={() => {
          history?.push(ROUTES.PROFILE_UPDATE);
        }}
      />
      <Spacer />
      <Typography.Title level={3}>Address</Typography.Title>
      {!user?.address && (
        <Card>
          <p>No Saved Address</p>
          <p style={{ fontSize: '0.85em', opacity: 0.85 }}>
            Address will be added on first service request
          </p>
        </Card>
      )}
      {user?.address && (
        <Card
          onClick={() =>
            history?.push(`${ROUTES.ADDRESS_UPDATE}/${user?.address.id}`)
          }
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <p>{address.street}</p>
              {address.street2 && <p>{address.street2}</p>}
              <p>
                {address.city}, {address.state} {address.zip}
              </p>
            </div>
            <ArrowRightOutlined />
          </div>
        </Card>
      )}
      <Spacer />
      {Boolean(data?.getPaymentMethods?.length) && !loading && (
        <>
          <Typography.Title level={3}>Payment Methods</Typography.Title>
          <Space style={{ width: '100%' }} direction="vertical">
            {data?.getPaymentMethods?.map((method) => {
              return (
                <Card>
                  <p>Card ending in {method?.card?.last4}</p>
                  <p>
                    Expires{' '}
                    {method?.card?.exp_month?.toString().padStart(2, '00')} /{' '}
                    {method?.card?.exp_year}
                  </p>
                </Card>
              );
            })}
          </Space>
        </>
      )}
    </FullWidthPageWrapper>
  );
}
