import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export function OnRouterMount() {
  const history = useHistory();

  useEffect(() => {
    const state = {};
    history.replace({ ...history.location, state });
  }, [history]);

  return null;
}
