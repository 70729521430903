import { cloudName, uploadPreset } from '../config';

const cloudinary = (window as any)?.cloudinary;

type callbackType = (error: any, results: any) => void;

export function createUploadWidget(callback: callbackType) {
  return cloudinary.openUploadWidget(
    {
      cloudName,
      uploadPreset,
      multiple: true,
      maxFiles: 10,
      sources: ['local'],
      resourceType: 'image',
      clientAllowedFormats: ['png', 'gif', 'jpeg', 'bmp'],
      showAdvancedOptions: false,
      showPoweredBy: false,
      maxImageWidth: 2000,
      maxImageHeight: 2000,
    },
    callback,
  );
}
