import styled from 'styled-components';

interface IProps {
  size?: 'medium' | 'large';
}
const SizeMargin = {
  medium: '113px',
  large: '330px',
};

export const Wrapper = styled.div<IProps>`
  margin-bottom: 20px;
  margin-top: ${({ size = 'medium' }) => SizeMargin[size]};
`;

export const Registration = styled.div`
 font-size: 14px;
 color: #BBBBBB;
 margin-top: 10px;
 text-align: center;
`;
