import {
  BarsOutlined,
  PlusCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { FOOTER_HEIGHT } from '../Footer';

const ICON_SIZE = 24;
const NAV_VERTICAL_PADDING = 10;
export const NAV_HEIGHT = ICON_SIZE + NAV_VERTICAL_PADDING * 2;

export const NavSpacer = styled.div<{ doubleSpacing?: boolean }>`
  background: transparent;
  height: calc(
    (env(safe-area-inset-bottom) / 2) +
      ${({ doubleSpacing = false }) =>
        doubleSpacing ? NAV_HEIGHT * 2 : NAV_HEIGHT}px
  );
  width: 50px;
`;

const Wrapper = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  bottom: ${FOOTER_HEIGHT}px;
  left: 0;
  min-height: ${NAV_HEIGHT}px;
  padding: ${NAV_VERTICAL_PADDING}px 20px;
  padding-bottom: 0;
  position: fixed;
  width: 100%;
  z-index: 1001;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  margin: auto;
  max-width: 800px;
  width: 100%;
`;

const Link = styled(NavLink)`
  color: ${({ theme }) => theme.colors.tertiary};
  font-size: ${ICON_SIZE}px;
  line-height: 1;
`;

const IPhoneClearance = styled.div`
  padding-bottom: calc(env(safe-area-inset-bottom) / 2);
`;

export function NavBar() {
  return (
    <Wrapper>
      <Container>
        <Link exact to="/dashboard" activeStyle={{ color: 'white' }}>
          <BarsOutlined />
        </Link>
        <Link to="/service-request" activeStyle={{ color: 'white' }}>
          <PlusCircleOutlined />
        </Link>
        <Link to="/profile" activeStyle={{ color: 'white' }}>
          <UserOutlined />
        </Link>
      </Container>
      <IPhoneClearance />
    </Wrapper>
  );
}
