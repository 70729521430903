import React, { useContext, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { UserContext } from '../../context/User';
import { apolloClient } from '../../helpers/apollo';
import { ROUTES } from '../../router';
import { Link } from '../Link';

export function LogoutLink() {
  const { logout, user } = useContext(UserContext);
  const history = useHistory();

  const onClick = useCallback(
    async (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      event.preventDefault();

      // Log Out Routine
      // Clear Context Store
      logout();
      // Clear Apollo Cache
      apolloClient.clearStore();
      // Forward to Dashboard
      history.push(user ? ROUTES.DASHBOARD : ROUTES.LOGIN);
    },
    [history, logout, user],
  );

  return (
    <Link to="/login" onClick={onClick}>
      {user ? 'Logout' : 'Login'}
    </Link>
  );
}
