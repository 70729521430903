import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BackButton, BlackButton } from '../../components/Button';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Description, Title, Point, Spacer, SpacerSmall, Spacer59, ListItem } from './styled';
import { Space } from 'antd';
import { Arrow } from '../../components/Arrow';
type IProps = Partial<RouteComponentProps>;


export default function TermsPage({ history }: IProps) {
  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    document?.querySelector('main + div + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
      document?.querySelector('main + div + div + div')?.classList.remove('hide');
    };
  });

  const goToLogin = ()=> {
    history?.push({
      pathname: ROUTES.REGISTRATION
    });
  };

  return (
    <FullWidthPageWrapper>
      <BackButton to={{
        pathname: ROUTES.REGISTRATION
      }} replace>
        <Space>
          <Arrow />
        </Space>
      </BackButton>
      <Title>
        Terms of service
      </Title>
      <Point>
        1. ACCEPTANCE OF TERMS
      </Point>
      <Description>
        Brixos, Inc. (“Brixos”) provides technology-enabled services, including the Software as a Service offering branded as Brixos, the website at https://www.brixos.com, any Mobile Apps (as defined below), and other related software, content, and services, including all versions and upgrades thereto (collectively, the “Services).  Your use of the Services is subject to and governed by the terms and conditions in this Terms of Service (“TOS”). Brixos may, at its discretion, update this TOS at any time. You can access and review the most current version of this TOS at the URL for this page or by clicking on the “Terms of Service” link within the Services, or as otherwise made available by Brixos.
      </Description>
      <SpacerSmall />
      <Description>
        PLEASE REVIEW THIS TOS CAREFULLY. BY REGISTERING FOR AN ACCOUNT OR OTHERWISE ACCESSING OR USING THE SERVICES, YOU AGREE TO BE BOUND BY THIS TOS, INCLUDING ANY UPDATES OR REVISIONS POSTED HERE OR OTHERWISE COMMUNICATED TO YOU. IF YOU DO NOT AGREE WITH THE TERMS AND CONDITIONS OF THIS TOS, YOU MAY NOT ACCESS OR USE THE SERVICES.
      </Description>
      <SpacerSmall />
      <Description>
        YOU MUST, FOR A PERIOD OF TWELVE (12) MONTHS AFTER THE COMPLETION DATE OF THE LAST PROFESSIONAL SERVICES PROJECT WHICH WAS INITIATED USING THE SERVICES, USE THE SERVICES TO CONNECT WITH ANY PROFESSIONAL SERVICE PROVIDER WITH WHICH YOU HAVE CONNECTED PREVIOUSLY USING THE BRIXOS SERVICES AND PAY BRIXOS THE FEES WITH RESPECT TO ANY PROFESSIONAL SERVICES PROVIDED DURING SUCH TWELVE (12) MONTHS PERIOD. .
      </Description>
      <SpacerSmall />
      <Description>
        THIS TOS REQUIRES FINAL AND BINDING ARBITRATION TO RESOLVE ANY DISPUTE OR CLAIM ARISING OUT OF OR RELATING IN ANY WAY TO THIS TOS, OR YOUR ACCESS TO OR USE OF THE SERVICES, INCLUDING THE VALIDITY, APPLICABILITY OR INTERPRETATION OF THIS TOS, AND YOU AGREE THAT ANY SUCH CLAIM WILL BE RESOLVED ONLY ON AN INDIVIDUAL BASIS AND NOT IN A CLASS, CONSOLIDATED OR REPRESENTATIVE ACTION, ARBITRATION OR OTHER SIMILAR PROCESS. PLEASE REVIEW SECTION 14 CAREFULLY TO UNDERSTAND YOUR RIGHTS AND OBLIGATIONS WITH RESPECT TO THE RESOLUTION OF ANY CLAIM.
      </Description>
      <SpacerSmall />
      <Description>
        You represent and warrant that you: (a) are of legal age to form a binding contract; (b) have the right, authority, and capacity to agree to and abide by this TOS; and (c) are not a person barred from using the Services under the laws of any applicable jurisdiction. THE SERVICES ARE NOT INTENDED FOR USERS UNDER THE AGE OF 13, AND SUCH USERS ARE EXPRESSLY PROHIBITED FROM SUBMITTING ANY PERSONAL DATA OR USING ANY ASPECT OF THE SERVICES, AND BY TAKING SUCH ACTIONS YOU AGREE, REPRESENT, AND WARRANT THAT YOU ARE 13 YEARS OF AGE OR OLDER.
      </Description>
      <Spacer59 />
      <Point>
        2. RIGHTS
      </Point>
      <ListItem>
        <span className={"terms-num"}>1.</span>
        <span className={"terms-item"}>Grant. </span>
        Subject to and conditioned on your compliance with this TOS, Brixos hereby grants you a personal, non-exclusive, non-transferable, non-sublicensable, revocable license solely to use the Services only for your personal, non-commercial use to connect with service providers for your home improvement or maintenance needs. Your access to and use of the Services must further comply in all material respects with all usage guidelines posted by Brixos.
      </ListItem>
      <ListItem>
        <span className={"terms-num"}>2.</span>
        <span className={"terms-item"}>Mobile Apps. </span>
         Brixos may make available mobile software applications for access to and use of certain components of the Services (collectively, “Mobile Apps”). Your access to and use of Mobile Apps is subject to and governed by this TOS. If any Mobile App is downloaded by you from the iTunes App Store (each, an “iOS Mobile App”), your use of such iOS Mobile App is further subject to your compliance in all material respects with the terms and conditions of the Usage Rules set forth in the iTunes App Store Terms of Service. This TOS is between you and BRIXOS only, and not with Apple Inc. (“Apple”) or any other app store provider, and Apple is not responsible for iOS Mobile Apps and the contents thereof; however, Apple and Apple’s subsidiaries are third-party beneficiaries of this TOS with respect to iOS Mobile Apps.
      </ListItem>
      <ListItem>
        <span className={"terms-num"}>3.</span>
        <span className={"terms-item"}>Trademarks. </span>
        You may not use the Brixos or any or its names, brands, trademarks, service marks and logos that Brixos makes available on the Services (“Marks”). Brixos claims trademark protection over all such Marks and you will not use the Marks except as expressly authorized herein. You will not remove or alter the Marks or any proprietary notices on the Services. The Marks may not be included in or as part of any registered corporate name, any other logo, or service or product name.  You may not create any derivative works of the Marks or use the Marks in a manner that creates or reasonably implies an inaccurate sense of endorsement, sponsorship, or association with Brixos.  You will not otherwise use business names or logos in a manner that can mislead, confuse, or deceive any third party. All use of the Marks and all goodwill arising out of such use, will inure to Brixos’ benefit.
      </ListItem>
      <Spacer />
      <BlackButton
        onClick={ goToLogin }
      >
        Return to sign up
      </BlackButton>
    </FullWidthPageWrapper>
  );
}
