import React, { useMemo } from 'react';

import { Text } from './styled';

function splitSentence(message: string, split: string) {
  if (!split) {
    return [message, undefined];
  }

  const startIndex = message.indexOf(split);

  const firstPart = message.slice(0, startIndex);
  const lastPart = message.slice(startIndex + split.length, message.length);

  return [firstPart, lastPart];
}

interface IProps {
  text?: string;
  bolded?: string;
}

export function Note({
  text = 'Estimated Completion Date: 6/16/20',
  bolded = '6/16/20',
}: IProps) {
  const [firstPart, lastPart] = useMemo(
    () => splitSentence(text, bolded || ''),
    [bolded, text],
  );

  return (
    <Text>
      {firstPart}
      <Text strong>{bolded}</Text>
      {lastPart}
    </Text>
  );
}
