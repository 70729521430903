import { Card as AntdCard } from 'antd';
import styled from 'styled-components';

export const Card = styled(AntdCard)`
  width: 100%;
  min-height: 80px;
  .ant-card-body {
    padding: 16px;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  p {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;

export const RightColumn = styled.div`
  flex: 0 1 auto;
  text-align: right;
  display: flex;
  flex-direction: column;
`;

export const NegativeBody = styled.div`
  margin-top: -1px;
`;

export const AddressRow = styled.div`
  padding-right: 10px;
  flex: 1;
`;
