import { Typography, Button as AntdButton } from 'antd';
import styled from 'styled-components';

export const BaseButton = styled(AntdButton)`
  height: 40px;
  padding: 8px 26px;

  font-size: 16px;
  line-height: 16px;

  &.ant-btn-background-ghost.ant-btn-primary {
    color: ${({ theme }) => theme.colors.primary};
    border-color: ${({ theme }) => theme.colors.primary};

    :hover {
      color: ${({ theme }) => theme.colors.tertiary};
      border-color: ${({ theme }) => theme.colors.tertiary};
    }
  }
`;

export const Button = styled(BaseButton)`
  width: 100%;
`;

export const Row = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media only screen and (min-device-width: 500px) {
    flex-direction: row;
  }
`;

export const TitleContainer = styled(Typography)`
  align-items: center;
  display: flex;
  justify-content: space-between;
`;
