import { DefaultTheme } from 'styled-components';

const colors = {
  blue: '#1890ff',
  primary: '#1D1D1D',
  secondary: '#3B3B3B',
  tertiary: '#8D9099',
  white: '#FFF',
};

const theme: DefaultTheme = {
  colors,
};

export default theme;
