import { Typography } from 'antd';
import React from 'react';

import { BidCard } from '../Bids/BidCard';
import { Header } from '../Header';
import { Spacer } from '../Spacer';

interface IProps {
  bid?: any;
  onCancel?: any;
  onConfirm?: any;
  discount?: number | string;
}

export function ReviewBid({ bid, discount, onCancel, onConfirm }: IProps) {
  return (
    <div>
      <Header>Confirm Bid Payment</Header>
      <Typography.Text>Confirm Bid to process payment</Typography.Text>
      <Spacer />
      <BidCard
        discount={discount}
        date={bid?.startDate}
        duration={bid?.duration}
        price={bid?.price}
        data={bid}
        confirming
        onYes={onConfirm}
        onNo={onCancel}
      />
    </div>
  );
}
