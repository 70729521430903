import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
`;

export const Logo = styled.div`
  display: flex;
  align-self: center;
  margin-top: 44px;
  width: 180px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 500px;
`;

export const Image = styled.img`
  width: 100%;
`;

export const Picture = styled.div`
  margin-top: 90px;
  display: flex;
  align-self: center;
  width: 121px;
`;

export const Description = styled.div`
 font-size: 24px;
 font-weight: bold;
 color: #fff;
 margin-top: 81px;
 text-align: center;
 white-space: pre-line;
 margin-bottom: 63px;
 line-height: 30px;
`;

export const Registration = styled.p`
 font-size: 14px;
 color: #BBBBBB;
 margin-top: 14px;
 text-align: center;
`;
