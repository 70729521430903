import { Card as AntdCard } from 'antd';
import styled from 'styled-components';

export const Card = styled(AntdCard)`
  align-items: center;
  display: flex;
  min-height: 70px;
  width: 100%;
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};

  .ant-card-body {
    padding: 16px;
    width: 100%;
  }
`;

export const Body = styled.div`
  p {
    color: #000000;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 0;
  }
`;
