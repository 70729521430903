import { Moment } from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import {
  PROJECT_STATUS_LABELS_VALUES,
  PROJECT_STATUS_LABELS,
  STATUS_ENUM,
} from '../../../constants/status';
import { ProjectProgressBar } from '../../ProjectProgressBar';
import { Spacer } from '../../Spacer';
import { Note } from '../Note';

import {
  Card as CardStyle,
  Column,
  Header,
  PaddingContainer,
  Title,
  RightText,
} from './styled';

export interface ProjectOverviewCarpProps {
  line1?: string;
  line2?: string;
  line3?: string;
  note?: {
    text?: string;
    bolded?: string;
  };
  right?: string;
  status?: PROJECT_STATUS_LABELS_VALUES;
  title?: string;
  to?: string;
  extra?: {
    id?: string | number;
    bidStart?: string | Date | Moment;
    rawStatus?: STATUS_ENUM;
    estimatedDate?: string | Date | Moment;
    [key: string]: any;
  };
}

export function Card({
  extra,
  note,
  line1 = '123 Street',
  line2 = 'City, ST 12345',
  line3 = '',
  right = '$8,500',
  title = 'Fix Something: Roof',
  status = 'All Bids In',
  to = '/projects/1',
}: ProjectOverviewCarpProps) {
  const WrappingComp: any = to ? Link : 'div';

  const WrappingCompProps = to ? { to } : {};

  return (
    <WrappingComp {...WrappingCompProps}>
      <CardStyle>
        <PaddingContainer>
          <Header>
            <Title strong>{title}</Title>
            <RightText>{right}</RightText>
          </Header>
          <Column>
            <span>{line1}</span>
            <span>{line2}</span>
            {line3 && <span>{line3}</span>}
          </Column>
          <Spacer size="small" />
          {note && <Note text={note.text} bolded={note.bolded} />}
        </PaddingContainer>
        {status !== PROJECT_STATUS_LABELS.completed && (
          <ProjectProgressBar
            status={extra?.rawStatus}
            start={extra?.bidStart}
            completion={extra?.estimatedDate}
          />
        )}
      </CardStyle>
    </WrappingComp>
  );
}
