/*global google*/ // To disable any eslint 'google not defined' errors

import { Input } from 'antd';
import debounce from 'lodash/debounce';
import React, { useState, useCallback, useMemo, useRef } from 'react';
import Script from 'react-load-script';

import { GOOGLE_API_KEY } from '../../config';
import parseGooglePlace from '../../helpers/googlePlaces';

import { Container, Prediction, PredictionsContainer } from './styled';

let autocomplete: any;
let places: any;

export interface AddressAutocompleteProps {
  inputRef?: React.MutableRefObject<Input | undefined>;
  label?: string;
  value: string;
  error?: any;
  onSelected: (
    param: string,
    details?: null | {
      apartmentNumber?: string;
      city?: string;
      stateShort?: string;
      streetName?: string;
      streetNumber?: string | number;
      zipCode?: string;
    },
  ) => void;
}

const AddressAutocomplete = ({
  inputRef,
  onSelected,
  value,
}: AddressAutocompleteProps) => {
  const [predictions, setPredictions] = useState([]);
  const [selected, setSelected] = useState(value);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const wrapperRef = useRef(null);

  const onChangeDestination = useCallback(
    debounce(async (destination: string) => {
      setLoading(true);
      if (autocomplete && destination) {
        autocomplete.getPlacePredictions(
          {
            input: destination,
            componentRestrictions: { country: 'us' },
          },
          (suggestions: any) => {
            setPredictions(suggestions || []);
            setLoading(false);
          },
        );
      } else {
        setPredictions([]);
        setLoading(false);
      }
    }, 350),
    [setPredictions],
  );

  const getPlaceDetails = useCallback(async (id: string): Promise<any> => {
    const request = {
      placeId: id,
      fields: ['address_component'],
    };
    // eslint-disable-next-line no-undef
    return new Promise<{
      address_components?: { types: string[]; long_name: number }[];
    }>((resolve) => {
      places.getDetails(request, (place: any, status: any) => {
        resolve(place || {});
      });
    });
  }, []);

  const selectPrediction = useCallback(
    async (prediction: { description: string; place_id: string }) => {
      let addressToUse = prediction.description;
      const placeDetails = await getPlaceDetails(prediction.place_id);
      const parsedData = parseGooglePlace(placeDetails || {});
      const zipCode = parsedData?.zipCode;

      if (zipCode && !prediction.description.includes(String(zipCode))) {
        addressToUse = prediction.description.replace(
          /usa/i,
          `${zipCode}, USA`,
        );
      }

      setSelected(addressToUse);
      onSelected(addressToUse, parsedData);
      setPredictions([]);
    },
    [getPlaceDetails, onSelected],
  );
  const locationPredictions = useMemo(
    () => (
      <PredictionsContainer>
        {active && !predictions.length && (
          <Prediction key="autocomplete-no-results-key" last noClick>
            {loading ? 'Loading ...' : 'No Results'}
          </Prediction>
        )}
        {true &&
          predictions.map(
            (prediction: { place_id: string; description: string }, index) => (
              <Prediction
                key={prediction.place_id}
                className="prediction"
                onKeyPress={({ key }) => {
                  if (key === 'Enter') {
                    selectPrediction(prediction);
                    setActive(false);
                    setLoading(false);
                  }
                }}
                onMouseDown={() => {
                  selectPrediction(prediction);
                  setActive(false);
                  setLoading(false);
                }}
                last={index === predictions.length - 1}
                tabIndex={0}
              >
                {prediction.description}
              </Prediction>
            ),
          )}
      </PredictionsContainer>
    ),
    [active, loading, predictions, selectPrediction],
  );

  return (
    <Container ref={wrapperRef}>
      <div id="map" style={{ display: 'none' }} />
      <Script
        url={`https://maps.googleapis.com/maps/api/js?key=${GOOGLE_API_KEY}&libraries=places`}
        onLoad={() => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          autocomplete = new google.maps.places.AutocompleteService();

          // the map is required by the places service

          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          const map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: 29.7564, lng: -95.3632 },
            zoom: 15,
          });

          // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
          // @ts-ignore
          places = new google.maps.places.PlacesService(map);
        }}
      />
      <Input
        ref={inputRef as any}
        autoComplete="off"
        name="address"
        onBlur={(event: any) => {
          let target: Element | null = event.relatedTarget;
          if (target === null) {
            target = document.activeElement;
          }

          if (target && target.className.indexOf('prediction') > -1) {
            return;
          }

          setActive(false);
          setLoading(false);
        }}
        onFocus={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeDestination(e.target.value);
          setSelected(e.target.value);
          setActive(true);
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          onChangeDestination(e.target.value);
          setSelected(e.target.value);
        }}
        value={selected}
      />
      {locationPredictions}
    </Container>
  );
};

export default AddressAutocomplete;
