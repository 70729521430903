import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space, Form, notification, Input, Checkbox  } from 'antd';
import MaskedInput from 'antd-mask-input';
import React, { useState, useCallback, useEffect } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Registration, Wrapper } from './styled';
import { Button, BackButton } from '../../components/Button';
import { Header } from '../../components/Header';
import { Spacer } from '../../components/Spacer';
import { Arrow } from '../../components/Arrow';
import { useAuthGetCodeMutation } from '../../generated/graphql';
import { replaceNonNumeric } from '../../helpers';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Ellipsis } from '../../components/Ellipsis';
import { getInputMask } from '../../helpers/input-mask';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

function isPhoneNumberValid(value: string) {
  return replaceNonNumeric(value).length > 6;
}

type IProps = Partial<
  RouteComponentProps<
    {},
    {},
    {
      back?: string;
      verifiedCalled?: boolean;
      newAccount?: boolean;
      phoneNumber?: string;
      agreed?: boolean;
      isRegistration?: boolean;
      userFirstName?: string;
      userLastName?: string;
      countryCode?: string;
    }
  >
>;

export default function RegistrationPage({ history, location }: IProps) {
  const [mutate, { loading }] = useAuthGetCodeMutation();
  const [phoneNumber, setPhoneNumber] = useState('');
  // const [countryCode, setCountryCode] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const [agreed, setValue] = useState(false);
  // const [mask, setMask] = useState("(111) 111-1111");

  const back = location?.state?.back || ROUTES.LANDING;

  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
    };
  });

  console.log('agreed', agreed);

  const isValid = isPhoneNumberValid(phoneNumber) && (userFirstName && userLastName && agreed);

  // If failed notifcation
  // Connect to api
  const authVerify = useCallback(async (userFirstName, userLastName) => {
    const phoneNumberStripped = replaceNonNumeric(phoneNumber);

    try {
      await mutate({
        variables: {
          phoneNumber: `+` + phoneNumberStripped,
          agreed: true,
        },
      });
    } catch (error) {
      notification.error({ message: error.message });
      console.log(error);
      return;
    }
    history?.push({
      pathname: ROUTES.VERIFY,
      state: {
        back: ROUTES.REGISTRATION,
        verifiedCalled: true,
        newAccount: true,
        phoneNumber: phoneNumberStripped,
        userFirstName,
        userLastName,
        //countryCode
      },
    });
  }, [agreed, history, mutate, phoneNumber, userFirstName, userLastName]);

  return (
    <FullWidthPageWrapper>
      {back && (
        <BackButton to={back} replace>
          <Space>
            <Arrow />
          </Space>
        </BackButton>
      )}
      <Header>Sign Up</Header>
      <div  className={'login__description'}>
        With your phone number
      </div>
      <Spacer size="large" />
      <Form
        onFinish={() => {
          if (!isValid) {
            return;
          }
          authVerify(userFirstName, userLastName);
        }}
      >
        <div className={'login__title'}>
          Mobile phone number
        </div>
        <div className={'flex-container'}>
          <div className={'input-wrapper'}>
          <PhoneInput
            placeholder="Your mobile number"
            value={phoneNumber}
            defaultCountry="US"
            onChange={( value ) => {
              setPhoneNumber(value);
            }}
          />
          </div>
        </div>
        <div className={'login__title login__title--margin'}>
          First name
        </div>
        <Form.Item
          name="firstName"
          rules={[{ required: true, message: 'A first name is required.' }]}
        >
          <div className={'border'}>
            <Input
              style = {{height: "46px"}}
              placeholder="Your name"
              disabled={loading}
              value={userFirstName}
              onChange={({ target: { value } }) => {
                const clearValue = value.trim().replace(/ +/g, ' ');
                setUserFirstName(clearValue);
              }}
            />
          </div>
        </Form.Item>
        <div className={'login__title'}>
          Last name
        </div>
        <Form.Item
          name="lastName"
          rules={[{ required: true, message: 'A last name is required' }]}
        >
          <div className={'border'}>
            <Input
              style = {{height: "46px"}}
              placeholder="Your name"
              disabled={loading}
              value={userLastName}
              onChange={({ target: { value } }) => {
                const clearValue = value.trim().replace(/ +/g, ' ');
               setUserLastName(clearValue);
             }}
            />
          </div>
        </Form.Item>
        <Checkbox.Group>
          <Checkbox
            onChange={() => {
              setValue(!agreed);
            }}
          >
            I agree to the{' '}
            <Link
              className="link-bold"
              to={ROUTES.TERMS_PAGE}
            >
              Terms of Service.
            </Link>
          </Checkbox>
        </Checkbox.Group>
        <Wrapper size="medium">
          <Ellipsis />
        </Wrapper>
        <Button
          htmlType="submit"
          disabled={!isValid}
          loading={loading}
          type="primary"
        >
          Verify phone
        </Button>
      </Form>
      <Registration>
        Already have an account?
        <Link  className="main-link main-link--black link-bold" to={{
            pathname: ROUTES.LOGIN,
          }}> Sign In</Link>
      </Registration>
    </FullWidthPageWrapper>
  );
}
