import { PlusCircleOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import React, { useContext } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';
import styled from 'styled-components';

import { Header } from '../../components/Header';
import { ProjectOverview } from '../../components/ProjectOverview';
import { useProjectsOverviewTransformer } from '../../components/ProjectOverview/hooks';
import { Spacer } from '../../components/Spacer';
import { UserContext } from '../../context/User';
import { useAllUserProjectsQuery } from '../../generated/graphql';
import { FullWidthPageWrapper } from '../../styles/Layout';

const ServiceRequestLink = styled(Link)`
  font-size: 20px;
`;

type IProps = Partial<RouteComponentProps>;

function ServicePageList() {
  const { user } = useContext(UserContext);

  const { data } = useAllUserProjectsQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      userId: Number(user?.id),
    },
  });
  const { completed, uncompleted } = useProjectsOverviewTransformer(data);

  return (
    <>
      <ProjectOverview.List>
        {!uncompleted.length && (
          <Typography.Text>No Active Projects Found</Typography.Text>
        )}
        {uncompleted.map((project) => {
          return (
            <ProjectOverview.Card
              key={`project-${project?.extra?.id}`}
              extra={project?.extra}
              line1={project?.line1}
              line2={project?.line2}
              line3={project?.line3}
              note={project?.note}
              right={project?.right}
              status={project?.status}
              title={project?.title}
              to={project?.to}
            />
          );
        })}
      </ProjectOverview.List>
      <Spacer size="large" />
      <Typography.Title level={4}>Completed</Typography.Title>
      <ProjectOverview.List>
        {!completed.length && (
          <Typography.Text>No Completed Projects Yet</Typography.Text>
        )}
        {completed.map((project) => {
          return (
            <ProjectOverview.Card
              key={`project-${project?.extra?.id}`}
              extra={project?.extra}
              line1={project?.line1}
              line2={project?.line2}
              line3={project?.line3}
              right={project?.right}
              status={project?.status}
              title={project?.title}
              to={project?.to}
            />
          );
        })}
      </ProjectOverview.List>
    </>
  );
}

export default function DashboardPage({ history, location }: IProps) {
  return (
    <FullWidthPageWrapper>
      <Header
        right={
          <ServiceRequestLink to="/service-request">
            <PlusCircleOutlined size={28} style={{ fontSize: 28 }} />
          </ServiceRequestLink>
        }
      >
        Projects
      </Header>
      <ServicePageList />
    </FullWidthPageWrapper>
  );
}
