import clamp from 'lodash/clamp';
import React from 'react';

import { Wrapper, Bar, Text } from './styled';

interface IProps {
  children?: string;
  progress?: number;
  color?: 'green' | 'blue';
  textColor?: string;
}

export function ProgressBar({
  children = 'In Progress',
  progress = 0.5,
  color = 'blue',
  textColor,
}: IProps) {
  return (
    <Wrapper color={color}>
      <Bar progress={clamp(progress, 0, 1)} color={color} />
      <Text style={{ color: textColor }}>{children}</Text>
    </Wrapper>
  );
}
