import { ApolloProvider } from '@apollo/client';
import { Elements } from '@stripe/react-stripe-js';
import { notification } from 'antd';
import React from 'react';
import HttpsRedirect from 'react-https-redirect';
import { ThemeProvider } from 'styled-components';

import { CartContextProvider } from '../../context/Cart';
import { UserContextProvider } from '../../context/User';
import { apolloClient } from '../../helpers/apollo';
import { stripePromise } from '../../helpers/stripe';
import { Router } from '../../router';
import theme from '../../styles';
import { GlobalStyle } from '../../styles/GlobalStyle';

notification.config({
  duration: 15,
  top: 20,
});

const isDevelopment = process.env.NODE_ENV === 'development';

export function App() {
  return (
    <HttpsRedirect disabled={isDevelopment}>
      <ApolloProvider client={apolloClient}>
        <Elements stripe={stripePromise}>
          <UserContextProvider>
            <CartContextProvider>
              <ThemeProvider theme={theme}>
                <GlobalStyle />
                <Router />
              </ThemeProvider>
            </CartContextProvider>
          </UserContextProvider>
        </Elements>
      </ApolloProvider>
    </HttpsRedirect>
  );
}
