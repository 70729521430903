import TagManager from 'react-gtm-module';

import { GOOGLE_GTM_ID } from '../../config';

export function setupGoogleTagManager() {
  if (!GOOGLE_GTM_ID) {
    console.warn('No GTM Code');
    return;
  }

  // Init Google Tag Manager
  const tagManagerArgs = {
    gtmId: GOOGLE_GTM_ID,
  };

  TagManager.initialize(tagManagerArgs);
}
