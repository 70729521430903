/* eslint-disable no-case-declarations */
import { IState, IReducerArgs, USER_ACTION_TYPES } from './types';

import { authStorage } from '.';

export function reducer(state: IState, { type, payload }: IReducerArgs) {
  switch (type) {
    case USER_ACTION_TYPES.FETCHED:
    case USER_ACTION_TYPES.UPDATE:
      const updatedUser = {
        ...state.user,
        ...payload,
        isCreating: false,
      };

      const updatedState = {
        ...state,
        user: updatedUser,
      };

      if (USER_ACTION_TYPES.FETCHED === type) {
        updatedState.hasFetched = true;
      }

      authStorage.save(updatedUser);

      return updatedState;
    case USER_ACTION_TYPES.LOGIN:
      const user = {
        isCreating: payload.isCreating,
        token: payload.token,
        id: payload.userId,
        address: payload.address,
        promoBalance: payload.promoBalance,
        ...payload.info,
      };

      authStorage.save(user);

      return {
        ...state,
        loading: false,
        hasFetched: true,
        user,
      };
    case USER_ACTION_TYPES.LOGOUT:
      authStorage.clear();

      return {
        ...state,
        user: undefined,
        loading: false,
      };

    case USER_ACTION_TYPES.FETCHING:
      return {
        ...state,
        loading: true,
      };
    default:
      return state;
  }
}
