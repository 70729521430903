import styled from 'styled-components';

interface IProps {
  size?: 'small' | 'medium' | 'large';
}

const SizeToPixel = {
  small: '8px',
  medium: '16px',
  large: '32px',
};

export const Spacer = styled.div<IProps>`
  background: #fff;
  height: ${({ size = 'medium' }) => SizeToPixel[size]};
  width: 100%;
`;
