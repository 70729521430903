import { Image } from 'cloudinary-react';
import React from 'react';

import { cloudName } from '../../../config';

interface IProps {
  src: string;
}

export function ImageCloudinary({ src }: IProps) {
  return (
    <Image
      cloudName={cloudName}
      crop="scale"
      height={64}
      publicId={src}
      transformation={[
        { height: 64, width: 64, crop: 'pad', background: 'black' },
      ]}
      style={{ background: 'grey' }}
      width={64}
    />
  );
}
