import React from 'react';

import { useContractorByIdQuery } from '../../generated/graphql';
import { Card } from '../Card';

import { InfoRow } from './InfoRow';

interface IProps {
  children?: string;
  src?: string;
}

export function ContractorCard({ children, src }: IProps) {
  return (
    <Card>
      <InfoRow src={src}>{children}</InfoRow>
    </Card>
  );
}

export function ContractorCardWithData({ id }: { id?: string | number }) {
  const { data, loading } = useContractorByIdQuery({
    variables: {
      id: Number(id) || 0,
    },
  });

  return (
    <ContractorCard src={data?.Contractor_by_pk?.profilePic}>
      {loading ? 'Loading ...' : data?.Contractor_by_pk?.name}
    </ContractorCard>
  );
}
