import { ArrowRightOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { Form, notification, Modal, Card, Result, Typography } from 'antd';
import React, { useState, useEffect, useContext, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import { AddressInputCard } from '../../components/AddressInput';
import { Button } from '../../components/Button';
import { Header } from '../../components/Header';
import { ImagePreviews } from '../../components/ImagePreviews';
import { RadioGroup } from '../../components/RadioGroup';
import { Spacer } from '../../components/Spacer';
import { TextAreaInput } from '../../components/TextAreaInput';
import {
  Header as UploadPhotoInputHeader,
  UploadPhotoInput,
} from '../../components/UploadPhotoInput';
import {
  getServiceRequestOptions,
  getServiceRequestPlaceholder,
} from '../../constants';
import { CartContext } from '../../context/Cart';
import { UserContext } from '../../context/User';
import { USER_ACTION_TYPES } from '../../context/User/types';
import {
  useCreateServiceRequestMutation,
  useAddAddressMutation,
  Project_Insert_Input,
  Address_Insert_Input,
} from '../../generated/graphql';
import { useForceUpdate } from '../../hooks';
import { ROUTES } from '../../router';
import theme from '../../styles';
import { FullWidthPageWrapper } from '../../styles/Layout';
// tslint:disable-next-line:ban-types
declare let gtag: Function;

const Hidden = styled.div`
  display: ${({ hidden = false }) => (hidden ? 'none' : 'inherit')};
`;

function getAddressPresence(address: any = {}) {
  return address.street && address.city && address.state;
}
type IProps = Partial<RouteComponentProps>;

const initialAddressValues = {
  street: undefined,
  city: undefined,
  state: undefined,
  zip: undefined,
};

const initialValues = {
  address: initialAddressValues,
  apartment: undefined,
  area: undefined,
  category: undefined,
  details: undefined,
  format: undefined,
  location: undefined,
  material: undefined,
  reason: undefined,
  stairs: undefined,
  start: undefined,
  photos: [],
};

export function ServiceRequestPage({ history }: IProps) {
  const { dispatch, user } = useContext(UserContext);
  const { cart } = useContext(CartContext);
  const [mutate] = useCreateServiceRequestMutation();

  const [addAddress] = useAddAddressMutation();

  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [isComplete, setComplete] = useState(false);
  const [isConsultingOrder, setConsultingOrder] = useState(false);

  // call your hook here
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    form.setFieldsValue({
      ...initialValues,
      address: user?.address || initialAddressValues,
      apartment: user?.address?.street2,
      ...cart,
    });
    forceUpdate();
  }, [cart, forceUpdate, form, user]);

  const address = form.getFieldValue('address');

  const addressSelected = getAddressPresence(address);

  const areaSelected = form.getFieldValue('area');
  const categorySelected = form.getFieldValue('category');
  const formatSelected = form.getFieldValue('format');
  const locationSelected = form.getFieldValue('location');
  const materialSelected = form.getFieldValue('material');
  const reasonSelected = form.getFieldValue('reason');
  const startSelected = form.getFieldValue('start');
  const stairsSelected = form.getFieldValue('stairs');

  const isReasonRequired =
    locationSelected === 'Indoors' && categorySelected === 'Fix Something';

  const showDetails = useMemo(() => {
    const hasBasics = Boolean(
      startSelected &&
        materialSelected &&
        categorySelected &&
        locationSelected &&
        formatSelected,
    );

    if (!hasBasics) {
      return false;
    }

    if (isReasonRequired && !reasonSelected) {
      return false;
    }

    if (locationSelected !== 'Other' && !areaSelected) {
      return false;
    }

    return true;
  }, [
    areaSelected,
    categorySelected,
    formatSelected,
    isReasonRequired,
    locationSelected,
    materialSelected,
    reasonSelected,
    startSelected,
  ]);

  return (
    <FullWidthPageWrapper>
      <Header>Service Request</Header>
      <Form
        scrollToFirstError
        form={form}
        initialValues={{
          ...initialValues,
          address: user?.address || initialAddressValues,
        }}
        name="service-request"
        onFinishFailed={(errorInfo) => {
          console.log(errorInfo);
        }}
        onFinish={async (values) => {
          gtag('event', 'conversion', {'send_to': 'AW-586255786/RSYaCIvj36MCEKqbxpcC'});
          new Image().src='https://clickserv.sitescout.com/conv/3089a0477856e218';
          setLoading(true);
          const valAddress = values?.address;
          const formattedAddress = `${valAddress?.street}, ${valAddress?.city}, ${valAddress?.state} ${valAddress?.zip}`;

          const area = values?.location === 'Other' ? 'Other' : values?.area;

          const name = `${values?.category}: ${area}`;

          const payload: Project_Insert_Input = {
            address: formattedAddress,
            address2: values?.apartment || null,
            area,
            category: values?.category,
            details: values?.details,
            format: values?.format,
            location: values?.location,
            materialQuality: values?.material,
            name,
            reason: isReasonRequired ? values?.reason : null,
            stairs: values?.location === 'Indoors' ? values?.stairs : null,
            timing: values?.start,
            status: isConsultingOrder ? 'consult' : 'created',
            Images: {
              data: values?.photos.map((photo: any) => ({
                cloudinaryId: photo?.url,
                type: photo?.type,
              })),
            },
          };

          if (user?.role === 'admin') {
            payload.userId = Number(user?.id);
          }

          try {
            await mutate({
              variables: {
                object: payload,
              },
            });
          } catch (error) {
            notification.warn({
              message: error?.message,
            });
            setLoading(false);
            return;
          }

          if (!user?.address) {
            try {
              const payload: Address_Insert_Input = {
                city: valAddress?.city,
                state: valAddress?.state,
                street: valAddress?.street,
                street2: values?.apartment,
                zip: valAddress?.zip,
              };

              if (user?.role === 'admin') {
                payload.userId = Number(user?.id);
              }

              const results = await addAddress({
                variables: {
                  object: payload,
                },
              });

              const address = results.data?.insert_Address_one;
              dispatch({
                type: USER_ACTION_TYPES.UPDATE,
                payload: {
                  address,
                },
              });
            } catch (error) {
              notification.warn({
                message: error?.message,
              });
              console.log('Unable to update Address');
            }
          }

          setComplete(true);
          return false;
        }}
      >
        <Form.Item name="address" valuePropName="address">
          <AddressInputCard forceUpdate={forceUpdate} />
        </Form.Item>

        <Typography>
          <Typography.Text>
            Please answer the following questions to submit your project.
          </Typography.Text>
        </Typography>

        <Spacer />

        <RadioGroup
          name="start"
          hidden={!addressSelected}
          title={getServiceRequestPlaceholder('start')}
          options={getServiceRequestOptions('start')}
          onChange={forceUpdate}
          required
        />

        <RadioGroup
          hidden={!startSelected}
          name="material"
          title={getServiceRequestPlaceholder('material')}
          options={getServiceRequestOptions('material')}
          onChange={forceUpdate}
          required
        />

        <RadioGroup
          hidden={!materialSelected}
          name="location"
          title={getServiceRequestPlaceholder('location')}
          options={getServiceRequestOptions('location')}
          onChange={() => {
            form.setFieldsValue({
              area: undefined,
              stairs: undefined,
              reason: undefined,
            });
            forceUpdate();
          }}
          required
        />

        <RadioGroup
          hidden={!locationSelected || locationSelected !== 'Indoors'}
          name="stairs"
          title={getServiceRequestPlaceholder('stairs')}
          options={getServiceRequestOptions('stairs')}
          onChange={forceUpdate}
          required={locationSelected === 'Indoors'}
        />

        <RadioGroup
          hidden={
            !locationSelected ||
            (locationSelected === 'Indoors' && !stairsSelected)
          }
          name="category"
          title={getServiceRequestPlaceholder('category')}
          options={getServiceRequestOptions('category')}
          onChange={() => {
            form.setFieldsValue({
              reason: undefined,
            });
            forceUpdate();
          }}
          required
        />

        <RadioGroup
          mobileMaxWidth
          hidden={!categorySelected || locationSelected === 'Other'}
          name="area"
          title={getServiceRequestPlaceholder('area')}
          options={getServiceRequestOptions(
            'area',
            locationSelected,
            categorySelected,
          )}
          onChange={forceUpdate}
          required={locationSelected !== 'Other'}
        />

        <RadioGroup
          hidden={!isReasonRequired || !areaSelected}
          name="reason"
          title={getServiceRequestPlaceholder('reason')}
          options={getServiceRequestOptions('reason')}
          onChange={forceUpdate}
          required={isReasonRequired}
        />

        <RadioGroup
          hidden={
            (isReasonRequired && !reasonSelected) ||
            (!areaSelected && locationSelected !== 'Other') ||
            (locationSelected === 'Other' && !categorySelected)
          }
          name="format"
          title={getServiceRequestPlaceholder('format')}
          options={getServiceRequestOptions('format')}
          onChange={forceUpdate}
          required
        />

        <Hidden hidden={!showDetails}>
          <TextAreaInput
            placeholder={getServiceRequestPlaceholder('details')}
            name="details"
            title="Details"
            required
          />
          <Spacer size="small" />
          <UploadPhotoInputHeader />
          <UploadPhotoInput
            title={getServiceRequestPlaceholder('currentPhoto')}
            onUploadSuccess={(publicId: string) => {
              form.setFieldsValue({
                photos: [
                  ...form.getFieldValue('photos'),
                  { url: publicId, type: '' },
                ],
              });
            }}
          />
          <Spacer size="small" />
          <Form.Item name="photos" valuePropName="photos">
            <ImagePreviews
              filter=""
              onDelete={(deletedIndex, src) => {
                const images = form.getFieldValue('photos');

                const sliceImages = images.filter(
                  (_: any, index: any) => index !== deletedIndex,
                );

                form.setFieldsValue({ photos: sliceImages });
              }}
            />
          </Form.Item>
          <Spacer size="small" />
          <UploadPhotoInput
            title={getServiceRequestPlaceholder('conceptPhoto')}
            onUploadSuccess={(publicId: string) => {
              form.setFieldsValue({
                photos: [
                  ...form.getFieldValue('photos'),
                  { url: publicId, type: 'concept' },
                ],
              });
            }}
          />
          <Spacer size="small" />
          <Form.Item name="photos" valuePropName="photos">
            <ImagePreviews
              filter="concept"
              onDelete={(deletedIndex, src) => {
                const images = form.getFieldValue('photos');

                const sliceImages = images.filter(
                  (_: any, index: any) => index !== deletedIndex,
                );

                form.setFieldsValue({ photos: sliceImages });
              }}
            />
          </Form.Item>
        </Hidden>
        <Hidden hidden={!showDetails}>
          <div style={{ textAlign: 'center' }}>
            <Button
              type="primary"
              onClick={form.submit}
              loading={loading}
              isCTA
            >
              Create Service Request <ArrowRightOutlined />
            </Button>
            <Typography>
              <Typography.Text>Or</Typography.Text>
            </Typography>
            <Button
              ghost
              type="primary"
              onClick={() => {
                setConsultingOrder(true);
                setTimeout(form.submit);
              }}
              loading={loading}
              isCTA
            >
              Consult with Brixos <ArrowRightOutlined />
            </Button>
          </div>
        </Hidden>
      </Form>
      <Modal
        centered
        maskStyle={{ background: '#262626' }}
        className="bid-modal"
        closable={false}
        title={null}
        footer={null}
        visible={isComplete}
      >
        <Card style={{ textAlign: 'center', padding: 0 }}>
          {isComplete && (
            <>
              <Result
                icon={
                  <CheckCircleOutlined
                    style={{ color: theme.colors.tertiary }}
                  />
                }
                status="success"
                title={
                  isConsultingOrder
                    ? `Consulting Request Created`
                    : `Service Request Created`
                }
                subTitle={
                  isConsultingOrder
                    ? `Request has been created. Brixos will reach out to you shortly.`
                    : `Service request has been created. It will take some time to gather bids for your request.`
                }
                extra={[
                  <Button
                    type="primary"
                    key="console"
                    onClick={() => {
                      history?.push(ROUTES.DASHBOARD);
                    }}
                  >
                    Go To Projects
                  </Button>,
                ]}
              />
            </>
          )}
        </Card>
      </Modal>
    </FullWidthPageWrapper>
  );
}
