import { Space } from 'antd';
import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { BackButton, BlackButton } from '../../components/Button';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Title, Message } from './styled';
import { Arrow } from '../../components/Arrow';
type IProps = Partial<
  RouteComponentProps<
    {},
    {},
    {
      back?: string;
    }
    >
  >;


export default function RegistrationSuccessPage({ history, location }: IProps) {
  const back = location?.state?.back || ROUTES.LOGIN;

  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    document?.querySelector('main + div + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
      document?.querySelector('main + div + div + div')?.classList.remove('hide');
    };
  });

  const goToServiceRequestPage = ()=> {
    history?.push(ROUTES.SERVICE_REQUEST);
  };

  return (
    <FullWidthPageWrapper>
      <BackButton to={back} replace>
        <Space>
          <Arrow />
        </Space>
      </BackButton>
      <Title>
        YOU'RE ALL SET
      </Title>
      <Message>
        Now {'\n'}
        let's create {'\n'}
        your first {'\n'}
        project
      </Message>
      <BlackButton
        onClick={ goToServiceRequestPage }
      >
        Create a project
      </BlackButton>
    </FullWidthPageWrapper>
  );
}
