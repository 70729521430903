import { Space, Typography } from 'antd';
import React from 'react';

import {
  useGetPaymentMethodsQuery,
  PaymentMethod,
} from '../../generated/graphql';
import { Button } from '../Button';
import { Header } from '../Header';
import { Spacer } from '../Spacer';

interface IProps {
  onAddNew?: any;
  onSelected?: (card?: Partial<PaymentMethod> | null) => void | null;
}

export function PaymentMethods({ onAddNew, onSelected }: IProps) {
  const { data, loading, error } = useGetPaymentMethodsQuery({
    fetchPolicy: 'network-only',
  });
  return (
    <div style={{ width: '100%' }}>
      <Header>Select Payment Method</Header>
      <Spacer size="small" />
      {error && <div>Unable to fetch payment methods</div>}
      {loading && <div>Loading ...</div>}
      <Space style={{ width: '100%' }} direction="vertical">
        {!data?.getPaymentMethods?.length && !loading && (
          <Typography>
            <Typography.Text>No Current Payment Methods</Typography.Text>
          </Typography>
        )}
        {data?.getPaymentMethods?.map((method) => {
          return (
            <Button
              key={`payment-method-${method?.id}`}
              style={{ textAlign: 'left' }}
              onClick={() => {
                if (onSelected) {
                  onSelected(method);
                }
              }}
            >
              <div>Card ending in {method?.card?.last4}</div>
              <div>
                Expires {method?.card?.exp_month?.toString().padStart(2, '00')}{' '}
                / {method?.card?.exp_year}
              </div>
            </Button>
          );
        })}
      </Space>
      <Spacer />
      <div>
        <Button onClick={onAddNew} isCTA>
          Add New Card
        </Button>
      </div>
    </div>
  );
}
