import styled from 'styled-components';

interface IPrediction {
  noClick?: boolean;
  last?: boolean;
}

export const Container = styled.div`
  position: relative;
  width: 100%;
`;

export const PredictionsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  z-index: 10;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;

export const Prediction = styled.div<IPrediction>`
  width: 100%;
  background-color: white;
  padding: 8px 10px;
  border: 1px solid #d9d9d9;
  border-top: none;
  border-bottom-left-radius: ${({ last }) => (last ? '2px' : '0')};
  border-bottom-right-radius: ${({ last }) => (last ? '2px' : '0')};
  cursor: pointer;
  transition: background-color 0.2s;
  cursor: ${({ noClick = false }) => (noClick ? 'inherit' : 'pointer')};

  &:hover {
    background-color: #e2e2e2;
  }

  &:focus {
    outline: rgba(0, 0, 0, 0.15);
    outline-width: initial;
    outline-style: auto;
  }
`;
