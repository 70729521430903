import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import { ROUTES } from '../../router';

export function NonLoggedInRoute({ component: Component, user, ...rest }: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (user) {
          return <Redirect to={ROUTES.DASHBOARD} />;
        } else {
          return <Component {...rest} {...props} />;
        }
      }}
    />
  );
}

export function AuthenticatedRoute({
  component: Component,
  user,
  ...rest
}: any) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (!user) {
          return <Redirect to={ROUTES.LANDING} />;
        } else {
          return <Component {...rest} {...props} />;
        }
      }}
    />
  );
}
