import { Typography, Form } from 'antd';
import React from 'react';

import Svgs, { IconListKeys } from '../Svgs';

import { Button, Center, RadioGroupWrapper } from './styled';

export interface IOptions {
  label?: string;
  value?: string | number;
  icon?: IconListKeys;
}

interface IProps {
  hidden?: boolean;
  mobileMaxWidth?: boolean;
  name?: string;
  onChange?: any;
  options?: IOptions[];
  required?: boolean;
  title: string;
}

function getIcon(icon?: IconListKeys) {
  const Icon = icon ? Svgs[icon] : null;

  return Icon ? <Icon /> : null;
}

export function RadioGroup({
  hidden,
  name,
  onChange,
  options = [],
  required,
  title,
  mobileMaxWidth = false,
}: IProps) {
  return (
    <div style={{ display: hidden ? 'none' : 'inherit' }}>
      <Typography>
        <Typography.Title level={3}>{title}</Typography.Title>
        <Form.Item name={name} rules={[{ required }]}>
          <RadioGroupWrapper
            defaultValue="a"
            buttonStyle="solid"
            onChange={onChange}
          >
            {options.map(({ label, value, icon }) => (
              <Button value={value} key={value} mobileMaxWidth={mobileMaxWidth}>
                <Center>
                  {getIcon(icon)}
                  <div>{label}</div>
                </Center>
              </Button>
            ))}
          </RadioGroupWrapper>
        </Form.Item>
      </Typography>
    </div>
  );
}
