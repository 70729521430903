import styled from 'styled-components';

export const Column = styled.div<{ rightAligned?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ rightAligned }) =>
    rightAligned ? 'flex-end' : 'flex-start'};
`;

export const RightColumn = styled(Column)`
  align-items: flex-end;
`;

export const Header = styled.div`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.secondary};
`;

export const Stat = styled.div`
  font-size: 20px;
  color: #000;
`;
