import { ArrowLeftOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import React, { useCallback, useContext } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';

import { BackButton } from '../../components/Button';
import { Header } from '../../components/Header';
import { ProjectDetails } from '../../components/ProjectDetails';
import { STATUS_ENUM } from '../../constants/status';
import { UserContext } from '../../context/User';
import { useProjectByIdQuery } from '../../generated/graphql';
import { formatCurrency } from '../../helpers/formatters';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';

type IProps = Partial<RouteComponentProps>;

export default function ProjectDetailsPage({ history, location }: IProps) {
  const { id } = useParams();
  const { user } = useContext(UserContext);
  const { data, loading } = useProjectByIdQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      id,
    },
  });

  const onAccept = useCallback(
    async (bidId?: string, bid?: any) => {
      const rejectedBids = data?.Project_by_pk?.Bids.filter(
        (bid) => bid.id !== Number(bidId),
      );

      history?.push({
        pathname: ROUTES.PAYMENT,
        state: {
          back: `${ROUTES.PROJECTS}/${id}`,
          rejectedBidsIds: rejectedBids?.map((bid) => bid?.id) || [],
          bid,
          projectId: id,
        },
      });

      return true;
    },
    [data, history, id],
  );

  const isLoading = loading && !data;

  const title = isLoading
    ? 'Loading ...'
    : `${data?.Project_by_pk?.category}: ${data?.Project_by_pk?.area}`;

  return (
    <FullWidthPageWrapper>
      <BackButton to={ROUTES.DASHBOARD} replace>
        <Space>
          <ArrowLeftOutlined />
          Back
        </Space>
      </BackButton>
      <Header
        right={
          data?.Project_by_pk?.status === STATUS_ENUM.WAITING && (
            <Typography.Text>
              Available Balance: {formatCurrency(user?.promoBalance)}
            </Typography.Text>
          )
        }
      >
        {title}
      </Header>
      <ProjectDetails
        data={data?.Project_by_pk || undefined}
        loading={isLoading}
        discount={user?.promoBalance}
        onAccept={
          data?.Project_by_pk?.status === STATUS_ENUM.WAITING && onAccept
        }
      />
    </FullWidthPageWrapper>
  );
}
