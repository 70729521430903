import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FetchResult } from '@apollo/client';
import { Space, Form, notification } from 'antd';
import React, { useContext, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { AddressInputCard } from '../../components/AddressInput';
import { Button, BackButton } from '../../components/Button';
import { Header } from '../../components/Header';
import { Spacer } from '../../components/Spacer';
import { UserContext } from '../../context/User';
import { USER_ACTION_TYPES } from '../../context/User/types';
import {
  useUpdateAddressMutation,
  UpdateAddressMutation,
} from '../../generated/graphql';
import { useForceUpdate } from '../../hooks';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';

type IProps = Partial<
  RouteComponentProps<{}, {}, { back?: string; verifiedCalled?: boolean }>
>;

export default function UpdateAddressPage({ history, location }: IProps) {
  const { dispatch, user } = useContext(UserContext);
  const [mutate, { loading }] = useUpdateAddressMutation();
  const forceUpdate = useForceUpdate();
  const [form] = Form.useForm();

  const address = form.getFieldValue('address');
  const apartment = form.getFieldValue('apartment');

  const onSubmit = useCallback(
    async (values) => {
      let results: FetchResult<
        UpdateAddressMutation,
        Record<string, any>,
        Record<string, any>
      > = {};

      try {
        results = await mutate({
          variables: {
            id: user?.address?.id,
            _set: {
              city: values?.address?.city,
              state: values?.address?.state,
              street: values?.address?.street,
              street2: values?.apartment,
              zip: values?.address?.zip,
            },
          },
        });
      } catch (error) {
        console.log(error);
        notification.warn({
          message: 'Unable to update address, please try again.',
        });
      }

      const address = results?.data?.update_Address_by_pk;

      if (address) {
        dispatch({ type: USER_ACTION_TYPES.UPDATE, payload: { address } });
        notification.success({
          message: 'Address Updated',
        });
        history?.push(ROUTES.PROFILE);
      }
    },
    [dispatch, history, mutate, user],
  );

  return (
    <FullWidthPageWrapper>
      <BackButton to={ROUTES.PROFILE} replace>
        <Space>
          <ArrowLeftOutlined />
          Back
        </Space>
      </BackButton>
      <Header>Your Address</Header>
      <Spacer />
      <div>
        <Form
          form={form}
          onFinish={onSubmit}
          initialValues={{
            address: user?.address,
            apartment: user?.address?.street2,
          }}
        >
          <Form.Item name="address" valuePropName="address">
            <AddressInputCard forceUpdate={forceUpdate} hideLabel />
          </Form.Item>
        </Form>
        <Form.Item>
          <Button
            disabled={!address || !form.isFieldsTouched()}
            htmlType="submit"
            loading={loading}
            onClick={form.submit}
            type="primary"
          >
            Update <ArrowRightOutlined />
          </Button>
        </Form.Item>
      </div>
    </FullWidthPageWrapper>
  );
}
