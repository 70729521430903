import { Dispatch } from 'react';

export enum USER_ACTION_TYPES {
  FETCHED = 'FETCHED',
  FETCHING = 'FETCHING',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  UPDATE = 'UPDATE',
}

export interface IReducerArgs {
  type: USER_ACTION_TYPES;
  payload?: any;
}

export interface IUser {
  address?: any;
  firstName?: string;
  id?: string;
  isCreating?: boolean;
  lastName?: string;
  name?: string;
  promoBalance?: string | number;
  role?: string;
  token?: string;
}
export interface IState {
  hasFetched?: boolean;
  loading: boolean;
  user?: IUser;
}

export interface IContextValues {
  dispatch: Dispatch<IReducerArgs>;
  hasFetched?: boolean;
  loading: boolean;
  login: (data: any) => void | null;
  logout: () => void | null;
  refetch: () => Promise<void | null>;
  user?: IUser;
}
