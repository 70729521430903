import React from 'react';

import { Column, Header, Stat } from './styled';

interface IProps {
  label?: string;
  children?: string;
  rightAligned?: boolean;
}

export function LabeledText({ label, children, rightAligned }: IProps) {
  return (
    <Column rightAligned={rightAligned}>
      <Header>{label}</Header>
      <Stat>{children}</Stat>
    </Column>
  );
}
