import { IOptions } from '../components/RadioGroup';

const placeHolders = {
  start: 'What’s your timing?',
  material: 'What type of materials do you want to use?',
  location: 'Lastly, tell us a bit about your project.',
  stairs: 'Upstairs or downstairs?',
  category: 'What can Brixos do for you?',
  area:
    'Is it one of these areas? If not, press other but be sure to tell us more?',
  reason: "What's Wrong?",
  format: 'What format would you like to receive your quote in?',
  details:
    'Now give us the details. The more details you provide the better bids you will receive.',
  currentPhoto: 'Show us what you current space looks like',
  conceptPhoto: 'Show us what you want Brixos to do to your space.',
};

type placeHoldersKeys = keyof typeof placeHolders;

export function getServiceRequestPlaceholder(field: placeHoldersKeys) {
  return placeHolders[field];
}

const categoryOptions: IOptions[] = [
  {
    label: 'I want to build something.',
    value: 'Improvement',
  },
  {
    label: 'I need to fix something.',
    value: 'Fix Something',
  },
];

const projectStartOptions: IOptions[] = [
  {
    label: 'It’s urgent. I need it done now.',
    value: 'Urgent',
  },
  {
    label: 'I’m flexible, within 2 weeks.',
    value: 'Flexible',
  },
];

const bidTypeOptions: IOptions[] = [
  {
    label: 'Fixed dollar amount so it’s straight forward.',
    value: 'Fixed',
  },
  {
    label:
      'On a per square foot basis. I may want to make small adjustments along the way.',
    value: 'Per Sq Ft',
  },
];

const materialQualityOptions: IOptions[] = [
  {
    label: 'Top of the line.',
    value: 'High',
  },
  {
    label: 'Gets the job done and looks good.',
    value: 'Medium',
  },
];

const locationOptions: IOptions[] = [
  {
    label: 'Indoors',
    value: 'Indoors',
  },
  {
    label: 'Outdoors',
    value: 'Outdoors',
  },
  {
    label: 'Other',
    value: 'Other',
    icon: 'Tool',
  },
];

const areaOutdoorImprovement: IOptions[] = [
  {
    icon: 'Home',
    label: 'Home Addition',
    value: 'Home Addition',
  },
  {
    icon: 'Kitchen',
    label: 'Outdoor Kitchen',
    value: 'Outdoor Kitchen',
  },
  {
    icon: 'Pool',
    label: 'Pool',
    value: 'Pool',
  },
  {
    icon: 'Chair',
    label: 'Deck',
    value: 'Deck',
  },
  {
    icon: 'Garage',
    label: 'Garage',
    value: 'Garage',
  },
  {
    icon: 'Fence',
    label: 'Fence',
    value: 'Fence',
  },
  {
    icon: 'Toilet',
    label: 'Plumbing',
    value: 'Plumbing',
  },
  {
    icon: 'Flash',
    label: 'Electrical',
    value: 'Electrical',
  },
  {
    icon: 'Tool',
    label: 'Other',
    value: 'Other',
  },
];

const areaOutdoorFixSomething: IOptions[] = [
  {
    icon: 'House',
    label: 'House Exterior',
    value: 'House Exterior',
  },
  {
    icon: 'Fan',
    label: 'HVAC',
    value: 'HVAC',
  },
  {
    icon: 'Toilet',
    label: 'Plumbing',
    value: 'Plumbing',
  },
  {
    icon: 'Flash',
    label: 'Electrical',
    value: 'Electrical',
  },
  {
    icon: 'Grass',
    label: 'Lawn',
    value: 'Lawn',
  },
  {
    icon: 'Roof',
    label: 'Roof',
    value: 'Roof',
  },
  {
    icon: 'Pool',
    label: 'Pool',
    value: 'Pool',
  },
  {
    icon: 'Garage',
    label: 'Garage',
    value: 'Garage',
  },
  {
    icon: 'Fence',
    label: 'Fence',
    value: 'Fence',
  },
  {
    icon: 'Tool',
    label: 'Other',
    value: 'Other',
  },
];

const stairOptions: IOptions[] = [
  {
    icon: 'Upstairs',
    label: 'Upstairs',
    value: 'Upstairs',
  },
  {
    icon: 'Downstairs',
    label: 'Downstairs',
    value: 'Downstairs',
  },
];

const areaIndoors: IOptions[] = [
  {
    icon: 'Kitchen',
    label: 'Kitchen',
    value: 'Kitchen',
  },
  {
    icon: 'Remote',
    label: 'Office',
    value: 'Office',
  },
  {
    icon: 'Couch',
    label: 'Living Area',
    value: 'Living Area',
  },
  {
    icon: 'Bathtub',
    label: 'Bathroom',
    value: 'Bathroom',
  },
  {
    icon: 'Bed',
    label: 'Bedroom',
    value: 'Bedroom',
  },
  {
    icon: 'WashingMachine',
    label: 'Utility',
    value: 'Utility',
  },
  {
    icon: 'Tool',
    label: 'Other',
    value: 'Other',
  },
];

const areaOutdoorOptions = {
  'Fix Something': areaOutdoorFixSomething,
  Improvement: areaOutdoorImprovement,
};

const areaIndoorOptions = {
  'Fix Something': areaIndoors,
  Improvement: areaIndoors,
};

const otherOptions = {
  'Fix Something': [],
  Improvement: [],
};

const areaOptions = {
  Outdoors: areaOutdoorOptions,
  Indoors: areaIndoorOptions,
  Other: otherOptions,
};

const reasonOptions: IOptions[] = [
  {
    label: 'Electrical',
    value: 'Electrical',
    icon: 'Flash',
  },
  {
    label: 'HVAC',
    value: 'HVAC',
    icon: 'Fan',
  },
  {
    label: 'Paint',
    value: 'Paint',
    icon: 'Roller',
  },
  {
    label: 'Plumbing',
    value: 'Plumbing',
    icon: 'Toilet',
  },
  {
    label: 'Other',
    value: 'Other',
    icon: 'Tool',
  },
];

export const fields = {
  area: areaOptions,
  category: categoryOptions,
  location: locationOptions,
  material: materialQualityOptions,
  start: projectStartOptions,
  stairs: stairOptions,
  reason: reasonOptions,
  format: bidTypeOptions,
};

type optionsTypes = keyof typeof fields;

export function getServiceRequestOptions(
  option: optionsTypes,
  areaSelected: 'Outdoors' | 'Indoors' | 'Other' = 'Indoors',
  categorySelected: 'Improvement' | 'Fix Something' = 'Improvement',
) {
  if (option === 'area') {
    return fields[option][areaSelected][categorySelected];
  }

  return fields[option];
}
