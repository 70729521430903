import { Layout, notification } from 'antd';
import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';

import { Footer } from '../components/Footer';
import { NavBar, NavSpacer } from '../components/NavBar';
import { OnRouterMount } from '../components/OnRouterMount';
import { NonLoggedInRoute, AuthenticatedRoute } from '../components/Routing';
import { UserContext } from '../context/User';
import DashboardPage from '../pages/DashboardPage';
import LandingPage from '../pages/LandingPage';
import LoginPage from '../pages/LoginPage';
import RegistrationPage from '../pages/RegistrationPage';
import PaymentInformationPage from '../pages/PaymentInformationPage';
import ProfilePage from '../pages/ProfilePage';
import ProjectDetailsPage from '../pages/ProjectDetailsPage';
import { ServiceRequestPage } from '../pages/ServiceRequestPage';
import UpdateAddressPage from '../pages/UpdateAddressPage';
import UpdateProfilePage from '../pages/UpdateProfilePage';
import VerifyPage from '../pages/VerifyPage';
import RegistrationSuccessPage from '../pages/RegistrationSuccessPage';
import { Content } from '../styles/Layout';
import ContactUsSuccessPage from '../pages/ContactUsSuccessPage';
import ContactUsPage from '../pages/ContactUsPage';
import TermsPage from '../pages/TermsPage';

export enum ROUTES {
  ADDRESS_UPDATE = '/profile/address',
  DASHBOARD = '/dashboard',
  LANDING = '/',
  LOGIN = '/login',
  REGISTRATION = '/registration',
  PAYMENT = '/make-payment',
  PROFILE = '/profile',
  PROFILE_UPDATE = '/profile/update',
  PROJECTS = '/projects',
  SERVICE_REQUEST = '/service-request',
  VERIFY = '/verify',
  REGISTRATION_SUCCESS = '/registration-success',
  CONTACT_US_SUCCESS = '/contact-us-success',
  CONTACT_US = '/contact-us',
  TERMS_PAGE = '/terms-page'
}

export function Router() {
  const { logout, user } = useContext(UserContext);

  React.useEffect(() => {
    if (user && user?.role === 'deactivated') {
      notification.warn({
        duration: 0,
        message: 'This account has been deactivated. Contact support for help.',
      });

      logout();
      return;
    }
  }, [logout, user]);

  return (
    <BrowserRouter>
      <OnRouterMount />
      <Layout>
        <Content>
          <Switch>
            <NonLoggedInRoute
              exact
              path={ROUTES.LANDING}
              user={user}
              component={LandingPage}
            />
            <NonLoggedInRoute
              path={ROUTES.VERIFY}
              user={user}
              component={VerifyPage}
            />
            <NonLoggedInRoute
              path={ROUTES.LOGIN}
              user={user}
              component={LoginPage}
            />
            <NonLoggedInRoute
              path={ROUTES.REGISTRATION}
              user={user}
              component={RegistrationPage}
            />
            <NonLoggedInRoute
              path={ROUTES.CONTACT_US}
              user={user}
              component={ContactUsPage}
            />
            <NonLoggedInRoute
              path={ROUTES.CONTACT_US_SUCCESS}
              user={user}
              component={ContactUsSuccessPage}
            />
            <NonLoggedInRoute
              path={ROUTES.TERMS_PAGE}
              user={user}
              component={TermsPage}
            />
            <AuthenticatedRoute
              path={ROUTES.SERVICE_REQUEST}
              user={user}
              component={ServiceRequestPage}
            />
            <AuthenticatedRoute
              exact
              path={ROUTES.PROFILE}
              user={user}
              component={ProfilePage}
            />
            <AuthenticatedRoute
              path={ROUTES.PAYMENT}
              user={user}
              component={PaymentInformationPage}
            />
            <AuthenticatedRoute
              path={ROUTES.PROFILE_UPDATE}
              user={user}
              component={UpdateProfilePage}
            />
            <AuthenticatedRoute
              exact
              path={`${ROUTES.ADDRESS_UPDATE}/:id`}
              user={user}
              component={UpdateAddressPage}
            />
            <AuthenticatedRoute
              exact
              path={`${ROUTES.PROJECTS}/:id`}
              user={user}
              component={ProjectDetailsPage}
            />
            <AuthenticatedRoute
              exact
              path={ROUTES.DASHBOARD}
              user={user}
              component={DashboardPage}
            />
            <AuthenticatedRoute
              exact
              path={ROUTES.REGISTRATION_SUCCESS}
              user={user}
              component={RegistrationSuccessPage}
            />
            <Route
              path="/p/:id"
              render={({ match }) => {
                return (
                  <Redirect to={`${ROUTES.PROJECTS}/${match.params.id}`} />
                );
              }}
            />
            <Route path="*">
              <Redirect to={user ? ROUTES.DASHBOARD : ROUTES.LANDING} />
            </Route>
          </Switch>
        </Content>
        <Footer hasNav={user && !user.isCreating} />
        <NavSpacer doubleSpacing={user && !user.isCreating} />
        {user && !user.isCreating && <NavBar />}
      </Layout>
    </BrowserRouter>
  );
}
