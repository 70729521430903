import { Typography, Space } from 'antd';
import React, { useCallback } from 'react';

import { Bid } from '../../generated/graphql';

import { BidCard } from './BidCard';
import { Wrapper } from './styled';
interface IProps {
  bids?: Partial<Bid>[];
  onAccept?: any;
  discount?: string | number;
  emptyText?: string;
}

export function BidsList({
  bids,
  emptyText = 'No Bids Yet',
  discount,
  onAccept,
}: IProps) {
  const onAcceptCallback = useCallback(
    async (clickedBid: any) => {
      if (onAccept) {
        await onAccept(clickedBid?.id, JSON.parse(JSON.stringify(clickedBid)));
      }
    },
    [onAccept],
  );

  return (
    <Wrapper>
      <Typography.Title level={3}>Current Bids</Typography.Title>
      <Space direction="vertical" style={{ width: '100%' }} size="large">
        {!bids?.length && <Typography.Text>{emptyText}</Typography.Text>}
        {bids?.map((bid: any) => {
          return (
            <BidCard
              key={bid?.id}
              data={bid}
              date={bid?.startDate}
              duration={bid?.duration}
              price={bid?.price}
              discount={discount}
              onAccept={onAccept && onAcceptCallback}
            />
          );
        })}
      </Space>
    </Wrapper>
  );
}
