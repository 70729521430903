import { Row } from 'antd';
import { Moment } from 'moment';
import React from 'react';

import { Bid } from '../../../generated/graphql';
import { Button } from '../../Button';
import { Card as CardStyle } from '../../Card';
import { InfoRowWithData } from '../../ContractorCard/InfoRow';
import { LabeledText } from '../../LabeledText';
import { Overview } from '../../ProjectDetails/Overview';
import { Spacer } from '../../Spacer';

interface IProps {
  confirming?: boolean;
  data?: Bid;
  date?: string | Moment | Date;
  discount?: string | number;
  duration?: number | string;
  loading?: boolean;
  onAccept?: any;
  onNo?: any;
  onYes?: any;
  price?: number;
}

export function BidCard({
  confirming,
  data,
  date,
  discount,
  duration,
  loading,
  onAccept,
  onNo,
  onYes,
  price,
}: IProps) {
  return (
    <CardStyle>
      <Row>
        <InfoRowWithData id={data?.contractorId} />
        <Spacer />
        <Overview
          completion={date}
          discount={discount}
          price={price}
          rightText="Earliest Start Date"
        />
        <Spacer />
        <LabeledText label="Project Duration">{`${duration} Days`}</LabeledText>
      </Row>
      <Spacer />
      {onAccept && (
        <Button
          type="primary"
          ghost
          onClick={() => {
            if (onAccept && typeof onAccept === 'function') {
              onAccept(data);
            }
          }}
        >
          Accept Bid
        </Button>
      )}
      {confirming && (
        <>
          <Row style={{ justifyContent: 'space-between' }}>
            <Button
              loading={loading}
              onClick={() => {
                if (onYes) {
                  onYes(data);
                }
              }}
              ghost
              type="primary"
              style={{ flex: '1 1 auto', marginRight: '16px', width: 'auto' }}
            >
              {confirming ? 'Confirm' : 'Yes'}
            </Button>
            <Button
              disabled={loading}
              onClick={() => {
                if (onNo) {
                  onNo();
                }
              }}
              danger
              ghost
              style={{ flex: '1 1 auto', width: 'auto' }}
            >
              {confirming ? 'Cancel' : 'No'}
            </Button>
          </Row>
        </>
      )}
    </CardStyle>
  );
}
