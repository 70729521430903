import { IUser } from '../../context/User/types';

export class AuthLocalStorage {
  private static STORAGE_KEY = 'user_info';

  private cachedUser?: IUser = undefined;

  constructor() {
    // set cached user
    this.getUserInfo();
  }

  public clear() {
    this.cachedUser = undefined;
    localStorage.removeItem(AuthLocalStorage.STORAGE_KEY);
  }

  public save(user: any) {
    this.cachedUser = undefined;
    localStorage.setItem(AuthLocalStorage.STORAGE_KEY, JSON.stringify(user));
  }

  public token(): string {
    return this.getUserInfo()?.token || '';
  }

  public getSavedUser(): IUser | undefined {
    const userJson = localStorage.getItem(AuthLocalStorage.STORAGE_KEY);
    const parsedUser = userJson ? JSON.parse(userJson) : undefined;

    return parsedUser;
  }

  public getUserInfo() {
    if (this.cachedUser) {
      return this.cachedUser;
    }

    const user = this.getSavedUser();

    this.cachedUser = user as IUser;

    return user;
  }
}
