import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FetchResult } from '@apollo/client';
import {
  Button as AntButton,
  Space,
  Typography,
  notification,
} from 'antd';
import Form from 'antd/lib/form/Form';
import React, { useContext, useState, useCallback, useEffect, useRef } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { Button, BackButton } from '../../components/Button';
import { Header } from '../../components/Header';
import { Spacer } from '../../components/Spacer';
import { CustomSpacer, CustomText, Margin } from './styled';
import { CartContext } from '../../context/Cart';
import { UserContext } from '../../context/User';
import { USER_ACTION_TYPES } from '../../context/User/types';
import {
  useAuthVerifyCodeMutation,
  AuthVerifyCodeMutation,
  CurrentUserDocument,
  useAuthGetCodeMutation,
  AuthGetCodeMutation,
  useUpdateUserNameMutation,
  UpdateUserNameMutation,
} from '../../generated/graphql';
import { apolloClient } from '../../helpers/apollo';
import { formatPhoneNumber } from '../../helpers/formatters';
import { getUserInfo } from '../../helpers/user';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';
import { Ellipsis } from '../../components/Ellipsis';
import { Arrow } from '../../components/Arrow';


type IProps = Partial<
  RouteComponentProps<
    {},
    {},
    {
      back?: string;
      verifiedCalled?: boolean;
      newAccount?: boolean;
      phoneNumber?: string;
      promoCode?: string;
      userFirstName?: string;
      userLastName?: string;
      //countryCode?: string;
    }
  >
>;

async function delay(duration = 500) {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

const RESEND_DELAY = 3000;

export default function VerifyPage({ history, location }: IProps) {
  const back = location?.state?.back || ROUTES.LOGIN;
  const phoneNumber = location?.state?.phoneNumber;
  const userFirstName = location?.state?.userFirstName;
  const userLastName = location?.state?.userLastName;
  // const countryCode = location?.state?.countryCode;

  const [mutate] = useAuthVerifyCodeMutation();
  const [updateFirstLastName] = useUpdateUserNameMutation();
  const [resend] = useAuthGetCodeMutation({
    variables: {
      phoneNumber: `+` + phoneNumber,
    },
  });

  const { dispatch, login } = useContext(UserContext);
  const { cart, clear: clearCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [resendDisabled, setResendDisabled] = useState(false);

  useEffect(() => {
    document?.querySelector('main + div')?.classList.add('hide');
    document?.querySelector('main + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
    };
  });

  const [value1, setInput1] = useState('');
  const [value2, setInput2] = useState('');
  const [value3, setInput3] = useState('');
  const [value4, setInput4] = useState('');
  const [value5, setInput5] = useState('');
  const [value6, setInput6] = useState('');
  const inputRef1 = useRef<any>();
  const inputRef2 = useRef<any>();
  const inputRef3 = useRef<any>();
  const inputRef4 = useRef<any>();
  const inputRef5 = useRef<any>();
  const inputRef6 = useRef<any>();
  
  useEffect(() => {
    if (!location?.state?.verifiedCalled) {
      history?.replace(ROUTES.LOGIN);
    }
  }, [history, location]);

  useEffect(() => {
    let time: number;

    if (resendDisabled) {
      time = setTimeout(() => setResendDisabled(false), RESEND_DELAY);
    }

    return () => clearTimeout(time);
  }, [resendDisabled]);

  const isValid = value1 && value2 && value3 && value4 && value5 && value6;

  const resendCallback = useCallback(async () => {
    let results: FetchResult<
      AuthGetCodeMutation,
      Record<string, any>,
      Record<string, any>
    >;

    if (resendDisabled) {
      console.log('Resend Disabled');
      return;
    } else {
      setResendDisabled(true);
    }

    try {
      results = await resend();
    } catch (error) {
      notification.error({ message: error.message });
      console.log(error);
      return;
    }

    const hasResent = results.data?.authGetCode;

    if (hasResent) {
      notification.info({
        message: 'Code resent',
      });
    }
  }, [resend, resendDisabled]);

  const onFinishCallback = useCallback(async () => {
    setLoading(true);

    if (!isValid) {
      setLoading(false);
      return;
    }
    dispatch({ type: USER_ACTION_TYPES.FETCHING });

    let results: FetchResult<
      AuthVerifyCodeMutation,
      Record<string, any>,
      Record<string, any>
    >;
    try {
      results = await mutate({
        variables: {
          code: `${value1}${value2}${value3}${value4}${value5}${value6}`,
          phoneNumber: `+` + phoneNumber,
          promoCode: null,
        },
      });
    } catch (error) {
      setLoading(false);
      notification.error({ message: error?.message });
      return;
    }

    const token = results?.data?.authVerifyCode?.token;
    const userId = Number(results?.data?.authVerifyCode?.userId);

    await delay(500);
    let userInfoResponse: any;

    try {
      userInfoResponse = await apolloClient.query({
        query: CurrentUserDocument,
        fetchPolicy: 'network-only',
        context: {
          headers: { authorization: 'Bearer ' + token },
        },
        variables: {
          userId,
        },
      });
    } catch (error) {
      console.log(error);
    }

    const userInfo = getUserInfo(userInfoResponse);

    const payload = {
      token: results?.data?.authVerifyCode?.token,
      isCreating: results?.data?.authVerifyCode?.wasCreated,
      userId: results?.data?.authVerifyCode?.userId,
      info: userInfo,
      promoBalance: userInfo?.promoBalance,
      address: userInfo?.Addresses?.[0],
    };

    login(payload);

    if (cart) {
      clearCart();
    }

    if (payload?.isCreating) { // create UserName

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let results: FetchResult<
        UpdateUserNameMutation,
        Record<string, any>,
        Record<string, any>
        >;

      try {
        results = await updateFirstLastName({
          variables: {
            id: Number(userId),
            name: `${userFirstName} ${userLastName}` || '',
          },
        });

        dispatch({
          type: USER_ACTION_TYPES.UPDATE,
          payload: {
            name: `${userFirstName} ${userLastName}`,
          },
        });

        history?.replace(ROUTES.REGISTRATION_SUCCESS);

      } catch (error) {
        console.log(error);
      }
    }

    setLoading(false);
  }, [
    cart,
    clearCart,
    dispatch,
    history,
    isValid,
    login,
    mutate,
    updateFirstLastName,
    phoneNumber,
  ]);

  return (
    <FullWidthPageWrapper>
      <BackButton to={back} replace>
        <Space>
          <Arrow />
        </Space>
      </BackButton>
      <Header>Verify</Header>
      <div  className={'login__description'}>
        Your number
      </div>
      <Typography>
        <Typography.Text strong>
          {formatPhoneNumber(phoneNumber || '')}
        </Typography.Text>
      </Typography>
      <Spacer />
      <Typography>
        <Typography.Text strong>Enter verification code</Typography.Text>
      </Typography>
      <Spacer size={"small"} />
      <Form onFinish={onFinishCallback}>
        <input className={"single-input"}
               ref={inputRef1}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value1}
               onChange={({ target: { value } }) => {
                 setInput1(value.replace(/\D/g, '').substring(0, 1));
                 inputRef2?.current?.focus();
               }}
               />
        <input className={"single-input"}
               ref={inputRef2}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value2}
               onChange={({ target: { value } }) => {
                 setInput2(value.replace(/\D/g, '').substring(0, 1));
                 inputRef3?.current?.focus();
               }}
               />
        <input className={"single-input"}
               ref={inputRef3}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value3}
               onChange={({ target: { value } }) => {
                 setInput3(value.replace(/\D/g, '').substring(0, 1));
                 inputRef4?.current?.focus();
               }}
               />
        <input className={"single-input"}
               ref={inputRef4}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value4}
               onChange={({ target: { value } }) => {
                 setInput4(value.replace(/\D/g, '').substring(0, 1));
                 inputRef5?.current?.focus();
               }}
               />
        <input className={"single-input"}
               ref={inputRef5}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value5}
               onChange={({ target: { value } }) => {
                 setInput5(value.replace(/\D/g, '').substring(0, 1));
                 inputRef6?.current?.focus();
               }}
               />
        <input className={"single-input"}
               ref={inputRef6}
               maxLength = {1}
               inputMode="numeric"
               pattern="[0-9]*"
               type="text"
               value={value6}
               onChange={({ target: { value } }) => {
                 setInput6(value.replace(/\D/g, '').substring(0, 1));
               }}
               />
          <Spacer />
          <CustomText>Didn't receive a code?
            <AntButton
              type="link"
              onClick={resendCallback}
              disabled={resendDisabled}
              className="main-link main-link--styled"
            >
              {resendDisabled ? 'Resending ...' : 'Resend Code'}
            </AntButton>
          </CustomText>
          <CustomText>Can't receive code by phone?
            <Link className="main-link main-link--styled main-link--padding" to={ROUTES.CONTACT_US}>  Contact us</Link>
          </CustomText>
          <CustomSpacer />
          <Ellipsis isFilled = { true } />
          <Margin />
          <Button
            disabled={!isValid}
            loading={loading}
            type="primary"
            htmlType="submit"
          >
            Submit verification code
          </Button>
      </Form>
    </FullWidthPageWrapper>
  );
}
