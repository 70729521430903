import { CameraOutlined } from '@ant-design/icons';
import { Typography, notification } from 'antd';
import React from 'react';

import { createUploadWidget } from '../../helpers/cloudinary';
import { Spacer } from '../Spacer';

import { BaseButton, Row } from './styled';

interface IProps {
  title?: string;
  onUploadSuccess?: any;
}

export function UploadPhotoInput({
  onUploadSuccess,
  title = 'Current',
}: IProps) {
  return (
    <div>
      <Spacer />
      <Row>
        <Typography.Title level={4}>{title}</Typography.Title>
        <div style={{ width: 8 }} />
        <BaseButton
          type="primary"
          ghost
          onClick={() => {
            const callback = (error: any, results: any) => {
              if (results?.event === 'success' && onUploadSuccess) {
                onUploadSuccess(results.info.public_id);
              }

              if (error) {
                notification.warn({
                  duration: 1000000,
                  message: 'Upload Error:',
                  description: error?.statusText || 'Unable to Upload File',
                });
              }
            };

            createUploadWidget(callback);
          }}
        >
          Add Photos
          <CameraOutlined />
        </BaseButton>
      </Row>
    </div>
  );
}

export * from './Header';
