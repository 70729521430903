import { notification } from 'antd';
import { useState, useEffect } from 'react';
import Geocode from 'react-geocode';
import { geolocated } from 'react-geolocated';

import { GOOGLE_API_KEY } from '../../config';

Geocode.setApiKey(GOOGLE_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('en');

// set response region. Its optional.
Geocode.setRegion('us');

// Enable or disable logs. Its optional.
// Geocode.enableDebug();

async function getAddressFromCoords({ latitude, longitude }: any) {
  let response: any;
  try {
    response = await Geocode.fromLatLng(latitude, longitude);
  } catch (error) {
    return {};
  }

  return response?.results[0];
}

let hasWarned = false;

function GeocodingComp({
  coords,
  onComplete,
  isGeolocationEnabled,
  isGeolocationAvailable,
}: any) {
  const [state, setState] = useState('');

  useEffect(() => {
    if (isGeolocationAvailable && !isGeolocationEnabled) {
      if (!hasWarned) {
        notification.warn({
          message:
            'Location is disabled. Please enable via browser settings and reload to use location services',
        });

        hasWarned = true;
      }

      onComplete('disabled');
    }
  }, [isGeolocationAvailable, isGeolocationEnabled, onComplete]);

  useEffect(() => {
    if (coords && !state) {
      getAddressFromCoords(coords).then((address) => {
        setState(address);

        if (onComplete) {
          onComplete(address);
        }
      });
    }
  }, [coords, onComplete, state]);

  return null;
}

export const Geocoding = geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 1000000000,
})(GeocodingComp);
