import { ApolloClient, InMemoryCache, from } from '@apollo/client';

import { authLink } from './authLink';
import { httpLink } from './httpLink';
import { onErrorLink } from './onErrorLink';

const link = from([onErrorLink, authLink, httpLink]);

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
