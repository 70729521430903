import styled from 'styled-components';

export const CustomSpacer = styled.div`
    height: 275px;
    width: 100%;
`;

export const CustomText = styled.p`
 font-size: 14px;
 color: #BBBBBB;
`;

export const Margin = styled.div`
  height: 28px;
  width: 100%;
`;