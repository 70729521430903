/* eslint-disable  */
// prettier-ignore-start
import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/client';
import * as ApolloReactHooks from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the `date-time` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A time string at UTC, such as 10:15:30Z, compliant with the `full-time` format outlined in section 5.6 of the RFC 3339profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Time: any;
  date: any;
  numeric: any;
  timestamptz: any;
};

/** columns and relationships of "Address" */
export type Address = {
  __typename?: 'Address';
  /** An object relationship */
  User: User;
  city?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  zip?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Address" */
export type Address_Aggregate = {
  __typename?: 'Address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

/** aggregate fields of "Address" */
export type Address_Aggregate_Fields = {
  __typename?: 'Address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "Address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Address" */
export type Address_Aggregate_Order_By = {
  avg?: Maybe<Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Address_Max_Order_By>;
  min?: Maybe<Address_Min_Order_By>;
  stddev?: Maybe<Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Address_Sum_Order_By>;
  var_pop?: Maybe<Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Address_Var_Samp_Order_By>;
  variance?: Maybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'Address_avg_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Address" */
export type Address_Avg_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Address_Bool_Exp>>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Address_Bool_Exp>>>;
  city?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  state?: Maybe<String_Comparison_Exp>;
  street?: Maybe<String_Comparison_Exp>;
  street2?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<Int_Comparison_Exp>;
  zip?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Address" */
export enum Address_Constraint {
  /** unique or primary key constraint */
  AddressPkey = 'Address_pkey'
}

/** input type for incrementing integer column in table "Address" */
export type Address_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Address" */
export type Address_Insert_Input = {
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'Address_max_fields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Address" */
export type Address_Max_Order_By = {
  city?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street2?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'Address_min_fields';
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Address" */
export type Address_Min_Order_By = {
  city?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street2?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** response of any mutation on the table "Address" */
export type Address_Mutation_Response = {
  __typename?: 'Address_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "Address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** on conflict condition type for table "Address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** ordering options when selecting data from "Address" */
export type Address_Order_By = {
  User?: Maybe<User_Order_By>;
  city?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  state?: Maybe<Order_By>;
  street?: Maybe<Order_By>;
  street2?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
  zip?: Maybe<Order_By>;
};

/** primary key columns input for table: "Address" */
export type Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Address" */
export enum Address_Select_Column {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  Street2 = 'street2',
  /** column name */
  UserId = 'userId',
  /** column name */
  Zip = 'zip'
}

/** input type for updating data in table "Address" */
export type Address_Set_Input = {
  city?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  state?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  street2?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  zip?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'Address_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Address" */
export type Address_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'Address_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Address" */
export type Address_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'Address_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Address" */
export type Address_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'Address_sum_fields';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Address" */
export type Address_Sum_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** update columns of table "Address" */
export enum Address_Update_Column {
  /** column name */
  City = 'city',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  Street = 'street',
  /** column name */
  Street2 = 'street2',
  /** column name */
  UserId = 'userId',
  /** column name */
  Zip = 'zip'
}

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'Address_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Address" */
export type Address_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'Address_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Address" */
export type Address_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'Address_variance_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Address" */
export type Address_Variance_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

export type AuthInfo = {
  __typename?: 'AuthInfo';
  role?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  wasCreated?: Maybe<Scalars['Boolean']>;
};

/** columns and relationships of "Bid" */
export type Bid = {
  __typename?: 'Bid';
  /** An object relationship */
  Contractor: Contractor;
  /** An array relationship */
  Payments: Array<Payment>;
  /** An aggregated array relationship */
  Payments_aggregate: Payment_Aggregate;
  /** An object relationship */
  Project: Project;
  contractorId: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['numeric']>;
  id: Scalars['Int'];
  price: Scalars['numeric'];
  projectId: Scalars['Int'];
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
};


/** columns and relationships of "Bid" */
export type BidPaymentsArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** columns and relationships of "Bid" */
export type BidPayments_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** aggregated selection of "Bid" */
export type Bid_Aggregate = {
  __typename?: 'Bid_aggregate';
  aggregate?: Maybe<Bid_Aggregate_Fields>;
  nodes: Array<Bid>;
};

/** aggregate fields of "Bid" */
export type Bid_Aggregate_Fields = {
  __typename?: 'Bid_aggregate_fields';
  avg?: Maybe<Bid_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Bid_Max_Fields>;
  min?: Maybe<Bid_Min_Fields>;
  stddev?: Maybe<Bid_Stddev_Fields>;
  stddev_pop?: Maybe<Bid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Bid_Stddev_Samp_Fields>;
  sum?: Maybe<Bid_Sum_Fields>;
  var_pop?: Maybe<Bid_Var_Pop_Fields>;
  var_samp?: Maybe<Bid_Var_Samp_Fields>;
  variance?: Maybe<Bid_Variance_Fields>;
};


/** aggregate fields of "Bid" */
export type Bid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Bid_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Bid" */
export type Bid_Aggregate_Order_By = {
  avg?: Maybe<Bid_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Bid_Max_Order_By>;
  min?: Maybe<Bid_Min_Order_By>;
  stddev?: Maybe<Bid_Stddev_Order_By>;
  stddev_pop?: Maybe<Bid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Bid_Stddev_Samp_Order_By>;
  sum?: Maybe<Bid_Sum_Order_By>;
  var_pop?: Maybe<Bid_Var_Pop_Order_By>;
  var_samp?: Maybe<Bid_Var_Samp_Order_By>;
  variance?: Maybe<Bid_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Bid" */
export type Bid_Arr_Rel_Insert_Input = {
  data: Array<Bid_Insert_Input>;
  on_conflict?: Maybe<Bid_On_Conflict>;
};

/** aggregate avg on columns */
export type Bid_Avg_Fields = {
  __typename?: 'Bid_avg_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Bid" */
export type Bid_Avg_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Bid". All fields are combined with a logical 'AND'. */
export type Bid_Bool_Exp = {
  Contractor?: Maybe<Contractor_Bool_Exp>;
  Payments?: Maybe<Payment_Bool_Exp>;
  Project?: Maybe<Project_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Bid_Bool_Exp>>>;
  _not?: Maybe<Bid_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Bid_Bool_Exp>>>;
  contractorId?: Maybe<Int_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Numeric_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  price?: Maybe<Numeric_Comparison_Exp>;
  projectId?: Maybe<Int_Comparison_Exp>;
  startDate?: Maybe<Date_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Bid" */
export enum Bid_Constraint {
  /** unique or primary key constraint */
  BidPkey = 'Bid_pkey'
}

/** input type for incrementing integer column in table "Bid" */
export type Bid_Inc_Input = {
  contractorId?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Bid" */
export type Bid_Insert_Input = {
  Contractor?: Maybe<Contractor_Obj_Rel_Insert_Input>;
  Payments?: Maybe<Payment_Arr_Rel_Insert_Input>;
  Project?: Maybe<Project_Obj_Rel_Insert_Input>;
  contractorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Bid_Max_Fields = {
  __typename?: 'Bid_max_fields';
  contractorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Bid" */
export type Bid_Max_Order_By = {
  contractorId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Bid_Min_Fields = {
  __typename?: 'Bid_min_fields';
  contractorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Bid" */
export type Bid_Min_Order_By = {
  contractorId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "Bid" */
export type Bid_Mutation_Response = {
  __typename?: 'Bid_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Bid>;
};

/** input type for inserting object relation for remote table "Bid" */
export type Bid_Obj_Rel_Insert_Input = {
  data: Bid_Insert_Input;
  on_conflict?: Maybe<Bid_On_Conflict>;
};

/** on conflict condition type for table "Bid" */
export type Bid_On_Conflict = {
  constraint: Bid_Constraint;
  update_columns: Array<Bid_Update_Column>;
  where?: Maybe<Bid_Bool_Exp>;
};

/** ordering options when selecting data from "Bid" */
export type Bid_Order_By = {
  Contractor?: Maybe<Contractor_Order_By>;
  Payments_aggregate?: Maybe<Payment_Aggregate_Order_By>;
  Project?: Maybe<Project_Order_By>;
  contractorId?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  startDate?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "Bid" */
export type Bid_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Bid" */
export enum Bid_Select_Column {
  /** column name */
  ContractorId = 'contractorId',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "Bid" */
export type Bid_Set_Input = {
  contractorId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['date']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Bid_Stddev_Fields = {
  __typename?: 'Bid_stddev_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Bid" */
export type Bid_Stddev_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Bid_Stddev_Pop_Fields = {
  __typename?: 'Bid_stddev_pop_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Bid" */
export type Bid_Stddev_Pop_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Bid_Stddev_Samp_Fields = {
  __typename?: 'Bid_stddev_samp_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Bid" */
export type Bid_Stddev_Samp_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Bid_Sum_Fields = {
  __typename?: 'Bid_sum_fields';
  contractorId?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  projectId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Bid" */
export type Bid_Sum_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** update columns of table "Bid" */
export enum Bid_Update_Column {
  /** column name */
  ContractorId = 'contractorId',
  /** column name */
  Description = 'description',
  /** column name */
  Duration = 'duration',
  /** column name */
  Id = 'id',
  /** column name */
  Price = 'price',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  StartDate = 'startDate',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type Bid_Var_Pop_Fields = {
  __typename?: 'Bid_var_pop_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Bid" */
export type Bid_Var_Pop_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Bid_Var_Samp_Fields = {
  __typename?: 'Bid_var_samp_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Bid" */
export type Bid_Var_Samp_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Bid_Variance_Fields = {
  __typename?: 'Bid_variance_fields';
  contractorId?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Bid" */
export type Bid_Variance_Order_By = {
  contractorId?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  price?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** columns and relationships of "Contractor" */
export type Contractor = {
  __typename?: 'Contractor';
  /** An array relationship */
  Bids: Array<Bid>;
  /** An aggregated array relationship */
  Bids_aggregate: Bid_Aggregate;
  id: Scalars['Int'];
  name: Scalars['String'];
  profilePic: Scalars['String'];
};


/** columns and relationships of "Contractor" */
export type ContractorBidsArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** columns and relationships of "Contractor" */
export type ContractorBids_AggregateArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};

/** aggregated selection of "Contractor" */
export type Contractor_Aggregate = {
  __typename?: 'Contractor_aggregate';
  aggregate?: Maybe<Contractor_Aggregate_Fields>;
  nodes: Array<Contractor>;
};

/** aggregate fields of "Contractor" */
export type Contractor_Aggregate_Fields = {
  __typename?: 'Contractor_aggregate_fields';
  avg?: Maybe<Contractor_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Contractor_Max_Fields>;
  min?: Maybe<Contractor_Min_Fields>;
  stddev?: Maybe<Contractor_Stddev_Fields>;
  stddev_pop?: Maybe<Contractor_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Contractor_Stddev_Samp_Fields>;
  sum?: Maybe<Contractor_Sum_Fields>;
  var_pop?: Maybe<Contractor_Var_Pop_Fields>;
  var_samp?: Maybe<Contractor_Var_Samp_Fields>;
  variance?: Maybe<Contractor_Variance_Fields>;
};


/** aggregate fields of "Contractor" */
export type Contractor_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Contractor_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Contractor" */
export type Contractor_Aggregate_Order_By = {
  avg?: Maybe<Contractor_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Contractor_Max_Order_By>;
  min?: Maybe<Contractor_Min_Order_By>;
  stddev?: Maybe<Contractor_Stddev_Order_By>;
  stddev_pop?: Maybe<Contractor_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Contractor_Stddev_Samp_Order_By>;
  sum?: Maybe<Contractor_Sum_Order_By>;
  var_pop?: Maybe<Contractor_Var_Pop_Order_By>;
  var_samp?: Maybe<Contractor_Var_Samp_Order_By>;
  variance?: Maybe<Contractor_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Contractor" */
export type Contractor_Arr_Rel_Insert_Input = {
  data: Array<Contractor_Insert_Input>;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};

/** aggregate avg on columns */
export type Contractor_Avg_Fields = {
  __typename?: 'Contractor_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Contractor" */
export type Contractor_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Contractor". All fields are combined with a logical 'AND'. */
export type Contractor_Bool_Exp = {
  Bids?: Maybe<Bid_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Contractor_Bool_Exp>>>;
  _not?: Maybe<Contractor_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Contractor_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  profilePic?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Contractor" */
export enum Contractor_Constraint {
  /** unique or primary key constraint */
  ContractorPkey = 'Contractor_pkey'
}

/** input type for incrementing integer column in table "Contractor" */
export type Contractor_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Contractor" */
export type Contractor_Insert_Input = {
  Bids?: Maybe<Bid_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Contractor_Max_Fields = {
  __typename?: 'Contractor_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Contractor" */
export type Contractor_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profilePic?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Contractor_Min_Fields = {
  __typename?: 'Contractor_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Contractor" */
export type Contractor_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profilePic?: Maybe<Order_By>;
};

/** response of any mutation on the table "Contractor" */
export type Contractor_Mutation_Response = {
  __typename?: 'Contractor_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Contractor>;
};

/** input type for inserting object relation for remote table "Contractor" */
export type Contractor_Obj_Rel_Insert_Input = {
  data: Contractor_Insert_Input;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};

/** on conflict condition type for table "Contractor" */
export type Contractor_On_Conflict = {
  constraint: Contractor_Constraint;
  update_columns: Array<Contractor_Update_Column>;
  where?: Maybe<Contractor_Bool_Exp>;
};

/** ordering options when selecting data from "Contractor" */
export type Contractor_Order_By = {
  Bids_aggregate?: Maybe<Bid_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  profilePic?: Maybe<Order_By>;
};

/** primary key columns input for table: "Contractor" */
export type Contractor_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Contractor" */
export enum Contractor_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePic = 'profilePic'
}

/** input type for updating data in table "Contractor" */
export type Contractor_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  profilePic?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Contractor_Stddev_Fields = {
  __typename?: 'Contractor_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Contractor" */
export type Contractor_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Contractor_Stddev_Pop_Fields = {
  __typename?: 'Contractor_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Contractor" */
export type Contractor_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Contractor_Stddev_Samp_Fields = {
  __typename?: 'Contractor_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Contractor" */
export type Contractor_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Contractor_Sum_Fields = {
  __typename?: 'Contractor_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Contractor" */
export type Contractor_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "Contractor" */
export enum Contractor_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  ProfilePic = 'profilePic'
}

/** aggregate var_pop on columns */
export type Contractor_Var_Pop_Fields = {
  __typename?: 'Contractor_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Contractor" */
export type Contractor_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Contractor_Var_Samp_Fields = {
  __typename?: 'Contractor_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Contractor" */
export type Contractor_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Contractor_Variance_Fields = {
  __typename?: 'Contractor_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Contractor" */
export type Contractor_Variance_Order_By = {
  id?: Maybe<Order_By>;
};



/** columns and relationships of "Image" */
export type Image = {
  __typename?: 'Image';
  /** An object relationship */
  Project: Project;
  cloudinaryId: Scalars['String'];
  id: Scalars['Int'];
  projectId: Scalars['Int'];
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Image" */
export type Image_Aggregate = {
  __typename?: 'Image_aggregate';
  aggregate?: Maybe<Image_Aggregate_Fields>;
  nodes: Array<Image>;
};

/** aggregate fields of "Image" */
export type Image_Aggregate_Fields = {
  __typename?: 'Image_aggregate_fields';
  avg?: Maybe<Image_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Image_Max_Fields>;
  min?: Maybe<Image_Min_Fields>;
  stddev?: Maybe<Image_Stddev_Fields>;
  stddev_pop?: Maybe<Image_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Image_Stddev_Samp_Fields>;
  sum?: Maybe<Image_Sum_Fields>;
  var_pop?: Maybe<Image_Var_Pop_Fields>;
  var_samp?: Maybe<Image_Var_Samp_Fields>;
  variance?: Maybe<Image_Variance_Fields>;
};


/** aggregate fields of "Image" */
export type Image_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Image_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Image" */
export type Image_Aggregate_Order_By = {
  avg?: Maybe<Image_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Image_Max_Order_By>;
  min?: Maybe<Image_Min_Order_By>;
  stddev?: Maybe<Image_Stddev_Order_By>;
  stddev_pop?: Maybe<Image_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Image_Stddev_Samp_Order_By>;
  sum?: Maybe<Image_Sum_Order_By>;
  var_pop?: Maybe<Image_Var_Pop_Order_By>;
  var_samp?: Maybe<Image_Var_Samp_Order_By>;
  variance?: Maybe<Image_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Image" */
export type Image_Arr_Rel_Insert_Input = {
  data: Array<Image_Insert_Input>;
  on_conflict?: Maybe<Image_On_Conflict>;
};

/** aggregate avg on columns */
export type Image_Avg_Fields = {
  __typename?: 'Image_avg_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Image" */
export type Image_Avg_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Image". All fields are combined with a logical 'AND'. */
export type Image_Bool_Exp = {
  Project?: Maybe<Project_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Image_Bool_Exp>>>;
  _not?: Maybe<Image_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Image_Bool_Exp>>>;
  cloudinaryId?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  projectId?: Maybe<Int_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "Image" */
export enum Image_Constraint {
  /** unique or primary key constraint */
  ImagePkey = 'Image_pkey'
}

/** input type for incrementing integer column in table "Image" */
export type Image_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Image" */
export type Image_Insert_Input = {
  Project?: Maybe<Project_Obj_Rel_Insert_Input>;
  cloudinaryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Image_Max_Fields = {
  __typename?: 'Image_max_fields';
  cloudinaryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "Image" */
export type Image_Max_Order_By = {
  cloudinaryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Image_Min_Fields = {
  __typename?: 'Image_min_fields';
  cloudinaryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "Image" */
export type Image_Min_Order_By = {
  cloudinaryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** response of any mutation on the table "Image" */
export type Image_Mutation_Response = {
  __typename?: 'Image_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Image>;
};

/** input type for inserting object relation for remote table "Image" */
export type Image_Obj_Rel_Insert_Input = {
  data: Image_Insert_Input;
  on_conflict?: Maybe<Image_On_Conflict>;
};

/** on conflict condition type for table "Image" */
export type Image_On_Conflict = {
  constraint: Image_Constraint;
  update_columns: Array<Image_Update_Column>;
  where?: Maybe<Image_Bool_Exp>;
};

/** ordering options when selecting data from "Image" */
export type Image_Order_By = {
  Project?: Maybe<Project_Order_By>;
  cloudinaryId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: "Image" */
export type Image_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Image" */
export enum Image_Select_Column {
  /** column name */
  CloudinaryId = 'cloudinaryId',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "Image" */
export type Image_Set_Input = {
  cloudinaryId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Image_Stddev_Fields = {
  __typename?: 'Image_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Image" */
export type Image_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Image_Stddev_Pop_Fields = {
  __typename?: 'Image_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Image" */
export type Image_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Image_Stddev_Samp_Fields = {
  __typename?: 'Image_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Image" */
export type Image_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Image_Sum_Fields = {
  __typename?: 'Image_sum_fields';
  id?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Image" */
export type Image_Sum_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** update columns of table "Image" */
export enum Image_Update_Column {
  /** column name */
  CloudinaryId = 'cloudinaryId',
  /** column name */
  Id = 'id',
  /** column name */
  ProjectId = 'projectId',
  /** column name */
  Type = 'type'
}

/** aggregate var_pop on columns */
export type Image_Var_Pop_Fields = {
  __typename?: 'Image_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Image" */
export type Image_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Image_Var_Samp_Fields = {
  __typename?: 'Image_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Image" */
export type Image_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Image_Variance_Fields = {
  __typename?: 'Image_variance_fields';
  id?: Maybe<Scalars['Float']>;
  projectId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Image" */
export type Image_Variance_Order_By = {
  id?: Maybe<Order_By>;
  projectId?: Maybe<Order_By>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};


export type Mutation = {
  __typename?: 'Mutation';
  /** Use to get a code */
  authGetCode: Scalars['Boolean'];
  /** Use to validate a code */
  authVerifyCode?: Maybe<AuthInfo>;
  /** create payment intent */
  createPaymentIntent: PaymentIntent;
  validatePromoCode: Scalars['Boolean'];
};


export type MutationAuthGetCodeArgs = {
  agreed?: Maybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};


export type MutationAuthVerifyCodeArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
};


export type MutationCreatePaymentIntentArgs = {
  bidId: Scalars['Int'];
  paymentMethodId?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  savePaymentMethod?: Maybe<Scalars['Boolean']>;
};


export type MutationValidatePromoCodeArgs = {
  code: Scalars['String'];
};

/** columns and relationships of "Payment" */
export type Payment = {
  __typename?: 'Payment';
  /** An object relationship */
  Bid?: Maybe<Bid>;
  bidId?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  promoBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  stripePaymentIntentId: Scalars['String'];
  userId?: Maybe<Scalars['Int']>;
};

export type PaymentAddress = {
  __typename?: 'PaymentAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  line1?: Maybe<Scalars['String']>;
  line2?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PaymentBillingDetails = {
  __typename?: 'PaymentBillingDetails';
  address?: Maybe<PaymentAddress>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
};

export type PaymentCard = {
  __typename?: 'PaymentCard';
  brand?: Maybe<Scalars['String']>;
  checks?: Maybe<PaymentCardChecks>;
  country?: Maybe<Scalars['String']>;
  exp_month?: Maybe<Scalars['Int']>;
  exp_year?: Maybe<Scalars['Int']>;
  fingerprint?: Maybe<Scalars['String']>;
  funding?: Maybe<Scalars['String']>;
  last4?: Maybe<Scalars['String']>;
  networks?: Maybe<PaymentCardNetworks>;
  three_d_secure_usage?: Maybe<PaymentCard3DSecure>;
};

export type PaymentCard3DSecure = {
  __typename?: 'PaymentCard3DSecure';
  supported?: Maybe<Scalars['Boolean']>;
};

export type PaymentCardChecks = {
  __typename?: 'PaymentCardChecks';
  address_line1_check?: Maybe<Scalars['String']>;
  address_postal_code_check?: Maybe<Scalars['String']>;
  cvc_check?: Maybe<Scalars['String']>;
};

export type PaymentCardNetworks = {
  __typename?: 'PaymentCardNetworks';
  available?: Maybe<Array<Maybe<Scalars['String']>>>;
  preferred?: Maybe<Scalars['String']>;
};

export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  secret?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  billing_details?: Maybe<PaymentBillingDetails>;
  card?: Maybe<PaymentCard>;
  created?: Maybe<Scalars['Int']>;
  customer?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  livemode?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

/** aggregated selection of "Payment" */
export type Payment_Aggregate = {
  __typename?: 'Payment_aggregate';
  aggregate?: Maybe<Payment_Aggregate_Fields>;
  nodes: Array<Payment>;
};

/** aggregate fields of "Payment" */
export type Payment_Aggregate_Fields = {
  __typename?: 'Payment_aggregate_fields';
  avg?: Maybe<Payment_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Payment_Max_Fields>;
  min?: Maybe<Payment_Min_Fields>;
  stddev?: Maybe<Payment_Stddev_Fields>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Fields>;
  sum?: Maybe<Payment_Sum_Fields>;
  var_pop?: Maybe<Payment_Var_Pop_Fields>;
  var_samp?: Maybe<Payment_Var_Samp_Fields>;
  variance?: Maybe<Payment_Variance_Fields>;
};


/** aggregate fields of "Payment" */
export type Payment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Payment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Payment" */
export type Payment_Aggregate_Order_By = {
  avg?: Maybe<Payment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Payment_Max_Order_By>;
  min?: Maybe<Payment_Min_Order_By>;
  stddev?: Maybe<Payment_Stddev_Order_By>;
  stddev_pop?: Maybe<Payment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Payment_Stddev_Samp_Order_By>;
  sum?: Maybe<Payment_Sum_Order_By>;
  var_pop?: Maybe<Payment_Var_Pop_Order_By>;
  var_samp?: Maybe<Payment_Var_Samp_Order_By>;
  variance?: Maybe<Payment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Payment" */
export type Payment_Arr_Rel_Insert_Input = {
  data: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** aggregate avg on columns */
export type Payment_Avg_Fields = {
  __typename?: 'Payment_avg_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Payment" */
export type Payment_Avg_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Payment". All fields are combined with a logical 'AND'. */
export type Payment_Bool_Exp = {
  Bid?: Maybe<Bid_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Payment_Bool_Exp>>>;
  _not?: Maybe<Payment_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Payment_Bool_Exp>>>;
  bidId?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  promoBalance?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  stripePaymentIntentId?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Payment" */
export enum Payment_Constraint {
  /** unique or primary key constraint */
  PaymentPkey = 'Payment_pkey'
}

/** input type for incrementing integer column in table "Payment" */
export type Payment_Inc_Input = {
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Payment" */
export type Payment_Insert_Input = {
  Bid?: Maybe<Bid_Obj_Rel_Insert_Input>;
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Payment_Max_Fields = {
  __typename?: 'Payment_max_fields';
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Payment" */
export type Payment_Max_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripePaymentIntentId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Payment_Min_Fields = {
  __typename?: 'Payment_min_fields';
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Payment" */
export type Payment_Min_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripePaymentIntentId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Payment" */
export type Payment_Mutation_Response = {
  __typename?: 'Payment_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Payment>;
};

/** input type for inserting object relation for remote table "Payment" */
export type Payment_Obj_Rel_Insert_Input = {
  data: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};

/** on conflict condition type for table "Payment" */
export type Payment_On_Conflict = {
  constraint: Payment_Constraint;
  update_columns: Array<Payment_Update_Column>;
  where?: Maybe<Payment_Bool_Exp>;
};

/** ordering options when selecting data from "Payment" */
export type Payment_Order_By = {
  Bid?: Maybe<Bid_Order_By>;
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  stripePaymentIntentId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "Payment" */
export type Payment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Payment" */
export enum Payment_Select_Column {
  /** column name */
  BidId = 'bidId',
  /** column name */
  Id = 'id',
  /** column name */
  PromoBalance = 'promoBalance',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Payment" */
export type Payment_Set_Input = {
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
  stripePaymentIntentId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Payment_Stddev_Fields = {
  __typename?: 'Payment_stddev_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Payment" */
export type Payment_Stddev_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Payment_Stddev_Pop_Fields = {
  __typename?: 'Payment_stddev_pop_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Payment" */
export type Payment_Stddev_Pop_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Payment_Stddev_Samp_Fields = {
  __typename?: 'Payment_stddev_samp_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Payment" */
export type Payment_Stddev_Samp_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Payment_Sum_Fields = {
  __typename?: 'Payment_sum_fields';
  bidId?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Payment" */
export type Payment_Sum_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** update columns of table "Payment" */
export enum Payment_Update_Column {
  /** column name */
  BidId = 'bidId',
  /** column name */
  Id = 'id',
  /** column name */
  PromoBalance = 'promoBalance',
  /** column name */
  Status = 'status',
  /** column name */
  StripePaymentIntentId = 'stripePaymentIntentId',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Payment_Var_Pop_Fields = {
  __typename?: 'Payment_var_pop_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Payment" */
export type Payment_Var_Pop_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Payment_Var_Samp_Fields = {
  __typename?: 'Payment_var_samp_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Payment" */
export type Payment_Var_Samp_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Payment_Variance_Fields = {
  __typename?: 'Payment_variance_fields';
  bidId?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Payment" */
export type Payment_Variance_Order_By = {
  bidId?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** columns and relationships of "Project" */
export type Project = {
  __typename?: 'Project';
  /** An array relationship */
  Bids: Array<Bid>;
  /** An aggregated array relationship */
  Bids_aggregate: Bid_Aggregate;
  /** An array relationship */
  Images: Array<Image>;
  /** An aggregated array relationship */
  Images_aggregate: Image_Aggregate;
  /** An object relationship */
  User: User;
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  category: Scalars['String'];
  completedAt?: Maybe<Scalars['date']>;
  createdAt: Scalars['date'];
  details?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location: Scalars['String'];
  materialQuality: Scalars['String'];
  name: Scalars['String'];
  reason?: Maybe<Scalars['String']>;
  stairs?: Maybe<Scalars['String']>;
  status: Scalars['String'];
  timing?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
};


/** columns and relationships of "Project" */
export type ProjectBidsArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectBids_AggregateArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectImagesArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


/** columns and relationships of "Project" */
export type ProjectImages_AggregateArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};

/** aggregated selection of "Project" */
export type Project_Aggregate = {
  __typename?: 'Project_aggregate';
  aggregate?: Maybe<Project_Aggregate_Fields>;
  nodes: Array<Project>;
};

/** aggregate fields of "Project" */
export type Project_Aggregate_Fields = {
  __typename?: 'Project_aggregate_fields';
  avg?: Maybe<Project_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Project_Max_Fields>;
  min?: Maybe<Project_Min_Fields>;
  stddev?: Maybe<Project_Stddev_Fields>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Fields>;
  sum?: Maybe<Project_Sum_Fields>;
  var_pop?: Maybe<Project_Var_Pop_Fields>;
  var_samp?: Maybe<Project_Var_Samp_Fields>;
  variance?: Maybe<Project_Variance_Fields>;
};


/** aggregate fields of "Project" */
export type Project_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Project_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "Project" */
export type Project_Aggregate_Order_By = {
  avg?: Maybe<Project_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Project_Max_Order_By>;
  min?: Maybe<Project_Min_Order_By>;
  stddev?: Maybe<Project_Stddev_Order_By>;
  stddev_pop?: Maybe<Project_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Project_Stddev_Samp_Order_By>;
  sum?: Maybe<Project_Sum_Order_By>;
  var_pop?: Maybe<Project_Var_Pop_Order_By>;
  var_samp?: Maybe<Project_Var_Samp_Order_By>;
  variance?: Maybe<Project_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "Project" */
export type Project_Arr_Rel_Insert_Input = {
  data: Array<Project_Insert_Input>;
  on_conflict?: Maybe<Project_On_Conflict>;
};

/** aggregate avg on columns */
export type Project_Avg_Fields = {
  __typename?: 'Project_avg_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "Project" */
export type Project_Avg_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "Project". All fields are combined with a logical 'AND'. */
export type Project_Bool_Exp = {
  Bids?: Maybe<Bid_Bool_Exp>;
  Images?: Maybe<Image_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<Project_Bool_Exp>>>;
  _not?: Maybe<Project_Bool_Exp>;
  _or?: Maybe<Array<Maybe<Project_Bool_Exp>>>;
  address?: Maybe<String_Comparison_Exp>;
  address2?: Maybe<String_Comparison_Exp>;
  area?: Maybe<String_Comparison_Exp>;
  category?: Maybe<String_Comparison_Exp>;
  completedAt?: Maybe<Date_Comparison_Exp>;
  createdAt?: Maybe<Date_Comparison_Exp>;
  details?: Maybe<String_Comparison_Exp>;
  format?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  materialQuality?: Maybe<String_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  reason?: Maybe<String_Comparison_Exp>;
  stairs?: Maybe<String_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  timing?: Maybe<String_Comparison_Exp>;
  userId?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "Project" */
export enum Project_Constraint {
  /** unique or primary key constraint */
  ProjectPkey = 'Project_pkey'
}

/** input type for incrementing integer column in table "Project" */
export type Project_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "Project" */
export type Project_Insert_Input = {
  Bids?: Maybe<Bid_Arr_Rel_Insert_Input>;
  Images?: Maybe<Image_Arr_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['date']>;
  details?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  materialQuality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  stairs?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timing?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Project_Max_Fields = {
  __typename?: 'Project_max_fields';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['date']>;
  details?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  materialQuality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  stairs?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timing?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "Project" */
export type Project_Max_Order_By = {
  address?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  area?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  format?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  materialQuality?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  stairs?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  timing?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Project_Min_Fields = {
  __typename?: 'Project_min_fields';
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['date']>;
  details?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  materialQuality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  stairs?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timing?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "Project" */
export type Project_Min_Order_By = {
  address?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  area?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  format?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  materialQuality?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  stairs?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  timing?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "Project" */
export type Project_Mutation_Response = {
  __typename?: 'Project_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Project>;
};

/** input type for inserting object relation for remote table "Project" */
export type Project_Obj_Rel_Insert_Input = {
  data: Project_Insert_Input;
  on_conflict?: Maybe<Project_On_Conflict>;
};

/** on conflict condition type for table "Project" */
export type Project_On_Conflict = {
  constraint: Project_Constraint;
  update_columns: Array<Project_Update_Column>;
  where?: Maybe<Project_Bool_Exp>;
};

/** ordering options when selecting data from "Project" */
export type Project_Order_By = {
  Bids_aggregate?: Maybe<Bid_Aggregate_Order_By>;
  Images_aggregate?: Maybe<Image_Aggregate_Order_By>;
  User?: Maybe<User_Order_By>;
  address?: Maybe<Order_By>;
  address2?: Maybe<Order_By>;
  area?: Maybe<Order_By>;
  category?: Maybe<Order_By>;
  completedAt?: Maybe<Order_By>;
  createdAt?: Maybe<Order_By>;
  details?: Maybe<Order_By>;
  format?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  materialQuality?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  reason?: Maybe<Order_By>;
  stairs?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  timing?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "Project" */
export type Project_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "Project" */
export enum Project_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address2 = 'address2',
  /** column name */
  Area = 'area',
  /** column name */
  Category = 'category',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  MaterialQuality = 'materialQuality',
  /** column name */
  Name = 'name',
  /** column name */
  Reason = 'reason',
  /** column name */
  Stairs = 'stairs',
  /** column name */
  Status = 'status',
  /** column name */
  Timing = 'timing',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "Project" */
export type Project_Set_Input = {
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  completedAt?: Maybe<Scalars['date']>;
  createdAt?: Maybe<Scalars['date']>;
  details?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  location?: Maybe<Scalars['String']>;
  materialQuality?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  stairs?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timing?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Project_Stddev_Fields = {
  __typename?: 'Project_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "Project" */
export type Project_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Project_Stddev_Pop_Fields = {
  __typename?: 'Project_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "Project" */
export type Project_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Project_Stddev_Samp_Fields = {
  __typename?: 'Project_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "Project" */
export type Project_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Project_Sum_Fields = {
  __typename?: 'Project_sum_fields';
  id?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "Project" */
export type Project_Sum_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** update columns of table "Project" */
export enum Project_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Address2 = 'address2',
  /** column name */
  Area = 'area',
  /** column name */
  Category = 'category',
  /** column name */
  CompletedAt = 'completedAt',
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Details = 'details',
  /** column name */
  Format = 'format',
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  MaterialQuality = 'materialQuality',
  /** column name */
  Name = 'name',
  /** column name */
  Reason = 'reason',
  /** column name */
  Stairs = 'stairs',
  /** column name */
  Status = 'status',
  /** column name */
  Timing = 'timing',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type Project_Var_Pop_Fields = {
  __typename?: 'Project_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "Project" */
export type Project_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Project_Var_Samp_Fields = {
  __typename?: 'Project_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "Project" */
export type Project_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Project_Variance_Fields = {
  __typename?: 'Project_variance_fields';
  id?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "Project" */
export type Project_Variance_Order_By = {
  id?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** columns and relationships of "PromoCode" */
export type PromoCode = {
  __typename?: 'PromoCode';
  /** An array relationship */
  Users: Array<UserCode>;
  /** An aggregated array relationship */
  Users_aggregate: UserCode_Aggregate;
  code: Scalars['String'];
  discount: Scalars['numeric'];
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  status?: Maybe<Scalars['String']>;
};


/** columns and relationships of "PromoCode" */
export type PromoCodeUsersArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** columns and relationships of "PromoCode" */
export type PromoCodeUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};

/** aggregated selection of "PromoCode" */
export type PromoCode_Aggregate = {
  __typename?: 'PromoCode_aggregate';
  aggregate?: Maybe<PromoCode_Aggregate_Fields>;
  nodes: Array<PromoCode>;
};

/** aggregate fields of "PromoCode" */
export type PromoCode_Aggregate_Fields = {
  __typename?: 'PromoCode_aggregate_fields';
  avg?: Maybe<PromoCode_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<PromoCode_Max_Fields>;
  min?: Maybe<PromoCode_Min_Fields>;
  stddev?: Maybe<PromoCode_Stddev_Fields>;
  stddev_pop?: Maybe<PromoCode_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<PromoCode_Stddev_Samp_Fields>;
  sum?: Maybe<PromoCode_Sum_Fields>;
  var_pop?: Maybe<PromoCode_Var_Pop_Fields>;
  var_samp?: Maybe<PromoCode_Var_Samp_Fields>;
  variance?: Maybe<PromoCode_Variance_Fields>;
};


/** aggregate fields of "PromoCode" */
export type PromoCode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<PromoCode_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "PromoCode" */
export type PromoCode_Aggregate_Order_By = {
  avg?: Maybe<PromoCode_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<PromoCode_Max_Order_By>;
  min?: Maybe<PromoCode_Min_Order_By>;
  stddev?: Maybe<PromoCode_Stddev_Order_By>;
  stddev_pop?: Maybe<PromoCode_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<PromoCode_Stddev_Samp_Order_By>;
  sum?: Maybe<PromoCode_Sum_Order_By>;
  var_pop?: Maybe<PromoCode_Var_Pop_Order_By>;
  var_samp?: Maybe<PromoCode_Var_Samp_Order_By>;
  variance?: Maybe<PromoCode_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "PromoCode" */
export type PromoCode_Arr_Rel_Insert_Input = {
  data: Array<PromoCode_Insert_Input>;
  on_conflict?: Maybe<PromoCode_On_Conflict>;
};

/** aggregate avg on columns */
export type PromoCode_Avg_Fields = {
  __typename?: 'PromoCode_avg_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "PromoCode" */
export type PromoCode_Avg_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "PromoCode". All fields are combined with a logical 'AND'. */
export type PromoCode_Bool_Exp = {
  Users?: Maybe<UserCode_Bool_Exp>;
  _and?: Maybe<Array<Maybe<PromoCode_Bool_Exp>>>;
  _not?: Maybe<PromoCode_Bool_Exp>;
  _or?: Maybe<Array<Maybe<PromoCode_Bool_Exp>>>;
  code?: Maybe<String_Comparison_Exp>;
  discount?: Maybe<Numeric_Comparison_Exp>;
  expiresAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "PromoCode" */
export enum PromoCode_Constraint {
  /** unique or primary key constraint */
  PromoCodeCodeKey = 'PromoCode_code_key',
  /** unique or primary key constraint */
  PromoCodePkey = 'PromoCode_pkey'
}

/** input type for incrementing integer column in table "PromoCode" */
export type PromoCode_Inc_Input = {
  discount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "PromoCode" */
export type PromoCode_Insert_Input = {
  Users?: Maybe<UserCode_Arr_Rel_Insert_Input>;
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['numeric']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type PromoCode_Max_Fields = {
  __typename?: 'PromoCode_max_fields';
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['numeric']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "PromoCode" */
export type PromoCode_Max_Order_By = {
  code?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type PromoCode_Min_Fields = {
  __typename?: 'PromoCode_min_fields';
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['numeric']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "PromoCode" */
export type PromoCode_Min_Order_By = {
  code?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** response of any mutation on the table "PromoCode" */
export type PromoCode_Mutation_Response = {
  __typename?: 'PromoCode_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<PromoCode>;
};

/** input type for inserting object relation for remote table "PromoCode" */
export type PromoCode_Obj_Rel_Insert_Input = {
  data: PromoCode_Insert_Input;
  on_conflict?: Maybe<PromoCode_On_Conflict>;
};

/** on conflict condition type for table "PromoCode" */
export type PromoCode_On_Conflict = {
  constraint: PromoCode_Constraint;
  update_columns: Array<PromoCode_Update_Column>;
  where?: Maybe<PromoCode_Bool_Exp>;
};

/** ordering options when selecting data from "PromoCode" */
export type PromoCode_Order_By = {
  Users_aggregate?: Maybe<UserCode_Aggregate_Order_By>;
  code?: Maybe<Order_By>;
  discount?: Maybe<Order_By>;
  expiresAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: "PromoCode" */
export type PromoCode_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "PromoCode" */
export enum PromoCode_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Discount = 'discount',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "PromoCode" */
export type PromoCode_Set_Input = {
  code?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['numeric']>;
  expiresAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type PromoCode_Stddev_Fields = {
  __typename?: 'PromoCode_stddev_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "PromoCode" */
export type PromoCode_Stddev_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type PromoCode_Stddev_Pop_Fields = {
  __typename?: 'PromoCode_stddev_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "PromoCode" */
export type PromoCode_Stddev_Pop_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type PromoCode_Stddev_Samp_Fields = {
  __typename?: 'PromoCode_stddev_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "PromoCode" */
export type PromoCode_Stddev_Samp_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type PromoCode_Sum_Fields = {
  __typename?: 'PromoCode_sum_fields';
  discount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "PromoCode" */
export type PromoCode_Sum_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "PromoCode" */
export enum PromoCode_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Discount = 'discount',
  /** column name */
  ExpiresAt = 'expiresAt',
  /** column name */
  Id = 'id',
  /** column name */
  Status = 'status'
}

/** aggregate var_pop on columns */
export type PromoCode_Var_Pop_Fields = {
  __typename?: 'PromoCode_var_pop_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "PromoCode" */
export type PromoCode_Var_Pop_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type PromoCode_Var_Samp_Fields = {
  __typename?: 'PromoCode_var_samp_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "PromoCode" */
export type PromoCode_Var_Samp_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type PromoCode_Variance_Fields = {
  __typename?: 'PromoCode_variance_fields';
  discount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "PromoCode" */
export type PromoCode_Variance_Order_By = {
  discount?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

export type Query = {
  __typename?: 'Query';
  /** get user payment methods */
  getPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  healthz: Scalars['Boolean'];
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  _nlike?: Maybe<Scalars['String']>;
  _nsimilar?: Maybe<Scalars['String']>;
  _similar?: Maybe<Scalars['String']>;
};


/** columns and relationships of "User" */
export type User = {
  __typename?: 'User';
  /** An array relationship */
  Addresses: Array<Address>;
  /** An aggregated array relationship */
  Addresses_aggregate: Address_Aggregate;
  /** An array relationship */
  Codes: Array<UserCode>;
  /** An aggregated array relationship */
  Codes_aggregate: UserCode_Aggregate;
  /** An array relationship */
  Projects: Array<Project>;
  /** An aggregated array relationship */
  Projects_aggregate: Project_Aggregate;
  id: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  phoneNumber: Scalars['String'];
  promoBalance?: Maybe<Scalars['Int']>;
  role: Scalars['String'];
  stripeCustomerId?: Maybe<Scalars['String']>;
};


/** columns and relationships of "User" */
export type UserAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserCodesArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserCodes_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserProjectsArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


/** columns and relationships of "User" */
export type UserProjects_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};

/** columns and relationships of "UserCode" */
export type UserCode = {
  __typename?: 'UserCode';
  /** An object relationship */
  PromoCode: PromoCode;
  /** An object relationship */
  User: User;
  createdAt: Scalars['timestamptz'];
  id: Scalars['Int'];
  promoCodeId: Scalars['Int'];
  userId: Scalars['Int'];
};

/** aggregated selection of "UserCode" */
export type UserCode_Aggregate = {
  __typename?: 'UserCode_aggregate';
  aggregate?: Maybe<UserCode_Aggregate_Fields>;
  nodes: Array<UserCode>;
};

/** aggregate fields of "UserCode" */
export type UserCode_Aggregate_Fields = {
  __typename?: 'UserCode_aggregate_fields';
  avg?: Maybe<UserCode_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<UserCode_Max_Fields>;
  min?: Maybe<UserCode_Min_Fields>;
  stddev?: Maybe<UserCode_Stddev_Fields>;
  stddev_pop?: Maybe<UserCode_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<UserCode_Stddev_Samp_Fields>;
  sum?: Maybe<UserCode_Sum_Fields>;
  var_pop?: Maybe<UserCode_Var_Pop_Fields>;
  var_samp?: Maybe<UserCode_Var_Samp_Fields>;
  variance?: Maybe<UserCode_Variance_Fields>;
};


/** aggregate fields of "UserCode" */
export type UserCode_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<UserCode_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "UserCode" */
export type UserCode_Aggregate_Order_By = {
  avg?: Maybe<UserCode_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<UserCode_Max_Order_By>;
  min?: Maybe<UserCode_Min_Order_By>;
  stddev?: Maybe<UserCode_Stddev_Order_By>;
  stddev_pop?: Maybe<UserCode_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<UserCode_Stddev_Samp_Order_By>;
  sum?: Maybe<UserCode_Sum_Order_By>;
  var_pop?: Maybe<UserCode_Var_Pop_Order_By>;
  var_samp?: Maybe<UserCode_Var_Samp_Order_By>;
  variance?: Maybe<UserCode_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "UserCode" */
export type UserCode_Arr_Rel_Insert_Input = {
  data: Array<UserCode_Insert_Input>;
  on_conflict?: Maybe<UserCode_On_Conflict>;
};

/** aggregate avg on columns */
export type UserCode_Avg_Fields = {
  __typename?: 'UserCode_avg_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "UserCode" */
export type UserCode_Avg_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "UserCode". All fields are combined with a logical 'AND'. */
export type UserCode_Bool_Exp = {
  PromoCode?: Maybe<PromoCode_Bool_Exp>;
  User?: Maybe<User_Bool_Exp>;
  _and?: Maybe<Array<Maybe<UserCode_Bool_Exp>>>;
  _not?: Maybe<UserCode_Bool_Exp>;
  _or?: Maybe<Array<Maybe<UserCode_Bool_Exp>>>;
  createdAt?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  promoCodeId?: Maybe<Int_Comparison_Exp>;
  userId?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "UserCode" */
export enum UserCode_Constraint {
  /** unique or primary key constraint */
  UserCodePkey = 'UserCode_pkey',
  /** unique or primary key constraint */
  UserCodeUserIdPromoCodeIdKey = 'UserCode_userId_promoCodeId_key'
}

/** input type for incrementing integer column in table "UserCode" */
export type UserCode_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "UserCode" */
export type UserCode_Insert_Input = {
  PromoCode?: Maybe<PromoCode_Obj_Rel_Insert_Input>;
  User?: Maybe<User_Obj_Rel_Insert_Input>;
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type UserCode_Max_Fields = {
  __typename?: 'UserCode_max_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "UserCode" */
export type UserCode_Max_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type UserCode_Min_Fields = {
  __typename?: 'UserCode_min_fields';
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "UserCode" */
export type UserCode_Min_Order_By = {
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** response of any mutation on the table "UserCode" */
export type UserCode_Mutation_Response = {
  __typename?: 'UserCode_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<UserCode>;
};

/** input type for inserting object relation for remote table "UserCode" */
export type UserCode_Obj_Rel_Insert_Input = {
  data: UserCode_Insert_Input;
  on_conflict?: Maybe<UserCode_On_Conflict>;
};

/** on conflict condition type for table "UserCode" */
export type UserCode_On_Conflict = {
  constraint: UserCode_Constraint;
  update_columns: Array<UserCode_Update_Column>;
  where?: Maybe<UserCode_Bool_Exp>;
};

/** ordering options when selecting data from "UserCode" */
export type UserCode_Order_By = {
  PromoCode?: Maybe<PromoCode_Order_By>;
  User?: Maybe<User_Order_By>;
  createdAt?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** primary key columns input for table: "UserCode" */
export type UserCode_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "UserCode" */
export enum UserCode_Select_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromoCodeId = 'promoCodeId',
  /** column name */
  UserId = 'userId'
}

/** input type for updating data in table "UserCode" */
export type UserCode_Set_Input = {
  createdAt?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type UserCode_Stddev_Fields = {
  __typename?: 'UserCode_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "UserCode" */
export type UserCode_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type UserCode_Stddev_Pop_Fields = {
  __typename?: 'UserCode_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "UserCode" */
export type UserCode_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type UserCode_Stddev_Samp_Fields = {
  __typename?: 'UserCode_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "UserCode" */
export type UserCode_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type UserCode_Sum_Fields = {
  __typename?: 'UserCode_sum_fields';
  id?: Maybe<Scalars['Int']>;
  promoCodeId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "UserCode" */
export type UserCode_Sum_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** update columns of table "UserCode" */
export enum UserCode_Update_Column {
  /** column name */
  CreatedAt = 'createdAt',
  /** column name */
  Id = 'id',
  /** column name */
  PromoCodeId = 'promoCodeId',
  /** column name */
  UserId = 'userId'
}

/** aggregate var_pop on columns */
export type UserCode_Var_Pop_Fields = {
  __typename?: 'UserCode_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "UserCode" */
export type UserCode_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type UserCode_Var_Samp_Fields = {
  __typename?: 'UserCode_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "UserCode" */
export type UserCode_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type UserCode_Variance_Fields = {
  __typename?: 'UserCode_variance_fields';
  id?: Maybe<Scalars['Float']>;
  promoCodeId?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "UserCode" */
export type UserCode_Variance_Order_By = {
  id?: Maybe<Order_By>;
  promoCodeId?: Maybe<Order_By>;
  userId?: Maybe<Order_By>;
};

/** aggregated selection of "User" */
export type User_Aggregate = {
  __typename?: 'User_aggregate';
  aggregate?: Maybe<User_Aggregate_Fields>;
  nodes: Array<User>;
};

/** aggregate fields of "User" */
export type User_Aggregate_Fields = {
  __typename?: 'User_aggregate_fields';
  avg?: Maybe<User_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Max_Fields>;
  min?: Maybe<User_Min_Fields>;
  stddev?: Maybe<User_Stddev_Fields>;
  stddev_pop?: Maybe<User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Stddev_Samp_Fields>;
  sum?: Maybe<User_Sum_Fields>;
  var_pop?: Maybe<User_Var_Pop_Fields>;
  var_samp?: Maybe<User_Var_Samp_Fields>;
  variance?: Maybe<User_Variance_Fields>;
};


/** aggregate fields of "User" */
export type User_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<User_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "User" */
export type User_Aggregate_Order_By = {
  avg?: Maybe<User_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<User_Max_Order_By>;
  min?: Maybe<User_Min_Order_By>;
  stddev?: Maybe<User_Stddev_Order_By>;
  stddev_pop?: Maybe<User_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<User_Stddev_Samp_Order_By>;
  sum?: Maybe<User_Sum_Order_By>;
  var_pop?: Maybe<User_Var_Pop_Order_By>;
  var_samp?: Maybe<User_Var_Samp_Order_By>;
  variance?: Maybe<User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "User" */
export type User_Arr_Rel_Insert_Input = {
  data: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Avg_Fields = {
  __typename?: 'User_avg_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "User" */
export type User_Avg_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "User". All fields are combined with a logical 'AND'. */
export type User_Bool_Exp = {
  Addresses?: Maybe<Address_Bool_Exp>;
  Codes?: Maybe<UserCode_Bool_Exp>;
  Projects?: Maybe<Project_Bool_Exp>;
  _and?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  _not?: Maybe<User_Bool_Exp>;
  _or?: Maybe<Array<Maybe<User_Bool_Exp>>>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  phoneNumber?: Maybe<String_Comparison_Exp>;
  promoBalance?: Maybe<Int_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  stripeCustomerId?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "User" */
export enum User_Constraint {
  /** unique or primary key constraint */
  UserPhoneNumberKey = 'User_phoneNumber_key',
  /** unique or primary key constraint */
  UserPkey = 'User_pkey'
}

/** input type for incrementing integer column in table "User" */
export type User_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "User" */
export type User_Insert_Input = {
  Addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  Codes?: Maybe<UserCode_Arr_Rel_Insert_Input>;
  Projects?: Maybe<Project_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type User_Max_Fields = {
  __typename?: 'User_max_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "User" */
export type User_Max_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type User_Min_Fields = {
  __typename?: 'User_min_fields';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "User" */
export type User_Min_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
};

/** response of any mutation on the table "User" */
export type User_Mutation_Response = {
  __typename?: 'User_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User>;
};

/** input type for inserting object relation for remote table "User" */
export type User_Obj_Rel_Insert_Input = {
  data: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};

/** on conflict condition type for table "User" */
export type User_On_Conflict = {
  constraint: User_Constraint;
  update_columns: Array<User_Update_Column>;
  where?: Maybe<User_Bool_Exp>;
};

/** ordering options when selecting data from "User" */
export type User_Order_By = {
  Addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  Codes_aggregate?: Maybe<UserCode_Aggregate_Order_By>;
  Projects_aggregate?: Maybe<Project_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  phoneNumber?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  stripeCustomerId?: Maybe<Order_By>;
};

/** primary key columns input for table: "User" */
export type User_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "User" */
export enum User_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PromoBalance = 'promoBalance',
  /** column name */
  Role = 'role',
  /** column name */
  StripeCustomerId = 'stripeCustomerId'
}

/** input type for updating data in table "User" */
export type User_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  role?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type User_Stddev_Fields = {
  __typename?: 'User_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "User" */
export type User_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Stddev_Pop_Fields = {
  __typename?: 'User_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "User" */
export type User_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Stddev_Samp_Fields = {
  __typename?: 'User_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "User" */
export type User_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type User_Sum_Fields = {
  __typename?: 'User_sum_fields';
  id?: Maybe<Scalars['Int']>;
  promoBalance?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "User" */
export type User_Sum_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** update columns of table "User" */
export enum User_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phoneNumber',
  /** column name */
  PromoBalance = 'promoBalance',
  /** column name */
  Role = 'role',
  /** column name */
  StripeCustomerId = 'stripeCustomerId'
}

/** aggregate var_pop on columns */
export type User_Var_Pop_Fields = {
  __typename?: 'User_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "User" */
export type User_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Var_Samp_Fields = {
  __typename?: 'User_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "User" */
export type User_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Variance_Fields = {
  __typename?: 'User_variance_fields';
  id?: Maybe<Scalars['Float']>;
  promoBalance?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "User" */
export type User_Variance_Order_By = {
  id?: Maybe<Order_By>;
  promoBalance?: Maybe<Order_By>;
};


/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** Use to get a code */
  authGetCode: Scalars['Boolean'];
  /** Use to validate a code */
  authVerifyCode?: Maybe<AuthInfo>;
  /** create payment intent */
  createPaymentIntent: PaymentIntent;
  /** delete data from the table: "Address" */
  delete_Address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "Address" */
  delete_Address_by_pk?: Maybe<Address>;
  /** delete data from the table: "Bid" */
  delete_Bid?: Maybe<Bid_Mutation_Response>;
  /** delete single row from the table: "Bid" */
  delete_Bid_by_pk?: Maybe<Bid>;
  /** delete data from the table: "Contractor" */
  delete_Contractor?: Maybe<Contractor_Mutation_Response>;
  /** delete single row from the table: "Contractor" */
  delete_Contractor_by_pk?: Maybe<Contractor>;
  /** delete data from the table: "Image" */
  delete_Image?: Maybe<Image_Mutation_Response>;
  /** delete single row from the table: "Image" */
  delete_Image_by_pk?: Maybe<Image>;
  /** delete data from the table: "Payment" */
  delete_Payment?: Maybe<Payment_Mutation_Response>;
  /** delete single row from the table: "Payment" */
  delete_Payment_by_pk?: Maybe<Payment>;
  /** delete data from the table: "Project" */
  delete_Project?: Maybe<Project_Mutation_Response>;
  /** delete single row from the table: "Project" */
  delete_Project_by_pk?: Maybe<Project>;
  /** delete data from the table: "PromoCode" */
  delete_PromoCode?: Maybe<PromoCode_Mutation_Response>;
  /** delete single row from the table: "PromoCode" */
  delete_PromoCode_by_pk?: Maybe<PromoCode>;
  /** delete data from the table: "User" */
  delete_User?: Maybe<User_Mutation_Response>;
  /** delete data from the table: "UserCode" */
  delete_UserCode?: Maybe<UserCode_Mutation_Response>;
  /** delete single row from the table: "UserCode" */
  delete_UserCode_by_pk?: Maybe<UserCode>;
  /** delete single row from the table: "User" */
  delete_User_by_pk?: Maybe<User>;
  /** insert data into the table: "Address" */
  insert_Address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "Address" */
  insert_Address_one?: Maybe<Address>;
  /** insert data into the table: "Bid" */
  insert_Bid?: Maybe<Bid_Mutation_Response>;
  /** insert a single row into the table: "Bid" */
  insert_Bid_one?: Maybe<Bid>;
  /** insert data into the table: "Contractor" */
  insert_Contractor?: Maybe<Contractor_Mutation_Response>;
  /** insert a single row into the table: "Contractor" */
  insert_Contractor_one?: Maybe<Contractor>;
  /** insert data into the table: "Image" */
  insert_Image?: Maybe<Image_Mutation_Response>;
  /** insert a single row into the table: "Image" */
  insert_Image_one?: Maybe<Image>;
  /** insert data into the table: "Payment" */
  insert_Payment?: Maybe<Payment_Mutation_Response>;
  /** insert a single row into the table: "Payment" */
  insert_Payment_one?: Maybe<Payment>;
  /** insert data into the table: "Project" */
  insert_Project?: Maybe<Project_Mutation_Response>;
  /** insert a single row into the table: "Project" */
  insert_Project_one?: Maybe<Project>;
  /** insert data into the table: "PromoCode" */
  insert_PromoCode?: Maybe<PromoCode_Mutation_Response>;
  /** insert a single row into the table: "PromoCode" */
  insert_PromoCode_one?: Maybe<PromoCode>;
  /** insert data into the table: "User" */
  insert_User?: Maybe<User_Mutation_Response>;
  /** insert data into the table: "UserCode" */
  insert_UserCode?: Maybe<UserCode_Mutation_Response>;
  /** insert a single row into the table: "UserCode" */
  insert_UserCode_one?: Maybe<UserCode>;
  /** insert a single row into the table: "User" */
  insert_User_one?: Maybe<User>;
  /** update data of the table: "Address" */
  update_Address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "Address" */
  update_Address_by_pk?: Maybe<Address>;
  /** update data of the table: "Bid" */
  update_Bid?: Maybe<Bid_Mutation_Response>;
  /** update single row of the table: "Bid" */
  update_Bid_by_pk?: Maybe<Bid>;
  /** update data of the table: "Contractor" */
  update_Contractor?: Maybe<Contractor_Mutation_Response>;
  /** update single row of the table: "Contractor" */
  update_Contractor_by_pk?: Maybe<Contractor>;
  /** update data of the table: "Image" */
  update_Image?: Maybe<Image_Mutation_Response>;
  /** update single row of the table: "Image" */
  update_Image_by_pk?: Maybe<Image>;
  /** update data of the table: "Payment" */
  update_Payment?: Maybe<Payment_Mutation_Response>;
  /** update single row of the table: "Payment" */
  update_Payment_by_pk?: Maybe<Payment>;
  /** update data of the table: "Project" */
  update_Project?: Maybe<Project_Mutation_Response>;
  /** update single row of the table: "Project" */
  update_Project_by_pk?: Maybe<Project>;
  /** update data of the table: "PromoCode" */
  update_PromoCode?: Maybe<PromoCode_Mutation_Response>;
  /** update single row of the table: "PromoCode" */
  update_PromoCode_by_pk?: Maybe<PromoCode>;
  /** update data of the table: "User" */
  update_User?: Maybe<User_Mutation_Response>;
  /** update data of the table: "UserCode" */
  update_UserCode?: Maybe<UserCode_Mutation_Response>;
  /** update single row of the table: "UserCode" */
  update_UserCode_by_pk?: Maybe<UserCode>;
  /** update single row of the table: "User" */
  update_User_by_pk?: Maybe<User>;
  validatePromoCode: Scalars['Boolean'];
};


/** mutation root */
export type Mutation_RootAuthGetCodeArgs = {
  agreed?: Maybe<Scalars['Boolean']>;
  phoneNumber: Scalars['String'];
};


/** mutation root */
export type Mutation_RootAuthVerifyCodeArgs = {
  code: Scalars['String'];
  phoneNumber: Scalars['String'];
  promoCode?: Maybe<Scalars['String']>;
};


/** mutation root */
export type Mutation_RootCreatePaymentIntentArgs = {
  bidId: Scalars['Int'];
  paymentMethodId?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  savePaymentMethod?: Maybe<Scalars['Boolean']>;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_BidArgs = {
  where: Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Bid_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ContractorArgs = {
  where: Contractor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Contractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ImageArgs = {
  where: Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Image_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PaymentArgs = {
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Payment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProjectArgs = {
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Project_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PromoCodeArgs = {
  where: PromoCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_PromoCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_UserArgs = {
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserCodeArgs = {
  where: UserCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UserCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BidArgs = {
  objects: Array<Bid_Insert_Input>;
  on_conflict?: Maybe<Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Bid_OneArgs = {
  object: Bid_Insert_Input;
  on_conflict?: Maybe<Bid_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ContractorArgs = {
  objects: Array<Contractor_Insert_Input>;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Contractor_OneArgs = {
  object: Contractor_Insert_Input;
  on_conflict?: Maybe<Contractor_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ImageArgs = {
  objects: Array<Image_Insert_Input>;
  on_conflict?: Maybe<Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Image_OneArgs = {
  object: Image_Insert_Input;
  on_conflict?: Maybe<Image_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PaymentArgs = {
  objects: Array<Payment_Insert_Input>;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Payment_OneArgs = {
  object: Payment_Insert_Input;
  on_conflict?: Maybe<Payment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProjectArgs = {
  objects: Array<Project_Insert_Input>;
  on_conflict?: Maybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Project_OneArgs = {
  object: Project_Insert_Input;
  on_conflict?: Maybe<Project_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromoCodeArgs = {
  objects: Array<PromoCode_Insert_Input>;
  on_conflict?: Maybe<PromoCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PromoCode_OneArgs = {
  object: PromoCode_Insert_Input;
  on_conflict?: Maybe<PromoCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserArgs = {
  objects: Array<User_Insert_Input>;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserCodeArgs = {
  objects: Array<UserCode_Insert_Input>;
  on_conflict?: Maybe<UserCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UserCode_OneArgs = {
  object: UserCode_Insert_Input;
  on_conflict?: Maybe<UserCode_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_OneArgs = {
  object: User_Insert_Input;
  on_conflict?: Maybe<User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_BidArgs = {
  _inc?: Maybe<Bid_Inc_Input>;
  _set?: Maybe<Bid_Set_Input>;
  where: Bid_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Bid_By_PkArgs = {
  _inc?: Maybe<Bid_Inc_Input>;
  _set?: Maybe<Bid_Set_Input>;
  pk_columns: Bid_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ContractorArgs = {
  _inc?: Maybe<Contractor_Inc_Input>;
  _set?: Maybe<Contractor_Set_Input>;
  where: Contractor_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Contractor_By_PkArgs = {
  _inc?: Maybe<Contractor_Inc_Input>;
  _set?: Maybe<Contractor_Set_Input>;
  pk_columns: Contractor_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ImageArgs = {
  _inc?: Maybe<Image_Inc_Input>;
  _set?: Maybe<Image_Set_Input>;
  where: Image_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Image_By_PkArgs = {
  _inc?: Maybe<Image_Inc_Input>;
  _set?: Maybe<Image_Set_Input>;
  pk_columns: Image_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PaymentArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  where: Payment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Payment_By_PkArgs = {
  _inc?: Maybe<Payment_Inc_Input>;
  _set?: Maybe<Payment_Set_Input>;
  pk_columns: Payment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ProjectArgs = {
  _inc?: Maybe<Project_Inc_Input>;
  _set?: Maybe<Project_Set_Input>;
  where: Project_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Project_By_PkArgs = {
  _inc?: Maybe<Project_Inc_Input>;
  _set?: Maybe<Project_Set_Input>;
  pk_columns: Project_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_PromoCodeArgs = {
  _inc?: Maybe<PromoCode_Inc_Input>;
  _set?: Maybe<PromoCode_Set_Input>;
  where: PromoCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_PromoCode_By_PkArgs = {
  _inc?: Maybe<PromoCode_Inc_Input>;
  _set?: Maybe<PromoCode_Set_Input>;
  pk_columns: PromoCode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UserArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  where: User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserCodeArgs = {
  _inc?: Maybe<UserCode_Inc_Input>;
  _set?: Maybe<UserCode_Set_Input>;
  where: UserCode_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UserCode_By_PkArgs = {
  _inc?: Maybe<UserCode_Inc_Input>;
  _set?: Maybe<UserCode_Set_Input>;
  pk_columns: UserCode_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_By_PkArgs = {
  _inc?: Maybe<User_Inc_Input>;
  _set?: Maybe<User_Set_Input>;
  pk_columns: User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootValidatePromoCodeArgs = {
  code: Scalars['String'];
};


/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "Address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "Address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "Bid" */
  Bid: Array<Bid>;
  /** fetch aggregated fields from the table: "Bid" */
  Bid_aggregate: Bid_Aggregate;
  /** fetch data from the table: "Bid" using primary key columns */
  Bid_by_pk?: Maybe<Bid>;
  /** fetch data from the table: "Contractor" */
  Contractor: Array<Contractor>;
  /** fetch aggregated fields from the table: "Contractor" */
  Contractor_aggregate: Contractor_Aggregate;
  /** fetch data from the table: "Contractor" using primary key columns */
  Contractor_by_pk?: Maybe<Contractor>;
  /** fetch data from the table: "Image" */
  Image: Array<Image>;
  /** fetch aggregated fields from the table: "Image" */
  Image_aggregate: Image_Aggregate;
  /** fetch data from the table: "Image" using primary key columns */
  Image_by_pk?: Maybe<Image>;
  /** fetch data from the table: "Payment" */
  Payment: Array<Payment>;
  /** fetch aggregated fields from the table: "Payment" */
  Payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "Payment" using primary key columns */
  Payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "Project" */
  Project: Array<Project>;
  /** fetch aggregated fields from the table: "Project" */
  Project_aggregate: Project_Aggregate;
  /** fetch data from the table: "Project" using primary key columns */
  Project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "PromoCode" */
  PromoCode: Array<PromoCode>;
  /** fetch aggregated fields from the table: "PromoCode" */
  PromoCode_aggregate: PromoCode_Aggregate;
  /** fetch data from the table: "PromoCode" using primary key columns */
  PromoCode_by_pk?: Maybe<PromoCode>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserCode" */
  UserCode: Array<UserCode>;
  /** fetch aggregated fields from the table: "UserCode" */
  UserCode_aggregate: UserCode_Aggregate;
  /** fetch data from the table: "UserCode" using primary key columns */
  UserCode_by_pk?: Maybe<UserCode>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
  /** get user payment methods */
  getPaymentMethods?: Maybe<Array<Maybe<PaymentMethod>>>;
  healthz: Scalars['Boolean'];
};


/** query root */
export type Query_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** query root */
export type Query_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** query root */
export type Query_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootBidArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** query root */
export type Query_RootBid_AggregateArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** query root */
export type Query_RootBid_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootContractorArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** query root */
export type Query_RootContractor_AggregateArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** query root */
export type Query_RootContractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootImageArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


/** query root */
export type Query_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


/** query root */
export type Query_RootImage_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** query root */
export type Query_RootPayment_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootProjectArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


/** query root */
export type Query_RootProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


/** query root */
export type Query_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootPromoCodeArgs = {
  distinct_on?: Maybe<Array<PromoCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PromoCode_Order_By>>;
  where?: Maybe<PromoCode_Bool_Exp>;
};


/** query root */
export type Query_RootPromoCode_AggregateArgs = {
  distinct_on?: Maybe<Array<PromoCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PromoCode_Order_By>>;
  where?: Maybe<PromoCode_Bool_Exp>;
};


/** query root */
export type Query_RootPromoCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUserCodeArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** query root */
export type Query_RootUserCode_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** query root */
export type Query_RootUserCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** query root */
export type Query_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "Address" */
  Address: Array<Address>;
  /** fetch aggregated fields from the table: "Address" */
  Address_aggregate: Address_Aggregate;
  /** fetch data from the table: "Address" using primary key columns */
  Address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "Bid" */
  Bid: Array<Bid>;
  /** fetch aggregated fields from the table: "Bid" */
  Bid_aggregate: Bid_Aggregate;
  /** fetch data from the table: "Bid" using primary key columns */
  Bid_by_pk?: Maybe<Bid>;
  /** fetch data from the table: "Contractor" */
  Contractor: Array<Contractor>;
  /** fetch aggregated fields from the table: "Contractor" */
  Contractor_aggregate: Contractor_Aggregate;
  /** fetch data from the table: "Contractor" using primary key columns */
  Contractor_by_pk?: Maybe<Contractor>;
  /** fetch data from the table: "Image" */
  Image: Array<Image>;
  /** fetch aggregated fields from the table: "Image" */
  Image_aggregate: Image_Aggregate;
  /** fetch data from the table: "Image" using primary key columns */
  Image_by_pk?: Maybe<Image>;
  /** fetch data from the table: "Payment" */
  Payment: Array<Payment>;
  /** fetch aggregated fields from the table: "Payment" */
  Payment_aggregate: Payment_Aggregate;
  /** fetch data from the table: "Payment" using primary key columns */
  Payment_by_pk?: Maybe<Payment>;
  /** fetch data from the table: "Project" */
  Project: Array<Project>;
  /** fetch aggregated fields from the table: "Project" */
  Project_aggregate: Project_Aggregate;
  /** fetch data from the table: "Project" using primary key columns */
  Project_by_pk?: Maybe<Project>;
  /** fetch data from the table: "PromoCode" */
  PromoCode: Array<PromoCode>;
  /** fetch aggregated fields from the table: "PromoCode" */
  PromoCode_aggregate: PromoCode_Aggregate;
  /** fetch data from the table: "PromoCode" using primary key columns */
  PromoCode_by_pk?: Maybe<PromoCode>;
  /** fetch data from the table: "User" */
  User: Array<User>;
  /** fetch data from the table: "UserCode" */
  UserCode: Array<UserCode>;
  /** fetch aggregated fields from the table: "UserCode" */
  UserCode_aggregate: UserCode_Aggregate;
  /** fetch data from the table: "UserCode" using primary key columns */
  UserCode_by_pk?: Maybe<UserCode>;
  /** fetch aggregated fields from the table: "User" */
  User_aggregate: User_Aggregate;
  /** fetch data from the table: "User" using primary key columns */
  User_by_pk?: Maybe<User>;
};


/** subscription root */
export type Subscription_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootBidArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBid_AggregateArgs = {
  distinct_on?: Maybe<Array<Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Bid_Order_By>>;
  where?: Maybe<Bid_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootBid_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootContractorArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContractor_AggregateArgs = {
  distinct_on?: Maybe<Array<Contractor_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Contractor_Order_By>>;
  where?: Maybe<Contractor_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootContractor_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootImageArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootImage_AggregateArgs = {
  distinct_on?: Maybe<Array<Image_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Image_Order_By>>;
  where?: Maybe<Image_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootImage_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPaymentArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_AggregateArgs = {
  distinct_on?: Maybe<Array<Payment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Payment_Order_By>>;
  where?: Maybe<Payment_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPayment_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootProjectArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_AggregateArgs = {
  distinct_on?: Maybe<Array<Project_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Project_Order_By>>;
  where?: Maybe<Project_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProject_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootPromoCodeArgs = {
  distinct_on?: Maybe<Array<PromoCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PromoCode_Order_By>>;
  where?: Maybe<PromoCode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPromoCode_AggregateArgs = {
  distinct_on?: Maybe<Array<PromoCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<PromoCode_Order_By>>;
  where?: Maybe<PromoCode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootPromoCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUserArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUserCodeArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUserCode_AggregateArgs = {
  distinct_on?: Maybe<Array<UserCode_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<UserCode_Order_By>>;
  where?: Maybe<UserCode_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUserCode_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUser_AggregateArgs = {
  distinct_on?: Maybe<Array<User_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<User_Order_By>>;
  where?: Maybe<User_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_By_PkArgs = {
  id: Scalars['Int'];
};


/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

export type AcceptBidOnProjectMutationVariables = Exact<{
  id: Scalars['Int'];
  bidId: Scalars['Int'];
}>;


export type AcceptBidOnProjectMutation = (
  { __typename?: 'mutation_root' }
  & { update_Project_by_pk?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'id' | 'address' | 'address2' | 'area' | 'category' | 'completedAt' | 'createdAt' | 'details' | 'location' | 'materialQuality' | 'name' | 'status'>
    & { Images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'cloudinaryId'>
    )>, Bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'contractorId' | 'description' | 'duration' | 'price' | 'startDate' | 'status'>
    )> }
  )>, update_Bid_by_pk?: Maybe<(
    { __typename?: 'Bid' }
    & Pick<Bid, 'id' | 'contractorId' | 'description' | 'duration' | 'price' | 'startDate' | 'status'>
  )> }
);

export type AddAddressMutationVariables = Exact<{
  object: Address_Insert_Input;
}>;


export type AddAddressMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Address_one?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'city' | 'id' | 'street' | 'state' | 'street2' | 'zip'>
  )> }
);

export type AuthGetCodeMutationVariables = Exact<{
  phoneNumber?: Maybe<Scalars['String']>;
  agreed?: Maybe<Scalars['Boolean']>;
}>;


export type AuthGetCodeMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'authGetCode'>
);

export type AuthVerifyCodeMutationVariables = Exact<{
  promoCode?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
}>;


export type AuthVerifyCodeMutation = (
  { __typename?: 'mutation_root' }
  & { authVerifyCode?: Maybe<(
    { __typename?: 'AuthInfo' }
    & Pick<AuthInfo, 'role' | 'token' | 'userId' | 'wasCreated'>
  )> }
);

export type CreatePaymentIntentMutationVariables = Exact<{
  bidId: Scalars['Int'];
  paymentMethodId?: Maybe<Scalars['String']>;
  promoBalance?: Maybe<Scalars['Int']>;
  savePaymentMethod?: Maybe<Scalars['Boolean']>;
}>;


export type CreatePaymentIntentMutation = (
  { __typename?: 'mutation_root' }
  & { createPaymentIntent: (
    { __typename?: 'PaymentIntent' }
    & Pick<PaymentIntent, 'secret'>
  ) }
);

export type CreateServiceRequestMutationVariables = Exact<{
  object: Project_Insert_Input;
}>;


export type CreateServiceRequestMutation = (
  { __typename?: 'mutation_root' }
  & { insert_Project_one?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'address' | 'address2' | 'area' | 'category' | 'createdAt' | 'details' | 'format' | 'id' | 'location' | 'materialQuality' | 'name' | 'reason' | 'stairs' | 'status' | 'timing' | 'userId'>
    & { Images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'cloudinaryId' | 'id' | 'projectId'>
    )> }
  )> }
);

export type UpdateAddressMutationVariables = Exact<{
  id: Scalars['Int'];
  _set?: Maybe<Address_Set_Input>;
}>;


export type UpdateAddressMutation = (
  { __typename?: 'mutation_root' }
  & { update_Address_by_pk?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'state' | 'street' | 'street2' | 'zip' | 'city' | 'id'>
  )> }
);

export type UpdateBidsStatusMutationVariables = Exact<{
  _in?: Maybe<Array<Scalars['Int']>>;
  status?: Maybe<Scalars['String']>;
}>;


export type UpdateBidsStatusMutation = (
  { __typename?: 'mutation_root' }
  & { update_Bid?: Maybe<(
    { __typename?: 'Bid_mutation_response' }
    & { returning: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'status' | 'price' | 'startDate' | 'duration' | 'description' | 'contractorId'>
    )> }
  )> }
);

export type UpdateUserNameMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
}>;


export type UpdateUserNameMutation = (
  { __typename?: 'mutation_root' }
  & { update_User_by_pk?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'phoneNumber' | 'promoBalance' | 'stripeCustomerId'>
    & { Addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'id' | 'state' | 'street' | 'street2' | 'zip'>
    )> }
  )> }
);

export type ValidatePromoCodeMutationVariables = Exact<{
  code: Scalars['String'];
}>;


export type ValidatePromoCodeMutation = (
  { __typename?: 'mutation_root' }
  & Pick<Mutation_Root, 'validatePromoCode'>
);

export type AllUserProjectsQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>;
}>;


export type AllUserProjectsQuery = (
  { __typename?: 'query_root' }
  & { Project: Array<(
    { __typename?: 'Project' }
    & Pick<Project, 'address' | 'address2' | 'area' | 'category' | 'completedAt' | 'details' | 'id' | 'location' | 'materialQuality' | 'name' | 'status'>
    & { Bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'duration' | 'price' | 'startDate' | 'status'>
    )> }
  )> }
);

export type ContractorByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ContractorByIdQuery = (
  { __typename?: 'query_root' }
  & { Contractor_by_pk?: Maybe<(
    { __typename?: 'Contractor' }
    & Pick<Contractor, 'id' | 'name' | 'profilePic'>
  )> }
);

export type CurrentUserQueryVariables = Exact<{
  userId?: Maybe<Scalars['Int']>;
}>;


export type CurrentUserQuery = (
  { __typename?: 'query_root' }
  & { User: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name' | 'phoneNumber' | 'promoBalance' | 'stripeCustomerId' | 'role'>
    & { Addresses: Array<(
      { __typename?: 'Address' }
      & Pick<Address, 'city' | 'id' | 'state' | 'street' | 'street2' | 'zip'>
    )> }
  )> }
);

export type GetPaymentMethodsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPaymentMethodsQuery = (
  { __typename?: 'query_root' }
  & { getPaymentMethods?: Maybe<Array<Maybe<(
    { __typename?: 'PaymentMethod' }
    & Pick<PaymentMethod, 'id'>
    & { billing_details?: Maybe<(
      { __typename?: 'PaymentBillingDetails' }
      & Pick<PaymentBillingDetails, 'name' | 'phone'>
    )>, card?: Maybe<(
      { __typename?: 'PaymentCard' }
      & Pick<PaymentCard, 'brand' | 'last4' | 'exp_year' | 'exp_month'>
    )> }
  )>>> }
);

export type ProjectByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProjectByIdQuery = (
  { __typename?: 'query_root' }
  & { Project_by_pk?: Maybe<(
    { __typename?: 'Project' }
    & Pick<Project, 'address' | 'address2' | 'area' | 'category' | 'completedAt' | 'createdAt' | 'details' | 'id' | 'location' | 'materialQuality' | 'name' | 'status'>
    & { Images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'id' | 'cloudinaryId' | 'type'>
    )>, Bids: Array<(
      { __typename?: 'Bid' }
      & Pick<Bid, 'id' | 'price' | 'startDate' | 'status' | 'duration' | 'description' | 'contractorId'>
    )> }
  )> }
);


export const AcceptBidOnProjectDocument = gql`
    mutation acceptBidOnProject($id: Int!, $bidId: Int!) {
  update_Project_by_pk(pk_columns: {id: $id}, _set: {status: "progress"}) {
    id
    address
    address2
    area
    category
    completedAt
    createdAt
    details
    location
    materialQuality
    name
    status
    Images {
      id
      cloudinaryId
    }
    Bids {
      id
      contractorId
      description
      duration
      price
      startDate
      status
    }
  }
  update_Bid_by_pk(pk_columns: {id: $bidId}, _set: {status: "progress"}) {
    id
    contractorId
    description
    duration
    price
    startDate
    status
  }
}
    `;

/**
 * __useAcceptBidOnProjectMutation__
 *
 * To run a mutation, you first call `useAcceptBidOnProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptBidOnProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptBidOnProjectMutation, { data, loading, error }] = useAcceptBidOnProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      bidId: // value for 'bidId'
 *   },
 * });
 */
export function useAcceptBidOnProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AcceptBidOnProjectMutation, AcceptBidOnProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<AcceptBidOnProjectMutation, AcceptBidOnProjectMutationVariables>(AcceptBidOnProjectDocument, baseOptions);
      }
export type AcceptBidOnProjectMutationHookResult = ReturnType<typeof useAcceptBidOnProjectMutation>;
export const AddAddressDocument = gql`
    mutation addAddress($object: Address_insert_input!) {
  insert_Address_one(object: $object) {
    city
    id
    street
    state
    street2
    zip
  }
}
    `;

/**
 * __useAddAddressMutation__
 *
 * To run a mutation, you first call `useAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressMutation, { data, loading, error }] = useAddAddressMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useAddAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAddressMutation, AddAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAddressMutation, AddAddressMutationVariables>(AddAddressDocument, baseOptions);
      }
export type AddAddressMutationHookResult = ReturnType<typeof useAddAddressMutation>;
export const AuthGetCodeDocument = gql`
    mutation authGetCode($phoneNumber: String = "", $agreed: Boolean) {
  authGetCode(phoneNumber: $phoneNumber, agreed: $agreed)
}
    `;

/**
 * __useAuthGetCodeMutation__
 *
 * To run a mutation, you first call `useAuthGetCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthGetCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authGetCodeMutation, { data, loading, error }] = useAuthGetCodeMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      agreed: // value for 'agreed'
 *   },
 * });
 */
export function useAuthGetCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthGetCodeMutation, AuthGetCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthGetCodeMutation, AuthGetCodeMutationVariables>(AuthGetCodeDocument, baseOptions);
      }
export type AuthGetCodeMutationHookResult = ReturnType<typeof useAuthGetCodeMutation>;
export const AuthVerifyCodeDocument = gql`
    mutation authVerifyCode($promoCode: String, $phoneNumber: String = "", $code: String = "") {
  authVerifyCode(code: $code, phoneNumber: $phoneNumber, promoCode: $promoCode) {
    role
    token
    userId
    wasCreated
  }
}
    `;

/**
 * __useAuthVerifyCodeMutation__
 *
 * To run a mutation, you first call `useAuthVerifyCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthVerifyCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authVerifyCodeMutation, { data, loading, error }] = useAuthVerifyCodeMutation({
 *   variables: {
 *      promoCode: // value for 'promoCode'
 *      phoneNumber: // value for 'phoneNumber'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useAuthVerifyCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<AuthVerifyCodeMutation, AuthVerifyCodeMutationVariables>(AuthVerifyCodeDocument, baseOptions);
      }
export type AuthVerifyCodeMutationHookResult = ReturnType<typeof useAuthVerifyCodeMutation>;
export const CreatePaymentIntentDocument = gql`
    mutation createPaymentIntent($bidId: Int!, $paymentMethodId: String, $promoBalance: Int, $savePaymentMethod: Boolean = false) {
  createPaymentIntent(bidId: $bidId, paymentMethodId: $paymentMethodId, promoBalance: $promoBalance, savePaymentMethod: $savePaymentMethod) {
    secret
  }
}
    `;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      bidId: // value for 'bidId'
 *      paymentMethodId: // value for 'paymentMethodId'
 *      promoBalance: // value for 'promoBalance'
 *      savePaymentMethod: // value for 'savePaymentMethod'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, baseOptions);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export const CreateServiceRequestDocument = gql`
    mutation createServiceRequest($object: Project_insert_input!) {
  insert_Project_one(object: $object) {
    address
    address2
    area
    category
    createdAt
    details
    format
    id
    location
    materialQuality
    name
    reason
    stairs
    status
    timing
    userId
    Images {
      cloudinaryId
      id
      projectId
    }
  }
}
    `;

/**
 * __useCreateServiceRequestMutation__
 *
 * To run a mutation, you first call `useCreateServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceRequestMutation, { data, loading, error }] = useCreateServiceRequestMutation({
 *   variables: {
 *      object: // value for 'object'
 *   },
 * });
 */
export function useCreateServiceRequestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateServiceRequestMutation, CreateServiceRequestMutationVariables>(CreateServiceRequestDocument, baseOptions);
      }
export type CreateServiceRequestMutationHookResult = ReturnType<typeof useCreateServiceRequestMutation>;
export const UpdateAddressDocument = gql`
    mutation updateAddress($id: Int!, $_set: Address_set_input = {city: "", state: "", street: "", street2: "", zip: ""}) {
  update_Address_by_pk(pk_columns: {id: $id}, _set: $_set) {
    state
    street
    street2
    zip
    city
    id
  }
}
    `;

/**
 * __useUpdateAddressMutation__
 *
 * To run a mutation, you first call `useUpdateAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAddressMutation, { data, loading, error }] = useUpdateAddressMutation({
 *   variables: {
 *      id: // value for 'id'
 *      _set: // value for '_set'
 *   },
 * });
 */
export function useUpdateAddressMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAddressMutation, UpdateAddressMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAddressMutation, UpdateAddressMutationVariables>(UpdateAddressDocument, baseOptions);
      }
export type UpdateAddressMutationHookResult = ReturnType<typeof useUpdateAddressMutation>;
export const UpdateBidsStatusDocument = gql`
    mutation updateBidsStatus($_in: [Int!], $status: String = "rejected") {
  update_Bid(where: {id: {_in: $_in}}, _set: {status: $status}) {
    returning {
      id
      status
      price
      startDate
      duration
      description
      contractorId
    }
  }
}
    `;

/**
 * __useUpdateBidsStatusMutation__
 *
 * To run a mutation, you first call `useUpdateBidsStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBidsStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBidsStatusMutation, { data, loading, error }] = useUpdateBidsStatusMutation({
 *   variables: {
 *      _in: // value for '_in'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateBidsStatusMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBidsStatusMutation, UpdateBidsStatusMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBidsStatusMutation, UpdateBidsStatusMutationVariables>(UpdateBidsStatusDocument, baseOptions);
      }
export type UpdateBidsStatusMutationHookResult = ReturnType<typeof useUpdateBidsStatusMutation>;
export const UpdateUserNameDocument = gql`
    mutation updateUserName($id: Int!, $name: String!) {
  update_User_by_pk(pk_columns: {id: $id}, _set: {name: $name}) {
    id
    name
    phoneNumber
    promoBalance
    stripeCustomerId
    Addresses {
      city
      id
      state
      street
      street2
      zip
    }
  }
}
    `;

/**
 * __useUpdateUserNameMutation__
 *
 * To run a mutation, you first call `useUpdateUserNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserNameMutation, { data, loading, error }] = useUpdateUserNameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateUserNameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserNameMutation, UpdateUserNameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserNameMutation, UpdateUserNameMutationVariables>(UpdateUserNameDocument, baseOptions);
      }
export type UpdateUserNameMutationHookResult = ReturnType<typeof useUpdateUserNameMutation>;
export const ValidatePromoCodeDocument = gql`
    mutation validatePromoCode($code: String!) {
  validatePromoCode(code: $code)
}
    `;

/**
 * __useValidatePromoCodeMutation__
 *
 * To run a mutation, you first call `useValidatePromoCodeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useValidatePromoCodeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [validatePromoCodeMutation, { data, loading, error }] = useValidatePromoCodeMutation({
 *   variables: {
 *      code: // value for 'code'
 *   },
 * });
 */
export function useValidatePromoCodeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ValidatePromoCodeMutation, ValidatePromoCodeMutationVariables>) {
        return ApolloReactHooks.useMutation<ValidatePromoCodeMutation, ValidatePromoCodeMutationVariables>(ValidatePromoCodeDocument, baseOptions);
      }
export type ValidatePromoCodeMutationHookResult = ReturnType<typeof useValidatePromoCodeMutation>;
export const AllUserProjectsDocument = gql`
    query allUserProjects($userId: Int = 10) {
  Project(where: {userId: {_eq: $userId}, status: {_neq: "archived"}}) {
    address
    address2
    area
    category
    completedAt
    details
    id
    location
    materialQuality
    name
    status
    Bids {
      id
      duration
      price
      startDate
      status
    }
  }
}
    `;

/**
 * __useAllUserProjectsQuery__
 *
 * To run a query within a React component, call `useAllUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUserProjectsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useAllUserProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllUserProjectsQuery, AllUserProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllUserProjectsQuery, AllUserProjectsQueryVariables>(AllUserProjectsDocument, baseOptions);
      }
export function useAllUserProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllUserProjectsQuery, AllUserProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllUserProjectsQuery, AllUserProjectsQueryVariables>(AllUserProjectsDocument, baseOptions);
        }
export type AllUserProjectsQueryHookResult = ReturnType<typeof useAllUserProjectsQuery>;
export type AllUserProjectsLazyQueryHookResult = ReturnType<typeof useAllUserProjectsLazyQuery>;
export const ContractorByIdDocument = gql`
    query contractorById($id: Int!) {
  Contractor_by_pk(id: $id) {
    id
    name
    profilePic
  }
}
    `;

/**
 * __useContractorByIdQuery__
 *
 * To run a query within a React component, call `useContractorByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useContractorByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useContractorByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useContractorByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ContractorByIdQuery, ContractorByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ContractorByIdQuery, ContractorByIdQueryVariables>(ContractorByIdDocument, baseOptions);
      }
export function useContractorByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ContractorByIdQuery, ContractorByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ContractorByIdQuery, ContractorByIdQueryVariables>(ContractorByIdDocument, baseOptions);
        }
export type ContractorByIdQueryHookResult = ReturnType<typeof useContractorByIdQuery>;
export type ContractorByIdLazyQueryHookResult = ReturnType<typeof useContractorByIdLazyQuery>;
export const CurrentUserDocument = gql`
    query currentUser($userId: Int) {
  User(where: {id: {_eq: $userId}}) {
    id
    name
    phoneNumber
    promoBalance
    stripeCustomerId
    role
    Addresses {
      city
      id
      state
      street
      street2
      zip
    }
  }
}
    `;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
      }
export function useCurrentUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, baseOptions);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export const GetPaymentMethodsDocument = gql`
    query getPaymentMethods {
  getPaymentMethods {
    id
    billing_details {
      name
      phone
    }
    card {
      brand
      last4
      exp_year
      exp_month
    }
  }
}
    `;

/**
 * __useGetPaymentMethodsQuery__
 *
 * To run a query within a React component, call `useGetPaymentMethodsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentMethodsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentMethodsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPaymentMethodsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
      }
export function useGetPaymentMethodsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPaymentMethodsQuery, GetPaymentMethodsQueryVariables>(GetPaymentMethodsDocument, baseOptions);
        }
export type GetPaymentMethodsQueryHookResult = ReturnType<typeof useGetPaymentMethodsQuery>;
export type GetPaymentMethodsLazyQueryHookResult = ReturnType<typeof useGetPaymentMethodsLazyQuery>;
export const ProjectByIdDocument = gql`
    query projectById($id: Int!) {
  Project_by_pk(id: $id) {
    address
    address2
    area
    category
    completedAt
    createdAt
    details
    id
    location
    materialQuality
    name
    status
    Images {
      id
      cloudinaryId
      type
    }
    Bids {
      id
      price
      startDate
      status
      duration
      description
      contractorId
    }
  }
}
    `;

/**
 * __useProjectByIdQuery__
 *
 * To run a query within a React component, call `useProjectByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProjectByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectByIdQuery, ProjectByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(ProjectByIdDocument, baseOptions);
      }
export function useProjectByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectByIdQuery, ProjectByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectByIdQuery, ProjectByIdQueryVariables>(ProjectByIdDocument, baseOptions);
        }
export type ProjectByIdQueryHookResult = ReturnType<typeof useProjectByIdQuery>;
export type ProjectByIdLazyQueryHookResult = ReturnType<typeof useProjectByIdLazyQuery>;