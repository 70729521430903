import moment from 'moment';

import { STATUS_ENUM } from '../../constants/status';
import { Bid } from '../../generated/graphql';

export function getAcceptedBid(bids: Bid[] = []) {
  return bids.find((bid) => bid?.status === STATUS_ENUM.IN_PROGRESS);
}

export function getBidEstimatedDate(bid?: Bid) {
  return moment(bid?.startDate).add(bid?.duration, 'days');
}
