import { ReactComponent as Bathtub } from './bathtub.svg';
import { ReactComponent as Bed } from './bed.svg';
import { ReactComponent as Billiards } from './billiards.svg';
import { ReactComponent as Bolt } from './bolt.svg';
import { ReactComponent as Bricks } from './bricks.svg';
import { ReactComponent as Builder } from './builder.svg';
import { ReactComponent as Chair } from './chair.svg';
import { ReactComponent as Couch } from './couch.svg';
import { ReactComponent as Downstairs } from './downstairs.svg';
import { ReactComponent as Fan } from './fan.svg';
import { ReactComponent as Fence } from './fence.svg';
import { ReactComponent as Flash } from './flash.svg';
import { ReactComponent as Garage } from './garage.svg';
import { ReactComponent as Grass } from './grass.svg';
import { ReactComponent as Home } from './home.svg';
import { ReactComponent as House } from './house.svg';
import { ReactComponent as Jacuzzi } from './jacuzzi.svg';
import { ReactComponent as Kitchen } from './kitchen.svg';
import { ReactComponent as Lightbulb } from './lightbulb.svg';
import { ReactComponent as Pool } from './pool.svg';
import { ReactComponent as Remote } from './remote.svg';
import { ReactComponent as Roller } from './roller.svg';
import { ReactComponent as Roof } from './roof.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Toilet } from './toilet.svg';
import { ReactComponent as Tool } from './tool.svg';
import { ReactComponent as Tools } from './tools.svg';
import { ReactComponent as Upstairs } from './upstairs.svg';
import { ReactComponent as WashingMachine } from './washing-machine.svg';

const IconList = {
  Bathtub,
  Bed,
  Billiards,
  Bolt,
  Bricks,
  Builder,
  Chair,
  Couch,
  Downstairs,
  Fan,
  Fence,
  Flash,
  Garage,
  Grass,
  Home,
  House,
  Jacuzzi,
  Kitchen,
  Lightbulb,
  Pool,
  Remote,
  Roller,
  Roof,
  Settings,
  Toilet,
  Tool,
  Tools,
  Upstairs,
  WashingMachine,
};

export type IconListKeys = keyof typeof IconList;

export default IconList;
