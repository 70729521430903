import { Typography } from 'antd';
import moment from 'moment';
import React, { useState, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { STATUS_ENUM, getProjectStatusFromEnum } from '../../constants/status';
import { Project } from '../../generated/graphql';
import { getAcceptedBid, getBidEstimatedDate } from '../../helpers/bids';
import { BidsList } from '../Bids';
import { ContractorCardWithData } from '../ContractorCard';
import { ImagePreviews } from '../ImagePreviews';
import { ProjectProgressBar } from '../ProjectProgressBar';
import { Spacer } from '../Spacer';

import { Description } from './Description';
import { Overview } from './Overview';
import { Row } from './styled';

interface IProps {
  data?: Partial<Project>;
  loading?: boolean;
  onAccept?: any;
  discount?: number | string;
}

export function ProjectDetails({ data, discount, loading, onAccept }: IProps) {
  const [expanded, setExpanded] = useState(false);

  const acceptedBid = useMemo(() => getAcceptedBid(data?.Bids), [data]);
  const estimatedDate = useMemo(() => {
    if (data?.status === 'completed' && data?.completedAt) {
      return moment(data?.completedAt);
    }

    return getBidEstimatedDate(acceptedBid);
  }, [acceptedBid, data]);

  const status = data?.status;

  const hasOverview =
    status === STATUS_ENUM.IN_PROGRESS || status === STATUS_ENUM.COMPLETED;

  const showBids =
    status === STATUS_ENUM.WAITING || status === STATUS_ENUM.CREATED;

  const showStatus =
    status === STATUS_ENUM.CREATED ||
    status === STATUS_ENUM.ARCHIVED ||
    status === STATUS_ENUM.WAITING ||
    status === STATUS_ENUM.CONSULT;

  return (
    <div>
      <Row>
        <Typography.Title level={3}>Project Details</Typography.Title>
        {/* Show Status */}
        {showStatus && (
          <Typography.Text>
            {getProjectStatusFromEnum(status as any)}
          </Typography.Text>
        )}
      </Row>
      {!loading && hasOverview && (
        <>
          <Overview
            completion={estimatedDate}
            price={acceptedBid?.price}
            status={status}
          />
          <Spacer />
        </>
      )}
      {/* In Progress */}
      {!loading && data?.status === STATUS_ENUM.IN_PROGRESS && (
        <>
          <ProjectProgressBar
            start={acceptedBid?.startDate}
            completion={estimatedDate}
            status={data?.status}
          />
          <Spacer />
        </>
      )}
      {/* Completed or In Progress */}
      {!loading && hasOverview && (
        <>
          <ContractorCardWithData id={acceptedBid?.contractorId} />
          <Spacer size="large" />
        </>
      )}
      {/* Completed or In Progress */}
      {hasOverview && (
        <Typography.Title level={3}>Description</Typography.Title>
      )}
      {!loading && <Description>{data?.details || ''}</Description>}
      <Spacer />
      {data?.Images && Boolean(data?.Images.length) && (
        <>
          <Link
            to="#"
            onClick={(event) => {
              event.preventDefault();
              setExpanded(!expanded);
            }}
          >
            {expanded ? 'Hide Photos' : 'View Photos'}{' '}
          </Link>
          <Spacer />
        </>
      )}
      {expanded && data?.Images && Boolean(data?.Images.length) && (
        <div>
          <ImagePreviews
            photos={
              data?.Images.map(({ cloudinaryId, type }) => ({
                url: cloudinaryId,
                type: type || '',
              })) || []
            }
          />
        </div>
      )}
      {/* Taking Bids */}
      {showBids && (
        <BidsList
          bids={STATUS_ENUM.WAITING === status ? data?.Bids : []}
          onAccept={onAccept}
          discount={discount}
          emptyText={
            STATUS_ENUM.WAITING === status
              ? 'No Bids Yet'
              : 'We are still gathering bids for this project'
          }
        />
      )}
    </div>
  );
}
