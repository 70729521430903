import styled from 'styled-components';

interface IStyledStripeWrapperProps {
  half?: boolean;
  noLeftMargins?: boolean;
  noRightMargins?: boolean;
}

const halfMargins = 15 / 2;

export const StyledStripeWrapper = styled.div<IStyledStripeWrapperProps>`
  display: inline-block;
  margin-bottom: 12px;
  margin-left: ${({ half, noLeftMargins }) =>
    half && !noLeftMargins ? halfMargins : 0}px;
  margin-right: ${({ half, noRightMargins }) =>
    half && !noRightMargins ? halfMargins : 0}px;
  width: ${({ half = false }) =>
    half ? `calc(50% - ${halfMargins}px)` : '100%'};

  .stripe-card-element,
  .StripeElement {
    padding: 4px 11px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', 'tnum';
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
  }
`;
