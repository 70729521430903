import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import { FetchResult } from '@apollo/client';
import { Space, Form, Input, notification } from 'antd';
import React, { useContext, useState, useCallback } from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { Button, BackButton } from '../../components/Button';
import { Header } from '../../components/Header';
import { Spacer } from '../../components/Spacer';
import { UserContext } from '../../context/User';
import { USER_ACTION_TYPES } from '../../context/User/types';
import {
  useUpdateUserNameMutation,
  UpdateUserNameMutation,
} from '../../generated/graphql';
import { ROUTES } from '../../router';
import { FullWidthPageWrapper } from '../../styles/Layout';

type IProps = Partial<
  RouteComponentProps<{}, {}, { back?: string; verifiedCalled?: boolean }>
>;

export default function UpdateProfilePage({ history, location }: IProps) {
  const { dispatch, user } = useContext(UserContext);
  const [mutate, { loading }] = useUpdateUserNameMutation();
  const [isValid, setValid] = useState(
    Boolean(user?.firstName && user?.lastName),
  );

  const onSubmit = useCallback(
    async (values) => {
      if (!user) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      let results: FetchResult<
        UpdateUserNameMutation,
        Record<string, any>,
        Record<string, any>
      >;

      try {
        results = await mutate({
          variables: {
            id: Number(user?.id),
            name: `${values.firstName} ${values.lastName}`,
          },
        });
      } catch (error) {
        console.log(error);
      }

      notification.success({
        message: 'Profile Updated',
      });

      const wasCreating = user.isCreating;

      dispatch({
        type: USER_ACTION_TYPES.UPDATE,
        payload: {
          name: `${values.firstName} ${values.lastName}`,
        },
      });

      if (wasCreating) {
        history?.push(ROUTES.SERVICE_REQUEST);
      } else {
        history?.push(ROUTES.PROFILE);
      }
    },
    [dispatch, history, mutate, user],
  );

  return (
    <FullWidthPageWrapper>
      {!user?.isCreating && (
        <BackButton to={ROUTES.PROFILE} replace>
          <Space>
            <ArrowLeftOutlined />
            Back
          </Space>
        </BackButton>
      )}
      <Header>Your Name</Header>
      <Spacer />
      <div>
        <Form
          onFieldsChange={(changedValues, allValues) => {
            const isUnfilled = allValues.some((field) => !field.value);
            setValid(!isUnfilled);
          }}
          onFinish={onSubmit}
          initialValues={{
            firstName: user?.name?.split(' ')[0] || '',
            lastName: user?.name?.split(' ')[1] || '',
          }}
        >
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'A first name is required.' }]}
          >
            <Input placeholder="First Name" disabled={loading} />
          </Form.Item>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'A last name is required' }]}
          >
            <Input placeholder="Last Name" disabled={loading} />
          </Form.Item>
          <Form.Item>
            <Button
              disabled={!isValid}
              loading={loading}
              type="primary"
              htmlType="submit"
            >
              Update <ArrowRightOutlined />
            </Button>
          </Form.Item>
        </Form>
      </div>
    </FullWidthPageWrapper>
  );
}
