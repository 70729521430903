import { Typography } from 'antd';
import React from 'react';

interface IProps {
  children?: string;
}

export function Description({ children }: IProps) {
  return (
    <Typography>
      <Typography.Text style={{ color: '#000' }}>{children}</Typography.Text>
    </Typography>
  );
}
