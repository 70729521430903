import { CloseCircleOutlined, PictureOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

import { ImageCloudinary } from './ImageCloudinary';
import { CancelButtonWrapper, Container } from './styled';

interface IProps {
  onDelete?: (index?: number, src?: string | undefined) => void;
  index?: number;
  src?: string;
}

export function ImagePreview({ index, onDelete, src }: IProps) {
  return (
    <Container>
      {onDelete && (
        <CancelButtonWrapper
          onClick={() => {
            if (onDelete) {
              onDelete(index, src);
            }
          }}
        >
          <CloseCircleOutlined style={{ color: 'white' }} />
        </CancelButtonWrapper>
      )}
      <Avatar
        icon={src ? <ImageCloudinary src={src} /> : <PictureOutlined />}
        shape="square"
        size={64}
        style={{ borderRadius: 3 }}
      />
    </Container>
  );
}
