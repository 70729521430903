import React from 'react';

import { Body, Card as CardStyle } from './styled';

interface IProps {
  children?: any;
  onClick?: any;
}

export function Card({ children, onClick }: IProps) {
  return (
    <CardStyle onClick={onClick}>
      <Body>{children}</Body>
    </CardStyle>
  );
}
