import moment, { Moment } from 'moment';

export function formatCurrency(price?: number | string) {
  return new Intl.NumberFormat('us', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(Number(price) || 0);
}

export function formatDate(date?: string | Date | Moment) {
  return moment(date).format('M/D/YY');
}

export function formatPhoneNumber(phoneNumberString: string) {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');

  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3];
  }

  return '';
}
