import * as parseAddress from 'parse-address';

export function createAddressStrings(address = '') {
  // Example: 1005 N Gravenstein Highway Sebastopol CA 95472
  const {
    city,
    number,
    prefix,
    state,
    street,
    type,
    zip,
  } = parseAddress.parseLocation(address);

  // Starts with: Gravenstein
  let line1 = street;

  // Adds: N
  // N Gravenstein
  if (prefix) {
    line1 = prefix + ' ' + line1;
  }

  // Add 1005
  // 1005 N Gravenstein
  if (number) {
    line1 = number + ' ' + line1;
  }

  // Add Hwy
  // 1005 N Gravenstein Hwy
  if (type) {
    line1 += ' ' + type + ' ';
  }

  // Parser useless, it doesn't work, so we take the address
  line1 = address;

  return {
    line1,
    line2: ``,
  };
}
