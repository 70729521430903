import React, {
  createContext,
  useReducer,
  useCallback,
  useEffect,
} from 'react';

import { CurrentUserDocument } from '../../generated/graphql';
import { apolloClient } from '../../helpers/apollo';
import { getUserInfo } from '../../helpers/user';

import { initialValue } from './initialValue';
import { reducer } from './reducer';
import { IContextValues, USER_ACTION_TYPES } from './types';

import { authStorage } from '.';

export const UserContext = createContext<IContextValues>(initialValue);

export let userDispatch: any;

async function updateUserInfo(dispatch: any, userId: string | number) {
  let userInfoResponse: any;

  try {
    userInfoResponse = await apolloClient.query({
      query: CurrentUserDocument,
      fetchPolicy: 'network-only',
      variables: {
        userId: Number(userId),
      },
    });
  } catch (error) {
    console.log(error);
    return;
  }

  const userInfo = getUserInfo(userInfoResponse);

  const payload = {
    promoBalance: userInfo?.promoBalance,
    address: userInfo?.Addresses?.[0],
    ...userInfo,
  };

  dispatch({ type: USER_ACTION_TYPES.FETCHED, payload });
}

export function UserContextProvider({ children }: { children: any }) {
  const [{ hasFetched, loading, user }, dispatch] = useReducer(reducer, {
    user: authStorage.getUserInfo(),
    loading: false,
  });

  useEffect(() => {
    userDispatch = dispatch;
  }, [dispatch]);

  // Updates User Info
  useEffect(() => {
    if (hasFetched || !user || !user?.token) {
      return;
    }

    updateUserInfo(dispatch, user?.id)
      .then()
      .catch((e) => console.error(e));
  }, [hasFetched, user]);

  const login = useCallback(
    (data) => dispatch({ type: USER_ACTION_TYPES.LOGIN, payload: data }),
    [],
  );
  const logout = useCallback(
    () => dispatch({ type: USER_ACTION_TYPES.LOGOUT }),
    [],
  );
  const refetch = useCallback(
    () =>
      updateUserInfo(dispatch, user?.id)
        .then()
        .catch((e) => console.error(e)),
    [user],
  );

  return (
    <UserContext.Provider
      value={{
        dispatch,
        login,
        logout,
        loading,
        refetch,
        user,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
