import styled from 'styled-components';

export const Title = styled.div`
   font-size: 28px;
   font-weight: bold;
   white-space: pre-line;
   margin: 41px 0 24px;
   color: #040404;
   line-height: 65px;
`;

export const Description = styled.div`
   font-size: 14px;
   color: #9A9A9A;
`;

export const Point = styled.div`
   font-size: 18px;
   font-weight: bold;
   margin-bottom: 11px;
   color: #040404;
`;

export const Spacer = styled.div`
   width: 100%;
   height: 66px;
`;

export const SpacerSmall = styled.div`
   width: 100%;
   height: 20px;
`;

export const Spacer59 = styled.div`
   width: 100%;
   height: 59px;
`;

export const ListItem = styled.div`
   position: relative;
   font-size: 14px;
   padding-left: 20px;
   color: #9A9A9A;
   margin-bottom: 20px;
`;