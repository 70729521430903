import { Typography } from 'antd';
import React from 'react';

import { Container } from './styled';

interface IProps {
  children?: string;
  right?: any;
}

export function Header({ children, right }: IProps) {
  return (
    <Container>
      <Typography>
        <Typography.Title style={{ margin: 0 }}>{children}</Typography.Title>
      </Typography>
      {right}
    </Container>
  );
}
