import { useElements, CardNumberElement } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
} from '@stripe/stripe-js';
import { Input, Checkbox } from 'antd';
import React, { useCallback, useState } from 'react';

import { Button } from '../Button';
import { CardElement } from '../CardElement';
import { Header } from '../Header';
import { Spacer } from '../Spacer';

interface ISubmitOptions {
  card: any;
  name?: string;
  save?: boolean;
}
interface IProps {
  children?: string;
  disabled?: boolean;
  onCancel?: () => void | null;
  onChange?: any;
  onSubmit?: (options: ISubmitOptions) => void;
  visible?: boolean;
}

export function PaymentForm({
  children = 'Continue',
  disabled,
  onCancel,
  onSubmit,
  visible = true,
}: IProps) {
  const elements = useElements();

  const [validFields, setValidFields] = useState({
    name: undefined,
    cardNumber: false,
    cardExpiry: false,
    cardCvc: false,
    saveCard: false,
  });

  const onChange = useCallback(
    (
      event:
        | StripeCardNumberElementChangeEvent
        | StripeCardCvcElementChangeEvent
        | StripeCardExpiryElementChangeEvent
        | React.ChangeEvent<HTMLInputElement>,
    ) => {
      let name: 'cardNumber' | 'cardCvc' | 'cardExpiry' | string;
      let value: boolean | string | undefined;

      if ('elementType' in event) {
        name = event?.elementType;
        value = event?.complete;
      } else {
        name = event?.target?.name;
        value = event?.target?.value;
      }

      setValidFields((prevState) => ({ ...prevState, [name]: value }));
    },
    [],
  );

  const isValid =
    validFields.name &&
    validFields.cardNumber &&
    validFields.cardExpiry &&
    validFields.cardCvc;

  const handleSubmit = useCallback(
    (event: React.ChangeEvent<HTMLFormElement>) => {
      event.preventDefault();

      if (!isValid) {
        return;
      }

      if (!elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const card = elements.getElement(CardNumberElement)!;

      if (onSubmit && card) {
        onSubmit({
          name: validFields?.name,
          card,
          save: validFields?.saveCard,
        });
      }
    },
    [elements, isValid, onSubmit, validFields],
  );

  if (!elements) {
    return <div>Loading ...</div>;
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: visible ? 'inherit' : 'none' }}
    >
      <Header>Payment information</Header>
      <label>
        Name
        <Input
          name="name"
          style={{ marginBottom: 12 }}
          placeholder="Name"
          onChange={onChange}
        />
      </label>
      <CardElement
        label="Card Number"
        type="CardNumberElement"
        onChange={onChange}
      />
      <CardElement
        half
        noLeftMargins
        label="Expiration"
        type="CardExpiryElement"
        onChange={onChange}
      />
      <CardElement
        half
        noRightMargins
        label="Security Code"
        type="CardCvcElement"
        onChange={onChange}
      />
      <div>
        <Checkbox
          checked={validFields.saveCard}
          onChange={({ target }) => {
            setValidFields((prevState) => ({
              ...prevState,
              saveCard: target.checked,
            }));
          }}
        >
          Save Card
        </Checkbox>
      </div>
      <Spacer size="small" />
      <Button type="primary" disabled={disabled || !isValid} htmlType="submit">
        {children}
      </Button>
      {onCancel && (
        <>
          <Spacer />
          <Button onClick={onCancel} ghost>
            Use Different Card
          </Button>
        </>
      )}
    </form>
  );
}
