import styled from 'styled-components';

export const Container = styled.div`
  height: 64px;
  margin: 9px;
  position: relative;
  width: 64px;
`;

export const CancelButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 4px;
  z-index: 1;
`;
