import React from 'react';

import { List as StyledList } from './styled';

interface IProps {
  children?: any;
}

export function List({ children }: IProps) {
  return (
    <StyledList direction="vertical" size="large">
      {children}
    </StyledList>
  );
}
