import React from 'react';
import styled from 'styled-components';

export const FOOTER_HEIGHT = 46;

const Wrapper = styled.div<{ hasNav?: boolean }>`
  background: ${({ theme }) => theme.colors.primary};
  bottom: -1px;
  height: ${FOOTER_HEIGHT + 2}px;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  z-index: 2;
`;

const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  font-size: 0.85em;
  justify-content: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};

  a {
    width: 60px;
    overflow: visible;
    flex: 1 1 25%;
    color: ${({ theme }) => theme.colors.white};
    margin: 0 8px;
    :hover {
      color: #fff;
    }
  }
`;

export function Footer({ hasNav = false }) {
  return (
    <Wrapper hasNav={hasNav}>
      <Container>
        <a href="//brixos.com">brixos.com</a>
        <div>⚉</div>
        <a href="mailto:helpme@brixos.com">helpme@brixos.com</a>
      </Container>
    </Wrapper>
  );
}
