export enum STATUS_ENUM {
  ACCEPTED = 'accepted',
  ARCHIVED = 'archived',
  COMPLETED = 'completed',
  CONSULT = 'consult',
  CREATED = 'created',
  IN_PROGRESS = 'progress',
  WAITING = 'waiting',
}

export type STATUS_ENUM_KEYS = keyof typeof STATUS_ENUM;

// Project Statuses
export const PROJECT_STATUS_LABELS = {
  [STATUS_ENUM.COMPLETED]: 'Completed',
  [STATUS_ENUM.IN_PROGRESS]: 'In Progress',
  [STATUS_ENUM.CREATED]: 'Taking Bids',
  [STATUS_ENUM.WAITING]: 'All Bids In',
  [STATUS_ENUM.CONSULT]: 'Consulting Request',
  [STATUS_ENUM.ARCHIVED]: 'Archived',
} as const;

export type PROJECT_STATUS_LABELS_KEYS = keyof typeof PROJECT_STATUS_LABELS;
export type PROJECT_STATUS_LABELS_VALUES = typeof PROJECT_STATUS_LABELS[PROJECT_STATUS_LABELS_KEYS];

export function getProjectStatusFromEnum(
  status: STATUS_ENUM | STATUS_ENUM_KEYS,
): PROJECT_STATUS_LABELS_VALUES {
  return PROJECT_STATUS_LABELS[status as PROJECT_STATUS_LABELS_KEYS];
}

// Bids Status
