import { Typography } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-left: -9px;
  margin-right: -9px;
`;

export const NoResultText = styled(Typography.Text)`
  margin: auto;
  text-align: center;
`;
