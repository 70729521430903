import { Radio } from 'antd';
import styled from 'styled-components';

export const RadioGroupWrapper = styled(Radio.Group)`
  display: flex;
  margin-left: -8px;
  margin-right: -8px;
  flex-wrap: wrap;

  span {
    padding: 0.5em;
    line-height: 1.4;
  }

  svg {
    width: 50px;
    margin-bottom: 8px;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.08) !important;
  }

  .ant-radio-button-wrapper {
    :not(.ant-radio-button-wrapper-disabled):not(.ant-radio-button-wrapper-checked):hover {
      color: ${({ theme }) => theme.colors.primary} !important;
    }
  }

  .ant-radio-button-wrapper-checked {
    background: ${({ theme }) => theme.colors.secondary} !important;
    border-color: ${({ theme }) => theme.colors.secondary} !important;

    ::before {
      background-color: ${({ theme }) => theme.colors.primary} !important;
    }

    span {
      font-weight: 700;
    }
  }
`;

export const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const Button = styled(Radio.Button)<{ mobileMaxWidth?: boolean }>`
  align-items: center;
  display: inline-flex;
  flex: 1 1
    calc(${({ mobileMaxWidth }) => (mobileMaxWidth ? '25%' : '50%')} - 16px);
  min-height: 70px;
  height: auto;
  justify-content: center;
  margin: 8px;
  padding: 8px 16px;

  /* max-width: ${({ mobileMaxWidth }) =>
    mobileMaxWidth ? '80px' : 'none'}; */

  @media only screen and (min-device-width: 768px) {
    max-width: none;
  }
`;
