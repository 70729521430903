/* eslint-disable @typescript-eslint/no-empty-function */
export const initialValue = {
  dispatch: ((args: any) => {}) as any,
  logout: () => {},
  login: (data?: any) => {},
  refetch: async () => {},
  hasFetched: false,
  loading: false,
  user: undefined,
};
