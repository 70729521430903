import { Input, Typography, Form } from 'antd';
import React from 'react';

import { Spacer } from '../Spacer';

const { TextArea } = Input;

interface IProps {
  name?: string;
  required?: boolean;
  title: string;
  placeholder?: string;
}

export function TextAreaInput({
  placeholder = '',
  name,
  required,
  title,
}: IProps) {
  return (
    <div>
      <Typography>
        <Typography.Title level={3}>{title}</Typography.Title>
      </Typography>
      <Spacer size="small" />
      <Form.Item name={name} rules={[{ required }]}>
        <TextArea
          style={{ padding: 16 }}
          placeholder={placeholder}
          autoSize={{ minRows: 5, maxRows: 10 }}
        />
      </Form.Item>
    </div>
  );
}
