import React from 'react';
import styled from 'styled-components';

import ellipsisImage from './ellipsis.svg';
import filledEllipsisImage from './filled-ellipsis.svg';

interface IProps {
  isFilled?: boolean;
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

const Image = styled.img``;

export function Ellipsis({ isFilled }: IProps) {
  return (
      <Container>
        <Image src={isFilled ? filledEllipsisImage : ellipsisImage } />
      </Container>
  );
}
