/* eslint-disable no-case-declarations */
import moment, { Moment } from 'moment';
import React, { useMemo } from 'react';

import { STATUS_ENUM, getProjectStatusFromEnum } from '../../constants/status';
import { ProgressBar } from '../ProgressBar';

interface IProps {
  start?: string | Date | Moment;
  completion?: string | Date | Moment;
  status?: STATUS_ENUM | keyof typeof STATUS_ENUM;
}

export function ProjectProgressBar({ start, status, completion }: IProps) {
  const { color, progress, progressText, progressTextColor } = useMemo(() => {
    let color = 'default';
    let progress = 1;

    let progressText = getProjectStatusFromEnum(status as any);
    let progressTextColor = '#fff';

    switch (status) {
      case STATUS_ENUM.IN_PROGRESS:
        const startDate = moment(start);
        const endDate = moment(completion);
        const duration = endDate.diff(startDate, 'minutes');
        const timeLeft = endDate.diff(moment(), 'minutes');
        progressText = 'In Progress';
        color = 'green';
        progress = 1 - timeLeft / duration;
        if (progress < 0.3) {
          progressTextColor = '#000';
        }
        break;
      default:
        break;
    }

    return {
      color,
      progress,
      progressText,
      progressTextColor,
    };
  }, [completion, start, status]);

  return (
    <ProgressBar
      color={color as any}
      progress={progress}
      textColor={progressTextColor}
    >
      {progressText}
    </ProgressBar>
  );
}
