import React from 'react';
import styled from 'styled-components';
import arrowLeft from './arrow-left.svg';
const Image = styled.img``;

export function Arrow() {
  return (
        <Image src={ arrowLeft } />
  );
}
