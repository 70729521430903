import { Card as AntdCard, Typography } from 'antd';
import styled from 'styled-components';

export const Card = styled(AntdCard)`
  width: 100%;

  .ant-card-body {
    padding: 0;
  }
`;

export const PaddingContainer = styled.div`
  padding: 16px;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Title = styled(Typography.Text)`
  color: #000;
`;

export const Column = styled.div`
  color: #000;
  display: flex;
  flex-direction: column;
`;

export const RightText = styled(Typography.Text)`
  color: #1890ff;
  font-size: 12px;
  line-height: 20px;
`;
