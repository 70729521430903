import { ArrowRightOutlined } from '@ant-design/icons';
import React, { useCallback, useEffect } from 'react';
import { RouteComponentProps, Link } from 'react-router-dom';

import { SimpleButton } from '../../components/Button';
import { ROUTES } from '../../router';

import { Wrapper, Image, Logo, Picture, Description, Registration } from './styled';
import logoImage from './logo.svg';
import mainImage from './welcome-pic.svg';

type IProps = Partial<RouteComponentProps>;

export default function LandingPage({ history }: IProps) {

  useEffect(() => {
      document?.querySelector('main')?.classList.add('background-black');
      document?.querySelector('section')?.classList.add('background-black');
      document?.querySelector('main + div')?.classList.add('hide');
      document?.querySelector('main + div + div')?.classList.add('hide');
    return () => {
      document?.querySelector('main')?.classList.remove('background-black');
      document?.querySelector('section')?.classList.remove('background-black');
      document?.querySelector('main + div')?.classList.remove('hide');
      document?.querySelector('main + div + div')?.classList.remove('hide');
    };
  });

  const goToRegistration = useCallback(async () => {
    history?.push({
      pathname: ROUTES.REGISTRATION
    });
  }, [history]);

  return (
    <Wrapper>
      <Logo>
        <Image src={logoImage} />
      </Logo>
      <Picture>
        <Image src={mainImage} />
      </Picture>
      <Description>
        The trusted team {'\n'}
        for remodels, additions, {'\n'}
        and home improvement projects
      </Description>
      <SimpleButton
        onClick={ goToRegistration }
        className="get-started-button"
      >
        Get started
      </SimpleButton>
      <Registration>
        Already have an account?
        <Link  className="main-link" to={ROUTES.LOGIN}> Sign in</Link>
      </Registration>
    </Wrapper>
  );
}
