import React, { createContext, useCallback, useState } from 'react';

export const CartContext = createContext({
  cart: {} as any,
  set: (data: any) => {
    return;
  },
  clear: () => {
    return;
  },
});

export function CartContextProvider({ children }: { children: any }) {
  const [state, setState] = useState(undefined);

  const set: any = useCallback((data: any) => setState(data), []);

  const clear = useCallback(() => setState(undefined), []);

  return (
    <CartContext.Provider value={{ cart: state, clear, set }}>
      {children}
    </CartContext.Provider>
  );
}
