import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import {
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
  StripeCardCvcElementChangeEvent,
  StripeCardNumberElementOptions,
} from '@stripe/stripe-js';
import React from 'react';

import { StyledStripeWrapper } from './styled';

const Elements = {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
};

interface IProps {
  noLeftMargins?: boolean;
  noRightMargins?: boolean;
  half?: boolean;
  label?: string;
  onChange?: (
    event:
      | StripeCardNumberElementChangeEvent
      | StripeCardCvcElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
  ) => any;
  type: 'CardNumberElement' | 'CardExpiryElement' | 'CardCvcElement';
}

const options: StripeCardNumberElementOptions = {
  style: {
    base: {
      lineHeight: '1.5715',
      color: 'rgba(0, 0, 0, 0.65)',
      letterSpacing: '0.025em',
      fontSize: '16px',
      fontFamily:
        "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'",
      '::placeholder': {
        color: '#bfbfbf',
      },
    },
    invalid: {
      color: '#ff4d4f',
    },
  },
};

export function CardElement<T>({
  half,
  label,
  onChange,
  type,
  noLeftMargins,
  noRightMargins,
}: IProps) {
  const CardElement = Elements[type];

  return (
    <StyledStripeWrapper
      half={half}
      noLeftMargins={noLeftMargins}
      noRightMargins={noRightMargins}
    >
      <label>
        {label}
        <CardElement options={options} onChange={onChange} />
      </label>
    </StyledStripeWrapper>
  );
}
